<div class="columns is-multiline">
  <div class="column is-12">
    <div class="content has-text-centered">
      <div class="title-wrapper">
        <img #orangeImage
             src="assets/images/orange-close-up-min.png"
             class="orange-image" />
        <h1 class="is-size-1 title-text m-l-md">
          Whoops!...
        </h1>
      </div>
      <h2 class="is-size-4">Looks like an error has occoured</h2>
      <p class="is-size-5">This error has been logged in our system, please try again or <a (click)="goBack()">go
          back</a></p>
    </div>
  </div>
</div>