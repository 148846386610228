import { Component } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ag-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent {

  constructor(private authService: AuthenticationService, private router: Router) {
    this.authService.handleAuthentication()
      .then(() => {
        this.router.navigate(['account']);
      }).catch(errObj => {
        if (errObj.error === 'invalid_token') {
          this.authService.logout();
        } else {
          this.router.navigate(['']);
          console.error('Error occurred while authenticating.', errObj);
          throw errObj; // Rethrow for sentry
        }
      });
  }
}
