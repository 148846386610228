import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/core/services/modal/modal.service';
import { Modal } from '@app/core/interfaces/modal.interface';
import { modalAnimations } from './modal-animations';
import { HostListener } from '@angular/core';

export enum ModalSize {
  Full = 'is-fullwidth',
  Larger = 'is-larger',
  Large = 'is-large',
  Normal = 'is-normal'
}

export interface ModalBaseConfig {
  title: string;
  canConfirm?: boolean;
  canCancel?: boolean;
  onConfirm: (res?: any) => void;
  onConfirmClickOverride?: (res?: any) => void;
  onCancel?: (res?: any) => void;
  confirmBtnText?: string;
  confirmBtnClass?: string;
  cancelBtnText?: string;
  size?: ModalSize;
}

@Component({
  selector: 'ag-modal-base',
  templateUrl: './modal-base.component.html',
  animations: [...modalAnimations],
  styleUrls: ['./modal-base.component.scss']
})
export class ModalBaseComponent implements OnInit, Modal {

  public title: string;
  public content: string;
  public confirmBtnText: string;
  public confirmBtnClass: string;
  public cancelBtnText: string;
  public onConfirm: (res?: any) => void;
  public onCancel: (res?: any) => void;
  public isLoading = false;
  public isVisible = false;
  public size: ModalSize;
  public canConfirm = true;
  public canCancel = true;

  public onAction: Promise<boolean>;
  public onActionResolve: (action: boolean) => void;

  constructor(private modalService: ModalService) {
    this.onAction = new Promise((resolve) => this.onActionResolve = resolve);
  }

  ngOnInit() { }

  configure(config: ModalBaseConfig) {
    this.title = config.title;
    this.confirmBtnClass = config.confirmBtnClass || 'is-primary';
    this.confirmBtnText = config.confirmBtnText || 'Ok';
    this.cancelBtnText = config.cancelBtnText || 'Cancel';
    this.canConfirm = config.canConfirm === undefined ? true : config.canConfirm;
    this.canCancel = config.canCancel === undefined ? true : config.canCancel;
    this.onConfirm = config.onConfirm;
    this.onCancel = config.onCancel || this.hide;
    this.size = config.size || ModalSize.Normal;
  }

  setTitle = (title: string) => this.title = title;
  setLoading = (isLoading: boolean) => this.isLoading = isLoading;
  setConfirmBtnText = (text: string) => this.confirmBtnText = text;
  setCancelBtnText = (text: string) => this.cancelBtnText = text;
  setOnConfirm = (onConfirm: (res?: any) => void) => this.onConfirm = onConfirm;
  setOnCancel = (onCancel: (res?: any) => void) => this.onCancel = onCancel;
  setCanConfirm = (canConfirm: boolean) => this.canConfirm = canConfirm;
  setCanCancel = (canCancel: boolean) => this.canCancel = canCancel;

  show = () => this.isVisible = true;

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent = (_: KeyboardEvent) => this.hide()

  hide(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.isVisible = false;
      setTimeout(() => {
        try {
          this.modalService.clearHost();
          resolve();
        } catch (err) {
          reject(err);
        }
      }, 500);
    });
  }

  onConfirmClick() {
    this.onActionResolve(true);
    this.onConfirm();
  }

  onCancelClick() {
    this.onActionResolve(false);
    this.onCancel();
  }

}
