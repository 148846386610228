import { Component, OnInit } from '@angular/core';
import { Modal } from '../../interfaces/modal.interface';

export interface ConfirmModalConfig {
  title: string;
  content: string;
  confirmBtnText?: string;
  confirmBtnClass?: string;
  canCancel?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

@Component({
  selector: 'ag-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit, Modal {

  public title: string;
  public content: string;
  public confirmBtnText: string;
  public confirmBtnClass: string;
  public onConfirm: () => void;
  public onCancel: () => void;
  public isLoading = false;
  public isVisible = false;
  public canCancel: boolean;

  public onAction: Promise<boolean>;
  private onActionResolve: (action: boolean) => void;

  constructor() {
    this.onAction = new Promise((resolve) => this.onActionResolve = resolve);
  }

  ngOnInit() { }

  configure(config: ConfirmModalConfig) {
    this.title = config.title;
    this.content = config.content;
    this.confirmBtnClass = config.confirmBtnClass || 'is-link';
    this.confirmBtnText = config.confirmBtnText || 'Ok';
    this.onConfirm = config.onConfirm;
    this.onCancel = config.onCancel || this.hide;
    this.canCancel = config.canCancel === undefined ? true : config.canCancel;
  }

  show() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  onConfirmClick() {
    this.onActionResolve(true);
    this.onConfirm();
  }

  onCancelClick() {
    this.onActionResolve(false);
    this.onCancel();
  }

}
