<div class="modal"
     [ngClass]="{ 'is-active': isVisible }">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body"
             [innerHtml]="content">
    </section>
    <footer class="modal-card-foot">
      <button class="button"
              [ngClass]="[confirmBtnClass, isLoading ? 'is-loading': '' ]"
              (click)="onConfirmClick()">
        {{ confirmBtnText }}
      </button>
      <button *ngIf="canCancel"
              class="button"
              (click)="onCancelClick()"
              type="button"
              [ngClass]="{ 'is-disabled': isLoading }">
        Cancel
      </button>
    </footer>
  </div>
</div>