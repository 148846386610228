<div class="modal is-active">
  <div class="modal-background"
       *ngIf="isVisible"
       [@modalBackdropFadeAnimation]></div>
  <div class="modal-card"
       [ngClass]="[size]"
       *ngIf="isVisible"
       [@modalScaleInInAnimation]>
    <header class="modal-card-head">
      <p class="modal-card-title"
         [innerHtml]="title"></p>
    </header>
    <section class="modal-card-body">
      <ng-content></ng-content>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-info"
              *ngIf="canConfirm"
              [ngClass]="[confirmBtnClass, isLoading ? 'is-loading': '' ]"
              (click)="onConfirmClick()">
        {{ confirmBtnText }}
      </button>
      <button class="button"
              *ngIf="canCancel"
              (click)="onCancelClick()"
              type="button"
              [attr.disabled]="isLoading ? 'disabled': null">
        {{ cancelBtnText }}
      </button>
    </footer>
  </div>
</div>