import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CompanyHelper } from '@app/core/helpers/company.helper';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { faEyeSlash, faEye, faEdit, faSave, faSearch, faMapMarkerAlt, faTable } from '@fortawesome/free-solid-svg-icons';
import { MapTypeStyle } from '@agm/core';

import {
  MyCompanyGQL,
  Company,
  OrderAlertsGQL,
  OrderAlert,
  AlertSeverityEnum,
  AlertStateEnum,
  HideOrderAlertGQL,
  UnhideOrderAlertGQL,
  MetabaseReportsGQL,
  MetabaseReport,
  CurrentMapPointsGQL,
  MapPoint,
  MapPointContainer
} from '@app/generated/graphql';
import { ModalService } from '@app/core/services/modal/modal.service';
import { KpiAlertModalComponent } from './kpi-alert-modal/kpi-alert-modal.component';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { FuseService, FuseMatch } from '@app/core/services/fuse/fuse.service';
import { animations } from './dashboard.animations';
import { ColumnMapping } from '@openagri/components';
const LOCAL_STORAGE_PREFIX = 'dashboard.reportname';

interface Dictionary<T> {
  [Key: string]: T;
}

type SearchablMapPoint = MapPoint & { match: string };

const fuseMatchToSearchableMapPoint = (m: FuseMatch<MapPoint>): SearchablMapPoint => {
  const searchable = m.item as SearchablMapPoint;
  if (m.matches) {
    const key = m.matches[0].key;
    const v = m.matches[0].value;
    const formatKey = (k: string) => {
      const prop = k.split('.')[k.split('.').length - 1];
      const upCase = `${prop.charAt(0).toUpperCase()}${prop.slice(1)}`;
      return upCase.split(/(?=[A-Z])/).join(' ');
    };
    searchable.match = `${formatKey(key)} - ${v}`;
  }
  return searchable;
};

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [...animations]
})
export class DashboardComponent implements OnInit, OnDestroy {

  initialized = false;
  myCompany: Company;
  companyHelper = CompanyHelper;
  mapPoints: SearchablMapPoint[];
  filteredMapPoints: SearchablMapPoint[];
  filteredContainers: MapPointContainer[];
  orderAlerts: OrderAlert[];
  lat = 51.678418;
  lng = 7.809007;
  objectKeys = Object.keys;
  showHiddenAlerts = false;
  mapStyles: MapTypeStyle[] = [{
    featureType: 'poi',
    stylers: [{
      visibility: 'off'
    }]
  }];
  containerColumnMappings: ColumnMapping[] = [
    { heading: 'Company', property: 'otherPartyName' },
    { heading: 'POD', property: 'portOfDestination' },
    { heading: 'Vessel Name', property: 'vesselName' },
    { heading: 'ETA', property: 'eta' },
    { heading: 'Container #', property: 'containerNumber' },
    { heading: 'Order #', property: 'orderId' }
  ];
  containerSelectedColumns: ColumnMapping[] = [
    { heading: 'Company', property: 'otherPartyName' },
    { heading: 'POD', property: 'portOfDestination' },
    { heading: 'Vessel Name', property: 'vesselName' },
    { heading: 'ETA', property: 'eta' },
    { heading: 'Container #', property: 'containerNumber' },
    { heading: 'Order #', property: 'orderId' }
  ];
  datePipe = new DatePipe('en-US');

  metabaseReports: MetabaseReport[];

  reportIds = new Proxy({} as Dictionary<number>, {
    get: function (_target: Dictionary<number>, reportName: string): number {
      const key = LOCAL_STORAGE_PREFIX + '.' + reportName;
      const reportIndex = localStorage.getItem(key);
      if (reportIndex === null) {
        return null;
      }
      const index = Number(reportIndex);
      if (Number.isNaN(index)) {
        return null;
      }
      return index;
    },
    set: function (_target: Dictionary<number>, reportName: string, value: number) {
      const key = LOCAL_STORAGE_PREFIX + '.' + reportName;
      localStorage.setItem(key, value.toString());
      return true;
    }
  });

  get visibleOrderAlerts(): OrderAlert[] {
    if (!this.orderAlerts) {
      return [];
    }
    return this.orderAlerts.filter(orderAlert => {
      if (this.showHiddenAlerts) {
        return true;
      }
      return orderAlert.state !== AlertStateEnum.Hidden;
    });
  }

  get filteredMapPointMatches(): string[] {
    const items = this.filteredMapPoints.map(m => m.match).filter(x => !!x);
    return [...new Set(items)];
  }

  get activeTrackingTab(): string {
    const key = LOCAL_STORAGE_PREFIX + '.activeTrackingTab';
    const activeTab = localStorage.getItem(key);
    if (activeTab === null || activeTab !== 'trackingMap' && activeTab !== 'trackingTable') {
      return 'trackingMap';
    }

    return activeTab;
  }

  set activeTrackingTab(activeTab: string) {
    const key = LOCAL_STORAGE_PREFIX + '.activeTrackingTab';
    localStorage.setItem(key, activeTab);
  }

  icons = {
    faEye,
    faEyeSlash,
    faEdit,
    faSave,
    faSearch,
    faMapMarkerAlt,
    faTable
  };

  dashboardSearch = new FormControl();

  constructor(
    private metabaseReportsGQL: MetabaseReportsGQL,
    private myCompanyGql: MyCompanyGQL,
    private sanitizer: DomSanitizer,
    private router: Router,
    private orderAlertsGql: OrderAlertsGQL,
    private titleService: Title,
    private modalService: ModalService,
    private hideOrderAlertGql: HideOrderAlertGQL,
    private unhideOrderAlertGql: UnhideOrderAlertGQL,
    private toastService: ToastService,
    private fuseService: FuseService,
    private currentMapLocationsGql: CurrentMapPointsGQL) { }

  ngOnInit(): void {

    this.titleService.setTitle('Agrigate | Dashboard');
    const metabaseReports$ = this.metabaseReportsGQL.fetch({ chart_type: 'question' });
    const myCompany$ = this.myCompanyGql.fetch();
    const orderAlerts$ = this.orderAlertsGql.fetch();
    const currentMapPoints$ = this.currentMapLocationsGql.fetch();

    this.dashboardSearch.valueChanges
      .pipe(map(val => val.trim()))
      .subscribe((value) => {
        if (value) {
          const matches = this.fuseService.customSearch(this.mapPoints, value, [
            'location.name',
            'location.vesselName',
            'location.unLocode',
            'location.country',
            'location.voyageNumber',
            'location.typeOfBusiness',
            'orders.id',
            'orders.otherPartyName',
            'containers.orderId',
            'containers.containerNumber',
            'containers.otherPartyName',
            'containers.portOfDestination',
          ]);

          this.filteredMapPoints = matches.map(fuseMatchToSearchableMapPoint);
        } else {
          this.filteredMapPoints = this.mapPoints.map((p) => {
            p.match = null;
            return p;
          });
        }

        this.updateFilteredContainers();
      });

    zip(myCompany$, orderAlerts$, metabaseReports$, currentMapPoints$).pipe(
      map(([myCompanyRes, orderAlertsRes, metabaseReportsRes, currentMapPointsRes]) => ({
        myCompanyRes,
        metabaseReportsRes,
        orderAlertsRes,
        currentMapPointsRes
      })
      )
    ).subscribe(z => {
      this.myCompany = z.myCompanyRes.data.myCompany;
      this.metabaseReports = z.metabaseReportsRes.data.metabaseReports;
      this.initReports();
      this.mapPoints = z.currentMapPointsRes.data.currentMapPoints as SearchablMapPoint[];
      this.filteredMapPoints = this.mapPoints;
      this.updateFilteredContainers();
      this.orderAlerts = this.sortAlerts(z.orderAlertsRes.data.orderAlerts);
      this.initialized = true;
    }, err => {
      this.router.navigate(['account', '500'], { replaceUrl: true });
      throw err;
    });
  }

  updateFilteredContainers() {
    if (!this.filteredMapPoints) {
      this.filteredContainers = [];
    }

    const containers = [];
    this.filteredMapPoints.forEach(mp => {
      if (mp.containers) {
        mp.containers.forEach(c => {
          containers.push({
            otherPartyName: c.otherPartyName,
            portOfDestination: c.portOfDestination,
            vesselName: c.vesselName,
            eta: this.datePipe.transform(c.eta, 'dd-MM-yyyy'),
            containerNumber: c.containerNumber,
            orderId: c.orderId
          });
        });
      }
    });
    this.filteredContainers = containers;
  }

  sortAlerts(orderAlerts: OrderAlert[]): OrderAlert[] {
    return orderAlerts.sort((a, b) => {
      // error takes order precendence and then fallback to importance
      const a_severity = a.severity === AlertSeverityEnum.Error ? 1 : 0;
      const b_severity = b.severity === AlertSeverityEnum.Error ? 1 : 0;

      if (a_severity > b_severity) {
        return -1;
      }

      if (a_severity < b_severity) {
        return 1;
      }

      return b.importance - a.importance;
    });
  }

  ngOnDestroy(): void {
    this.titleService.setTitle('Agrigate');
  }

  alertClass(orderAlert: OrderAlert) {
    switch (orderAlert.severity) {
      case AlertSeverityEnum.Error: {
        return 'has-background-danger';
      }
      case AlertSeverityEnum.Warn: {
        return 'has-background-warning';
      }
      default: {
        throw new Error(`Unknown order alert type: '${orderAlert.severity}'`);
      }
    }
  }

  getMapPinIconUrl(mapPoint: MapPoint) {
    if (mapPoint.location.__typename === 'Vessel') {
      return '/assets/icons/ship-marker.svg';
    } else if (mapPoint.location.__typename === 'SeaPort') {
      return '/assets/icons/port-marker.svg';
    } else { // FoodBusinessOperator
      if (mapPoint.location.typeOfBusiness === 'Commercial Cold Store') {
        return '/assets/icons/cold-store-marker.svg';
      } else if (mapPoint.location.typeOfBusiness.includes('Rail Depot')) {
        return '/assets/icons/rail-depot-marker.svg';
      } else if (mapPoint.location.typeOfBusiness.includes('Pack House')) {
        return '/assets/icons/packhouse-marker.svg';
      } else {
        return '/assets/icons/pin.svg';
      }
    }
  }

  otherCompany(alert: OrderAlert): Company {
    if (this.companyHelper.isBuyer(this.myCompany)) {
      return alert.order.seller;
    }
    if (this.companyHelper.isSeller(this.myCompany)) {
      return alert.order.buyer;
    }
  }

  showKpiAlertModal(orderAlert: OrderAlert) {
    this.modalService.createModalInstance(KpiAlertModalComponent, {
      orderAlert: orderAlert,
      myCompany: this.myCompany
    });
  }

  hideOrderAlert(orderAlert: OrderAlert) {
    this.hideOrderAlertGql.mutate({
      id: orderAlert.id
    }).subscribe(rsp => {
      // changing the underlying field value should alter the original
      orderAlert.state = rsp.data.hideOrderAlert.orderAlert.state;
    }, err => {
      this.toastService.showErrorToast('Unable to hide alert');
      throw err;
    });
  }

  unhideOrderAlert(orderAlert: OrderAlert) {
    this.unhideOrderAlertGql.mutate({
      id: orderAlert.id
    }).subscribe(rsp => {
      // changing the underlying field value should alter the original
      orderAlert.state = rsp.data.unhideOrderAlert.orderAlert.state;
    }, err => {
      this.toastService.showErrorToast('Unable to unhide alert');
      throw err;
    });
  }

  formatDate(dateString: string): string {
    if (dateString === 'TBD') {
      return dateString;
    }

    // assume en-US locale as it doesn't matter for dates
    return new DatePipe('en-US').transform(dateString, 'dd MMM yyyy');
  }

  initReports() {
    this.findReport('report_0', 0);
    this.findReport('report_1', 1);
    this.findReport('report_2', 2);
  }

  findReport(graphName: string, defaultIndex: number): MetabaseReport {
    let report: MetabaseReport;
    const reportId = this.reportIds[graphName];

    if (reportId === null) {
      report = this.metabaseReports[defaultIndex];
      this.reportIds[graphName] = report.id;
      return report;
    }

    report = this.metabaseReports.find(r => r.id === reportId);
    if (!report) {
      report = this.metabaseReports[defaultIndex];
      this.reportIds[graphName] = report.id;
      return report;
    }

    return report;
  }

  graphUrl(graphName: string): SafeResourceUrl {
    if (!graphName || graphName === null) {
      return undefined;
    }

    const report = this.findReport(graphName, 0);
    if (!report) {
      throw new Error(`Unable to find ID for ${graphName} (${this.reportIds[graphName]})`);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(report.url);
  }
}
