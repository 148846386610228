<div class="columns is-multiline"
     *ngIf="initialized; else loading">

  <div class="column is-12">
    <ag-page-title>Dashboard</ag-page-title>
  </div>

  <div class="column is-12">
    <div class="columns is-multiline">
      <div class="column is-4-fullhd is-12-desktop is-12-tablet">

        <div class="control p-b-md">
          <div class="select">
            <select [(ngModel)]="reportIds['report_0']">
              <ng-container *ngFor="let report of metabaseReports | sort: 'name'">
                <option [ngValue]="report.id">{{report.name | humanize}}</option>
              </ng-container>
            </select>
          </div>
        </div>

        <ag-metabase-iframe height="400px"
                            [src]="graphUrl('report_0')"></ag-metabase-iframe>
      </div>
      <div class="column is-4-fullhd is-12-desktop is-12-tablet">

        <div class="control p-b-md">
          <div class="select">
            <select [(ngModel)]="reportIds['report_1']">
              <ng-container *ngFor="let report of metabaseReports | sort: 'name'">
                <option [ngValue]="report.id">{{report.name | humanize}}</option>
              </ng-container>
            </select>
          </div>
        </div>

        <ag-metabase-iframe height="400px"
                            [src]="graphUrl('report_1')"></ag-metabase-iframe>
      </div>
      <div class="column is-4-fullhd is-12-desktop is-12-tablet">

        <div class="control p-b-md">
          <div class="select">
            <select [(ngModel)]="reportIds['report_2']">
              <ng-container *ngFor="let report of metabaseReports | sort: 'name'">
                <option [ngValue]="report.id">{{report.name | humanize}}</option>
              </ng-container>
            </select>
          </div>
        </div>

        <ag-metabase-iframe height="400px"
                            [src]="graphUrl('report_2')"></ag-metabase-iframe>
      </div>
    </div>
  </div>

  <div class="column  is-5-fullhd
                      is-12-tablet
                      is-12-mobile">
    <div class="box p-none"
         style="height: 100%;">
      <ng-container *ngTemplateOutlet="visibleOrderAlerts.length > 0 ? alerts : emptyAlerts"></ng-container>
    </div>
  </div>

  <div class="column is-7-fullhd is-12-desktop is-12-tablet">
    <div class="card">
      <div class="card-header no-shadow">
        <div class="tabs card-header-title">
          <ul>
            <li [ngClass]="{'is-active': activeTrackingTab === 'trackingMap'}">
              <a (click)="activeTrackingTab = 'trackingMap'">
                <span class="icon is-small">
                  <fa-icon [icon]="icons.faMapMarkerAlt"></fa-icon>
                </span>
                <span>Tracking Map</span>
              </a>
            </li>
            <li [ngClass]="{'is-active': activeTrackingTab === 'trackingTable'}">
              <a (click)="activeTrackingTab = 'trackingTable'">
                <span class="icon is-small">
                  <fa-icon [icon]="icons.faTable"></fa-icon>
                </span>
                <span>Table</span>
              </a>
            </li>
          </ul>
        </div>

        <div class="field p-t-md p-r-md">
          <div class="control has-icons-left">
            <input class="input"
                   placeholder="Search..."
                   [formControl]="dashboardSearch"/>
            <span class="icon is-small is-left">
              <fa-icon [icon]="icons.faSearch"></fa-icon>
            </span>
          </div>
          <p *ngIf="mapPoints.length !== filteredMapPoints.length"
             class="help">Showing {{filteredMapPoints.length}} of {{mapPoints.length}} locations</p>
        </div>

      </div>
      <ng-container>
        <div class="p-b-md p-l-md"
             *ngIf="mapPoints.length !== filteredMapPoints.length && filteredMapPoints.length > 0"
             [@matchesAnimation]>
          <div class="tags are-medium">
            <ng-container *ngFor="let match of filteredMapPointMatches">
              <strong class="tag is-primary">{{match}}</strong>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div *ngIf="activeTrackingTab === 'trackingMap'"
           class="card-image">
        <ng-container *ngTemplateOutlet="mapPoints.length > 0 ? map : mapNoData"></ng-container>
      </div>

      <ng-container *ngIf="activeTrackingTab === 'trackingTable'">
        <ng-container *ngTemplateOutlet="mapPoints.length > 0 ? containers : mapNoData"></ng-container>
      </ng-container>
    </div>
  </div>


</div>

<ng-template #loading>
  <ag-page-loading-placeholder title="Dashboard">
  </ag-page-loading-placeholder>
</ng-template>

<ng-template #emptyAlerts>
  <div class="clean-slate has-vertically-aligned-content p-md">
    <div class="clean-slate__image">
      <img src="assets/images/alarm-clock.svg"
           width="100"
           height="100" />
    </div>
    <div class="clean-slate__text">
      <div>You have no alerts</div>
    </div>
    <div *ngIf="orderAlerts && orderAlerts.length > 0 && visibleOrderAlerts.length === 0">
      <button type="button"
              class="button is-small"
              (click)="showHiddenAlerts = true">
        <fa-icon [icon]="icons.faEye"></fa-icon>&nbsp;
        Show Hidden Alerts
      </button>
    </div>
  </div>
</ng-template>

<ng-template #orderLinks
             let-location>
  <ng-container *ngIf="location.orders && location.orders.length === 1">
    <a [routerLink]="['/account/order/', location.orders[0].id]"
       style="text-decoration: underline; font-weight: bold;">
      View Order #{{location.orders[0].id}}
    </a>
  </ng-container>
  <ng-container *ngIf="location.orders && location.orders.length > 1">
    <strong>Orders:</strong>
    <ul>
      <li *ngFor="let order of location.orders">
        <a [routerLink]="['/account/order/', order.id]"
           style="text-decoration: underline; font-weight: bold;">
          Order #{{order.id}}
        </a>
      </li>
    </ul>
  </ng-container>
</ng-template>

<ng-template #alerts>
  <div class="table-container alerts-pannel">
    <table class="table is-fullwidth">
      <tr>
        <td style="text-align: right;vertical-align: middle; padding-left: 0.1em"
            colspan="5">
          <button type="button"
                  class="button is-small"
                  (click)="showHiddenAlerts = false"
                  *ngIf="showHiddenAlerts">
            <fa-icon [icon]="icons.faEyeSlash"></fa-icon>&nbsp;
            Hide Hidden Alerts
          </button>
          <button type="button"
                  class="button is-small"
                  (click)="showHiddenAlerts = true"
                  [disabled]="orderAlerts && orderAlerts.length === visibleOrderAlerts.length"
                  *ngIf="!showHiddenAlerts">
            <fa-icon [icon]="icons.faEye"></fa-icon>&nbsp;
            Show Hidden Alerts
          </button>
        </td>
      </tr>
      <ng-container *ngFor="let orderAlert of visibleOrderAlerts">
        <tr>
          <td style="vertical-align: middle;">
            <div [ngClass]="alertClass(orderAlert)"
                 class="alert-color-indicator"></div>
          </td>
          <td style="text-align: left; vertical-align: middle; padding-left: 0.1em; padding-right: 0.1em">
            <span class="has-text-weight-bold">
              {{orderAlert.eventText}}:&nbsp;{{orderAlert.alertText}}
            </span>
          </td>
          <td style="text-align: left; vertical-align: middle; padding-left: 0.7em; padding-right: 0.1em;">
            <span>
              Order #{{orderAlert.orderId}}
            </span>
          </td>
          <td style="text-align: left; vertical-align: middle; padding-left: 0.1em; padding-right: 0.1em;">
            <span>
              ({{orderAlert.affectedCompany.name | truncate : 15}})
            </span>
          </td>
          <td style="text-align: right;vertical-align: middle; padding-left: 0.1em">
            <span class="p-r-sm">
              <button type="button"
                      class="button is-small"
                      (click)="showKpiAlertModal(orderAlert)">
                <fa-icon [icon]="icons.faEye"></fa-icon>&nbsp;
                Details
              </button>
            </span>
            <button type="button"
                    class="button is-small"
                    (click)="hideOrderAlert(orderAlert)"
                    *ngIf="orderAlert.state !== 'hidden'">
              <fa-icon [icon]="icons.faEyeSlash"></fa-icon>&nbsp;
              Hide
            </button>
            <button type="button"
                    class="button is-small"
                    (click)="unhideOrderAlert(orderAlert)"
                    *ngIf="orderAlert.state === 'hidden'">
              <fa-icon [icon]="icons.faEye"></fa-icon>&nbsp;
              Unhide
            </button>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-template>

<ng-template #containers>
  <agx-pivot-table [columnMappings]="containerColumnMappings"
                   [selectedColumns]="containerSelectedColumns"
                   [isFullWidth]="true"
                   [data]="filteredContainers"
                   [itemsPerPage]="9"
                   [staticColumns]="true">
  </agx-pivot-table>
</ng-template>

<ng-template #mapNoData>
  <div class="clean-slate">
    <div class="clean-slate__image">
      <img src="assets/images/compass.svg"
           width="100"
           height="100" />
    </div>
    <div class="clean-slate__text">
      No tracking data to display
    </div>
  </div>
</ng-template>

<ng-template #map>
  <agm-map [streetViewControl]="false"
           [zoomControl]="true"
           [minZoom]="1"
           [maxZoom]="20"
           [zoom]="2"
           [styles]="mapStyles">
    <agm-marker-cluster imagePath="assets/images/google_maps/m">
      <ng-container *ngFor="let mapPoint of filteredMapPoints">

        <ng-container *ngIf="mapPoint.location.__typename === 'Vessel'">
          <agm-marker [latitude]="mapPoint.location.lat"
                      [longitude]="mapPoint.location.long"
                      [iconUrl]="{
url: getMapPinIconUrl(mapPoint),
strokeWeight: 3,
scaledSize: {width: 50, height: 50}
}">
            <agm-info-window>

              <ng-container *ngIf="mapPoint.match">
                <div>
                  <strong class="tag is-primary">{{mapPoint.match}}</strong>
                </div>
                <br />
              </ng-container>

              <div style="min-width: 200px">
                <h1 style="font-size: 1.2rem;"><b>{{mapPoint.location.vesselName}}</b></h1>
                <h1 style="font-size: 1.1rem; margin-bottom: 5px">Voyage {{mapPoint.location.voyageNumber}}</h1>
                <h1 style="font-size: 1rem;"><b>Total:</b> {{mapPoint.totalCartons | number}} cartons</h1>
                <hr style="margin: 10px 0" />
                <table class="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Container&nbsp;#</th>
                      <th>Order&nbsp;#</th>
                      <th>{{companyHelper.isSeller(myCompany) ? 'Buyer' : 'Seller'}}</th>
                      <th>POD</th>
                      <th>ETA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let container of mapPoint.containers">
                      <td>{{container.containerNumber}}</td>
                      <td>
                        <a [routerLink]="['/account/order/', container.orderId]">
                          {{container.orderId}}
                        </a>
                      </td>
                      <td>{{container.otherPartyName}}</td>
                      <td>{{container.portOfDestination}}</td>
                      <td>{{container.eta | date : 'yyyy/MM/dd'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </agm-info-window>
          </agm-marker>
        </ng-container>

        <ng-container *ngIf="mapPoint.location.__typename === 'SeaPort'">
          <agm-marker [latitude]="mapPoint.location.latitude"
                      [longitude]="mapPoint.location.longitude"
                      [iconUrl]="{
url: getMapPinIconUrl(mapPoint),
strokeWeight: 3,
scaledSize: {width: 50, height: 50}
}">
            <agm-info-window>

              <ng-container *ngIf="mapPoint.match">
                <div>
                  <strong class="tag is-primary">{{mapPoint.match}}</strong>
                </div>
                <br />
              </ng-container>

              <div style="min-width: 200px">
                <h1 style="font-size: 1.2rem; margin-bottom: 5px"><b>{{mapPoint.location.name | uppercase}},
                    {{mapPoint.location.country | uppercase}}</b></h1>
                <h1 style="font-size: 1rem;"><b>Total:</b> {{mapPoint.totalCartons | number}}
                  cartons</h1>
                <hr style="margin: 10px 0" />
                <table class="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Container&nbsp;#</th>
                      <th>Order&nbsp;#</th>
                      <th>{{companyHelper.isSeller(myCompany) ? 'Buyer' : 'Seller'}}</th>
                      <th>Vessel</th>
                      <th>POD</th>
                      <th>ETA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let container of mapPoint.containers">
                      <td>{{container.containerNumber}}</td>
                      <td>
                        <a [routerLink]="['/account/order/', container.orderId]">
                          {{container.orderId}}
                        </a>
                      </td>
                      <td>{{container.otherPartyName}}</td>
                      <td>{{container.vesselName}}</td>
                      <td>{{container.portOfDestination}}</td>
                      <td>{{container.eta | date : 'yyyy/MM/dd'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </agm-info-window>
          </agm-marker>
        </ng-container>

        <ng-container *ngIf="mapPoint.location.__typename === 'FoodBusinessOperator'">
          <agm-marker [latitude]="mapPoint.location.latitude"
                      [longitude]="mapPoint.location.longitude"
                      [iconUrl]="{
url: getMapPinIconUrl(mapPoint),
strokeWeight: 3,
scaledSize: {width: 50, height: 50}
}">
            <agm-info-window>

              <ng-container *ngIf="mapPoint.match">
                <div>
                  <strong class="tag is-primary">{{mapPoint.match}}</strong>
                </div>
                <br />
              </ng-container>

              <div style="min-width: 200px">
                <h1 style="font-size: 1.2rem"><b>{{mapPoint.location.name}}</b></h1>
                <h1 style="font-size: 1.1rem; margin-bottom: 5px">{{mapPoint.location.typeOfBusiness}}</h1>
                <h1 style="font-size: 1rem;"><b>Total:</b> {{mapPoint.totalCartons | number}} cartons</h1>
                <hr style="margin: 10px 0" />
                <table class="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Order&nbsp;#</th>
                      <th>{{companyHelper.isSeller(myCompany) ? 'Buyer' : 'Seller'}}</th>
                      <th>Cartons</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of mapPoint.orders">
                      <td>
                        <a [routerLink]="['/account/order/', order.orderId]">
                          {{order.orderId}}
                        </a>
                      </td>
                      <td>{{order.otherPartyName}}</td>
                      <td>{{order.totalCartons}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </agm-info-window>
          </agm-marker>
        </ng-container>
      </ng-container>
    </agm-marker-cluster>
  </agm-map>
</ng-template>
