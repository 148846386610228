import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalBaseConfig, ModalBaseComponent } from '@app/core/components/modal-base/modal-base.component';
import { OrderAlert, MyCompanyGQL, Company } from '@app/generated/graphql';
import { Modal } from '../../../core/interfaces/modal.interface';
import { Router } from '@angular/router';

export interface KpiAlertModalConfig extends ModalBaseConfig {
  orderAlert: OrderAlert;
  myCompany: Company;
}

@Component({
  selector: 'ag-kpi-alert-modal',
  templateUrl: './kpi-alert-modal.component.html',
  styleUrls: ['./kpi-alert-modal.component.scss']
})
export class KpiAlertModalComponent implements Modal, OnInit {

  @ViewChild(ModalBaseComponent, { static: true }) modalBase: ModalBaseComponent;

  orderAlert: OrderAlert;
  myCompany: Company;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  configure(config: KpiAlertModalConfig) {
    this.orderAlert = config.orderAlert;
    this.myCompany = config.myCompany;
    this.modalBase.isLoading = false;
    this.modalBase.configure(config);
    this.modalBase.setTitle('Alert Details');
    this.modalBase.setCancelBtnText('Close');
    this.modalBase.setCanConfirm(false);
    this.modalBase.setOnConfirm(() => this.hide());
  }

  routeToOrder() {
    this.hide();
    this.router.navigate([
      './account/order/',
      this.orderAlert.orderId
    ]);
  }

  routeToPackInstructions() {
    this.hide();
    this.router.navigate([
      './account/pack-instructions'
    ]);
  }

  unique(values: Array<string>): string {
    return [...new Set(values)].join(',');
  }

  show = () => this.modalBase.show();
  hide = () => this.modalBase.hide();
  setLoading = (isLoading: boolean) => this.modalBase.setLoading(isLoading);
}
