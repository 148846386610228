import { Injectable } from '@angular/core';
import { toast } from 'bulma-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  showToast(message: string) {
    toast({
      message: message,
      type: 'is-primary',
      position: 'bottom-center'
    });
  }

  showErrorToast(message: string) {
    toast({
      message: message,
      type: 'is-danger',
      position: 'bottom-center',
      duration: 4000
    });
  }

}
