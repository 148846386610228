import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalHostDirective } from '@app/core/directives/modal-host.directive';
import { ModalService } from '@app/core/services/modal/modal.service';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';
import { UserGQL, User, Company, OnAcceptLineItemsGQL, NewOrderGQL, OnWontPackLineItemsGQL, OnRevertLineItemsToCreatedGQL, } from '@app/generated/graphql';
import { CertificateHelper } from '@app/core/helpers/certificate.helper';
import { UserHelper } from '@app/core/helpers/user.helper';
import { UrlTrackerService } from '@app/core/services/url-tracker/url-tracker.service';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Angulartics2Mixpanel } from "angulartics2/mixpanel";

import {
  faBars,
  faList,
  faPlus,
  faUser,
  faCity,
  faSignOutAlt,
  faBoxes,
  faShip,
  faHandshake
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ag-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  @ViewChild(ModalHostDirective, { static: true }) modalHost: ModalHostDirective;

  myCompany: Company;
  user: User;
  showCertificateExpiryBanner = false;
  showV2Banner = true;
  initialized = false;
  icons = { faBars, faList, faPlus, faUser, faCity, faSignOutAlt, faBoxes, faShip, faHandshake };

  isExistingUser: boolean = null;

  constructor(private modalService: ModalService,
    private userGql: UserGQL,
    private urlTrackerService: UrlTrackerService,
    private router: Router,
    private newOrderGql: NewOrderGQL,
    private apollo: Apollo,
    private onAcceptLineItemsGql: OnAcceptLineItemsGQL,
    private onWontPackLineItemsGql: OnWontPackLineItemsGQL,
    private onRevertLineItemsToCreatedGql: OnRevertLineItemsToCreatedGQL,
    private angulartics2Mixpanel: Angulartics2Mixpanel) {
  }

  ngOnInit(): void {
    this.modalService.setHost(this.modalHost);
    const OneSignal = window['OneSignal'] || [];

    this.userGql.fetch().subscribe(gqlResponse => {
      this.myCompany = gqlResponse.data.myCompany;
      this.user = gqlResponse.data.myUser;
      this.isExistingUser = UserHelper.isExistingUser(this.user);

      if (environment.production) {
        if (!this.user.email.includes('@agrigate')) {
          this.angulartics2Mixpanel.setUsername(this.user.company.name);
          this.angulartics2Mixpanel.setUserProperties({
            '$email': this.user.email
          });
          this.angulartics2Mixpanel.startTracking();
        }
      }

      this.myCompany.certificates.forEach(cert => {
        if (this.expired(cert)) {
          this.showCertificateExpiryBanner = true;
          return;
        }
      });

      OneSignal.push(['init', {
        appId: environment.oneSignal.appId,
        autoRegister: true,
        notifyButton: {
          enable: false
        }
      }]);

      OneSignal.push(() => {
        OneSignal.sendTags({
          User: this.user.id,
          Company: this.myCompany.id
        });
      });

      Sentry.configureScope((scope) => {
        scope.setUser({
          id: this.user.id.toString(),
          email: this.user.email
        });
      });

      this.urlTrackerService.trackPreviousUrl();

      this.initialized = true;
    }, err => {
      Sentry.captureException(err);
      this.router.navigate(['account', '500'], { replaceUrl: true });
    });

    this.userGql.watch().valueChanges.subscribe(gqlResponse => {
      this.user = gqlResponse.data.myUser;
      this.myCompany = gqlResponse.data.myCompany;

      this.isExistingUser = UserHelper.isExistingUser(this.user);
    });


    this.newOrderGql.subscribe().subscribe(_ => {
      this.apollo.getClient().clearStore();
    });

    this.onAcceptLineItemsGql.subscribe().subscribe(res => {
      this.apollo.getClient().clearStore();
    });

    this.onWontPackLineItemsGql.subscribe().subscribe(res => {
      this.apollo.getClient().clearStore();
    });

    this.onRevertLineItemsToCreatedGql.subscribe().subscribe(res => {
      this.apollo.getClient().clearStore();
    });
  }

  expired(cert) {
    return CertificateHelper.inDangerOfExpiry(cert) || CertificateHelper.isExpired(cert);
  }
}
