import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  ISO8601DateTime: any,
  ISO8601Date: any,
  Json: any,
  Upload: any,
};



export type AcceptLineItemsPayload = {
   __typename?: 'AcceptLineItemsPayload',
  orders?: Maybe<Array<Order>>,
};

export type AdditionalField = {
   __typename?: 'AdditionalField',
  companyId?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  description?: Maybe<Scalars['String']>,
  fieldType?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  isPackingInstruction?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  parameters?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type Address = {
   __typename?: 'Address',
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  postalCode?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type AddressAttributes = {
  addressline1: Scalars['String'],
  addressline2?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  postalcode?: Maybe<Scalars['String']>,
  country: Scalars['String'],
};

export type AlertInformationUnion = OrderAcceptance | OrderFulfillment;

export enum AlertSeverityEnum {
  Error = 'error',
  Warn = 'warn'
}

export enum AlertStateEnum {
  Created = 'created',
  Hidden = 'hidden'
}

export type Audit = {
   __typename?: 'Audit',
  action?: Maybe<Scalars['String']>,
  associatedId?: Maybe<Scalars['Int']>,
  associatedType?: Maybe<Scalars['String']>,
  auditableId?: Maybe<Scalars['Int']>,
  auditableType?: Maybe<Scalars['String']>,
  auditedChanges?: Maybe<Array<Scalars['Json']>>,
  comment?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  user?: Maybe<User>,
  userId?: Maybe<Scalars['Int']>,
  userType?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  version?: Maybe<Scalars['Int']>,
};

export type CartonCount = {
   __typename?: 'CartonCount',
  commodityId?: Maybe<Scalars['Int']>,
  counts?: Maybe<Array<Scalars['String']>>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  priceMetric?: Maybe<Scalars['String']>,
  stackSizes?: Maybe<Array<Maybe<Scalars['Int']>>>,
};

export type CartonGrouping = {
   __typename?: 'CartonGrouping',
  advanceDueDate?: Maybe<Scalars['ISO8601DateTime']>,
  advancePrice?: Maybe<Scalars['Float']>,
  batchNumber?: Maybe<Scalars['String']>,
  buyer?: Maybe<Company>,
  buyerId?: Maybe<Scalars['Int']>,
  cartons?: Maybe<Scalars['Int']>,
  coldStore?: Maybe<Scalars['String']>,
  commercialTerm?: Maybe<CommercialTerm>,
  commercialTermId?: Maybe<Scalars['Int']>,
  consignmentNumber?: Maybe<Scalars['String']>,
  container?: Maybe<Container>,
  containerId?: Maybe<Scalars['Int']>,
  containerNumber?: Maybe<Scalars['String']>,
  exporterCode?: Maybe<Scalars['String']>,
  farmCode?: Maybe<Scalars['String']>,
  finalDueDate?: Maybe<Scalars['ISO8601DateTime']>,
  finalPrice?: Maybe<Scalars['Float']>,
  grade?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  inspectionDate?: Maybe<Scalars['ISO8601DateTime']>,
  inventoryCode?: Maybe<Scalars['String']>,
  lineItem?: Maybe<LineItem>,
  lineItemId?: Maybe<Scalars['Int']>,
  mark?: Maybe<Scalars['String']>,
  netMass?: Maybe<Scalars['Int']>,
  orchard?: Maybe<Scalars['String']>,
  pack?: Maybe<Scalars['String']>,
  packhouseCode?: Maybe<Scalars['String']>,
  packingWeek?: Maybe<Scalars['String']>,
  palletGrossMass?: Maybe<Scalars['Int']>,
  palletNumber?: Maybe<Scalars['String']>,
  palletRejected?: Maybe<Scalars['Boolean']>,
  palletStack?: Maybe<Scalars['Int']>,
  productCharacteristic?: Maybe<Scalars['String']>,
  seller?: Maybe<Company>,
  sellerId?: Maybe<Scalars['Int']>,
  sequenceNumber?: Maybe<Scalars['Int']>,
  sizeCount?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  targetCountry?: Maybe<Scalars['String']>,
  targetMarket?: Maybe<Scalars['String']>,
  targetRegion?: Maybe<Scalars['String']>,
  variety?: Maybe<Variety>,
  varietyId?: Maybe<Scalars['Int']>,
  voyage?: Maybe<Voyage>,
};

export type CertificateType = {
   __typename?: 'CertificateType',
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type Command = {
   __typename?: 'Command',
  batchId?: Maybe<Scalars['String']>,
  command?: Maybe<Scalars['String']>,
  commandType?: Maybe<CommandTypeEnum>,
  connectorName?: Maybe<Scalars['String']>,
};

export type CommandResultAttributes = {
  batchid: Scalars['String'],
  state: CommandStateEnum,
  jsonresult?: Maybe<Scalars['Json']>,
  zippedjson?: Maybe<Scalars['String']>,
};

export enum CommandStateEnum {
  OnPremisesSuccess = 'on_premises_success',
  OnPremisesError = 'on_premises_error',
  OnPremisesUnknown = 'on_premises_unknown'
}

export enum CommandTypeEnum {
  MssqlQuery = 'MSSQL_QUERY',
  CheckForUpdate = 'CHECK_FOR_UPDATE',
  Restart = 'RESTART',
  ReconnectDb = 'RECONNECT_DB',
  SystemReport = 'SYSTEM_REPORT'
}

export type Comment = {
   __typename?: 'Comment',
  commentText?: Maybe<Scalars['String']>,
  commentable?: Maybe<CommentableUnion>,
  commentableId?: Maybe<Scalars['Int']>,
  commentableType?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  isDeleted?: Maybe<Scalars['Boolean']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
  user?: Maybe<User>,
  userId?: Maybe<Scalars['Int']>,
};

export type CommentableUnion = Order;

export type CommercialTerm = {
   __typename?: 'CommercialTerm',
  advanceAmount?: Maybe<Scalars['Float']>,
  advanceCreditTerm?: Maybe<Scalars['Int']>,
  advanceEvent?: Maybe<Scalars['String']>,
  advanceType?: Maybe<Scalars['String']>,
  advanceUnit?: Maybe<Scalars['String']>,
  buyer?: Maybe<Company>,
  buyerId?: Maybe<Scalars['Int']>,
  commodity?: Maybe<Commodity>,
  deleted: Scalars['Boolean'],
  endDate?: Maybe<Scalars['ISO8601DateTime']>,
  finalCreditTerm?: Maybe<Scalars['Int']>,
  finalEvent?: Maybe<Scalars['String']>,
  grade?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  pack?: Maybe<Scalars['String']>,
  paymentTerm?: Maybe<Scalars['String']>,
  rankOneGroupEvent?: Maybe<Scalars['String']>,
  seller?: Maybe<Company>,
  sellerId?: Maybe<Scalars['Int']>,
  startDate?: Maybe<Scalars['ISO8601DateTime']>,
  targetRegion?: Maybe<Scalars['String']>,
};

export type CommercialTermChangeRequest = {
   __typename?: 'CommercialTermChangeRequest',
  commercialTerm?: Maybe<CommercialTerm>,
  flag?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  message?: Maybe<Scalars['String']>,
};

export type Commodity = {
   __typename?: 'Commodity',
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
  varieties?: Maybe<Array<Variety>>,
};

export type CommoditySeason = {
   __typename?: 'CommoditySeason',
  commodity?: Maybe<Commodity>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  endDate?: Maybe<Scalars['ISO8601Date']>,
  id?: Maybe<Scalars['Int']>,
  startDate?: Maybe<Scalars['ISO8601Date']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type Company = {
   __typename?: 'Company',
  about?: Maybe<Scalars['String']>,
  activeUsers?: Maybe<Array<User>>,
  additionalFields?: Maybe<Array<AdditionalField>>,
  address?: Maybe<Address>,
  averageRating?: Maybe<Scalars['Float']>,
  businessId?: Maybe<Scalars['Int']>,
  businessType?: Maybe<Scalars['String']>,
  certificates?: Maybe<Array<CompanyCertificate>>,
  commoditySeasons?: Maybe<Array<CommoditySeason>>,
  companies?: Maybe<Array<Company>>,
  completedShipments?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  invites?: Maybe<Array<Invite>>,
  logo?: Maybe<Scalars['String']>,
  logoFile?: Maybe<Document>,
  name?: Maybe<Scalars['String']>,
  numberOfRatings?: Maybe<Scalars['Int']>,
  registrationNumber?: Maybe<Scalars['String']>,
  tradeRegions?: Maybe<Array<TradeRegion>>,
  users?: Maybe<Array<User>>,
  varieties?: Maybe<Array<FarmVariety>>,
  vatNumber?: Maybe<Scalars['String']>,
};

export type CompanyCertificate = {
   __typename?: 'CompanyCertificate',
  certificate?: Maybe<Document>,
  certificateType?: Maybe<CertificateType>,
  companyId?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  expiryDate?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type Container = {
   __typename?: 'Container',
  audits?: Maybe<Array<Audit>>,
  id?: Maybe<Scalars['Int']>,
  incoterm?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
};

export type CreateAdditionalFieldAttributes = {
  companyid: Scalars['Int'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  fieldtype: Scalars['String'],
  parameters?: Maybe<Scalars['String']>,
  ispackinginstruction?: Maybe<Scalars['Boolean']>,
};

export type CreateAdditionalFieldPayload = {
   __typename?: 'CreateAdditionalFieldPayload',
  additionalField?: Maybe<AdditionalField>,
};

export type CreateCommentAttributes = {
  commentabletype: Scalars['String'],
  commentableid: Scalars['Int'],
  userid: Scalars['Int'],
  commenttext: Scalars['String'],
};

export type CreateCommentPayload = {
   __typename?: 'CreateCommentPayload',
  comment?: Maybe<Comment>,
};

export type CreateCommercialTermChangeRequestAttributes = {
  commercialtermsid: Scalars['Int'],
  message: Scalars['String'],
  flag: Scalars['String'],
};

export type CreateCommercialTermChangeRequestPayload = {
   __typename?: 'CreateCommercialTermChangeRequestPayload',
  changeRequest?: Maybe<CommercialTermChangeRequest>,
  errors?: Maybe<Scalars['String']>,
};

export type CreateFarmVarietyAttributes = {
  farmid: Scalars['Int'],
  varietyid: Scalars['Int'],
  peakcount?: Maybe<Scalars['String']>,
  hectares?: Maybe<Scalars['Int']>,
  packingstart: Scalars['String'],
  packingend: Scalars['String'],
};

export type CreateFarmVarietyPayload = {
   __typename?: 'CreateFarmVarietyPayload',
  farmVariety?: Maybe<FarmVariety>,
};

export type CreateLineItemAttributes = {
  varietyid: Scalars['Int'],
  commodityid: Scalars['Int'],
  quantity?: Maybe<Scalars['Int']>,
  quantityunit?: Maybe<Scalars['String']>,
  priceminorunit?: Maybe<Scalars['Int']>,
  priceunit?: Maybe<Scalars['String']>,
  currency: Scalars['String'],
  sizecounts: Array<Scalars['String']>,
  pack: Scalars['String'],
  priceterm: Scalars['String'],
  additionalfields: Scalars['String'],
  palletstack: Scalars['Int'],
  unlimited: Scalars['Boolean'],
  targetmarket?: Maybe<Scalars['String']>,
  targetregion: Scalars['String'],
  targetcountry?: Maybe<Scalars['String']>,
  packingweek?: Maybe<Scalars['String']>,
  startweek?: Maybe<Scalars['String']>,
  endweek?: Maybe<Scalars['String']>,
  incoterm: Scalars['String'],
  grade?: Maybe<Scalars['String']>,
  mark?: Maybe<Scalars['String']>,
  referencenumber?: Maybe<Scalars['String']>,
  rank?: Maybe<Scalars['Int']>,
  batchnumber?: Maybe<Scalars['String']>,
  inventorycode?: Maybe<Scalars['String']>,
};

export type CreateLineItemGroupingAttributes = {
  cumulativequantity?: Maybe<Scalars['Int']>,
  quantitytype?: Maybe<Scalars['String']>,
  commonfields: Array<Scalars['String']>,
  lineitems: Array<CreateLineItemAttributes>,
  rank?: Maybe<Scalars['Int']>,
};

export type CreateOrderAttributes = {
  buyerid: Scalars['Int'],
  sellerid: Scalars['Int'],
  userid: Scalars['Int'],
  ispackhouserun: Scalars['Boolean'],
  commonfields?: Maybe<Array<Scalars['String']>>,
  lineitemgroupings?: Maybe<Array<CreateLineItemGroupingAttributes>>,
};

export type CreateOrderPayload = {
   __typename?: 'CreateOrderPayload',
  errors?: Maybe<Scalars['String']>,
  order?: Maybe<Order>,
};

export type CreateTicketAttributes = {
  subject: Scalars['String'],
  description: Scalars['String'],
};

export type CreateTicketPayload = {
   __typename?: 'CreateTicketPayload',
  ticket?: Maybe<Ticket>,
};

export type DeleteAdditionalFieldPayload = {
   __typename?: 'DeleteAdditionalFieldPayload',
  id?: Maybe<Scalars['Int']>,
};

export type DeleteCommentPayload = {
   __typename?: 'DeleteCommentPayload',
  comment?: Maybe<Comment>,
};

export type DeleteCompanyCertificatePayload = {
   __typename?: 'DeleteCompanyCertificatePayload',
  id?: Maybe<Scalars['Int']>,
};

export type DeleteEmployeePayload = {
   __typename?: 'DeleteEmployeePayload',
  employeeId?: Maybe<Scalars['Int']>,
};

export type DeleteFarmVarietyImagePayload = {
   __typename?: 'DeleteFarmVarietyImagePayload',
  farmVariety?: Maybe<FarmVariety>,
};

export type DeleteFarmVarietyPayload = {
   __typename?: 'DeleteFarmVarietyPayload',
  farmVariety?: Maybe<FarmVariety>,
};

export type DeletePalletGroupingPayload = {
   __typename?: 'DeletePalletGroupingPayload',
  id?: Maybe<Scalars['Int']>,
};

export type DeleteShipmentDocumentPayload = {
   __typename?: 'DeleteShipmentDocumentPayload',
  id?: Maybe<Scalars['Int']>,
};

export type Document = {
   __typename?: 'Document',
  filename?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  v2Url?: Maybe<Scalars['String']>,
};

export type Employee = {
   __typename?: 'Employee',
  id?: Maybe<Scalars['Int']>,
};

export type FarmVariety = {
   __typename?: 'FarmVariety',
  commodity?: Maybe<Commodity>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  farmId?: Maybe<Scalars['Int']>,
  hectares?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Int']>,
  images?: Maybe<Array<Image>>,
  name?: Maybe<Scalars['String']>,
  packingEnd?: Maybe<Scalars['String']>,
  packingStart?: Maybe<Scalars['String']>,
  peakCount?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
  varietyId?: Maybe<Scalars['Int']>,
};

export type FoodBusinessOperator = {
   __typename?: 'FoodBusinessOperator',
  createdAt: Scalars['ISO8601DateTime'],
  fboCode?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  latitude?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  typeOfBusiness?: Maybe<Scalars['String']>,
  updatedAt: Scalars['ISO8601DateTime'],
};

export type GenerateDemoDataPayload = {
   __typename?: 'GenerateDemoDataPayload',
  order?: Maybe<Order>,
};

export type GetPricingHelpPayload = {
   __typename?: 'GetPricingHelpPayload',
  cifPriceMinorUnit: Scalars['Int'],
  currency: Scalars['String'],
  dipPriceMinorUnit: Scalars['Int'],
  exwPriceMinorUnit: Scalars['Int'],
  fobPriceMinorUnit: Scalars['Int'],
  fotPriceMinorUnit: Scalars['Int'],
};

export type HideOrderAlertPayload = {
   __typename?: 'HideOrderAlertPayload',
  orderAlert?: Maybe<OrderAlert>,
};

export type Image = {
   __typename?: 'Image',
  filename?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  thumbnailUrl?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  v2ThumbnailUrl?: Maybe<Scalars['String']>,
  v2Url?: Maybe<Scalars['String']>,
};

export type Invite = {
   __typename?: 'Invite',
  company?: Maybe<Company>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  email?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  initiator?: Maybe<User>,
  message?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type InviteUserPayload = {
   __typename?: 'InviteUserPayload',
  invite?: Maybe<Invite>,
};




export type LineItem = {
   __typename?: 'LineItem',
  additionalFields?: Maybe<Scalars['Json']>,
  additionalFieldsForPackInstructions?: Maybe<Scalars['Json']>,
  audits?: Maybe<Array<Audit>>,
  batchNumber?: Maybe<Scalars['String']>,
  cartonGroupings?: Maybe<Array<CartonGrouping>>,
  commodity?: Maybe<Commodity>,
  commodityId?: Maybe<Scalars['Int']>,
  containerId?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  currency?: Maybe<Scalars['String']>,
  deleted?: Maybe<Scalars['Boolean']>,
  departurePort?: Maybe<Array<SeaPort>>,
  destinationPort?: Maybe<Array<SeaPort>>,
  endWeek?: Maybe<Scalars['String']>,
  grade?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  incoterm?: Maybe<Scalars['String']>,
  inventoryCode?: Maybe<Scalars['String']>,
  labelImage?: Maybe<Array<Image>>,
  mark?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  pack?: Maybe<Scalars['String']>,
  packedCartons?: Maybe<Scalars['Int']>,
  packedPallets?: Maybe<Scalars['Int']>,
  packingWeek?: Maybe<Scalars['String']>,
  palletGroupingId?: Maybe<Scalars['Int']>,
  palletStack?: Maybe<Scalars['Int']>,
  priceMinorUnit?: Maybe<Scalars['Int']>,
  priceTerm?: Maybe<Scalars['String']>,
  priceUnit?: Maybe<Scalars['String']>,
  quantity?: Maybe<Scalars['Int']>,
  quantityUnit?: Maybe<Scalars['String']>,
  rank?: Maybe<Scalars['Int']>,
  referenceNumber?: Maybe<Scalars['String']>,
  shipmentId?: Maybe<Scalars['Int']>,
  sizeCounts?: Maybe<Array<Scalars['String']>>,
  startWeek?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  statesDistribution?: Maybe<Array<LineItemState>>,
  targetCountry?: Maybe<Scalars['String']>,
  targetMarket?: Maybe<Scalars['String']>,
  targetRegion?: Maybe<Scalars['String']>,
  unlimited?: Maybe<Scalars['Boolean']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
  variety?: Maybe<Variety>,
  varietyId?: Maybe<Scalars['Int']>,
};

export type LineItemGrouping = {
   __typename?: 'LineItemGrouping',
  commonFields?: Maybe<Array<Scalars['String']>>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  cumulativeQuantity?: Maybe<Scalars['Int']>,
  deleted?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['Int']>,
  lineItems?: Maybe<Array<LineItem>>,
  orderId?: Maybe<Scalars['Int']>,
  quantityType?: Maybe<Scalars['String']>,
  rank?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};


export type LineItemGroupingLineItemsArgs = {
  lineItemStates?: Maybe<Array<Scalars['String']>>,
  packingWeekStart?: Maybe<Scalars['String']>,
  packingWeekEnd?: Maybe<Scalars['String']>
};

export type LineItemState = {
   __typename?: 'LineItemState',
  palletQuantity?: Maybe<Scalars['Int']>,
  palletQuantityTotal?: Maybe<Scalars['Int']>,
  quanity?: Maybe<Scalars['Int']>,
  quanityTotal?: Maybe<Scalars['Int']>,
  quanityType?: Maybe<Quantity>,
  quantity?: Maybe<Scalars['Int']>,
  quantityTotal?: Maybe<Scalars['Int']>,
  state?: Maybe<Scalars['String']>,
  supplyChainOrder?: Maybe<Scalars['Int']>,
};

export type Location = {
   __typename?: 'Location',
  createdAt: Scalars['ISO8601DateTime'],
  id?: Maybe<Scalars['ID']>,
  latitude: Scalars['String'],
  longitude: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  voyage?: Maybe<Voyage>,
};

export type MapPoint = {
   __typename?: 'MapPoint',
  containers?: Maybe<Array<MapPointContainer>>,
  location?: Maybe<MapPointLocatableUnion>,
  orders?: Maybe<Array<MapPointOrder>>,
  totalCartons?: Maybe<Scalars['Int']>,
};

export type MapPointContainer = {
   __typename?: 'MapPointContainer',
  containerNumber?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  orderId?: Maybe<Scalars['Int']>,
  otherPartyName?: Maybe<Scalars['String']>,
  portOfDestination?: Maybe<Scalars['String']>,
  vesselName?: Maybe<Scalars['String']>,
};

export type MapPointLocatableUnion = FoodBusinessOperator | SeaPort | Vessel;

export type MapPointOrder = {
   __typename?: 'MapPointOrder',
  orderId?: Maybe<Scalars['Int']>,
  otherPartyName?: Maybe<Scalars['String']>,
  totalCartons?: Maybe<Scalars['Int']>,
};

export type MetabaseReport = {
   __typename?: 'MetabaseReport',
  chartType?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  acceptLineItems?: Maybe<AcceptLineItemsPayload>,
  createAdditionalField?: Maybe<CreateAdditionalFieldPayload>,
  createComment?: Maybe<CreateCommentPayload>,
  createCommercialTermChangeRequest?: Maybe<CreateCommercialTermChangeRequestPayload>,
  createFarmVariety?: Maybe<CreateFarmVarietyPayload>,
  createOrder?: Maybe<CreateOrderPayload>,
  createTicket?: Maybe<CreateTicketPayload>,
  deleteAdditionalField?: Maybe<DeleteAdditionalFieldPayload>,
  deleteComment?: Maybe<DeleteCommentPayload>,
  deleteCompanyCertificate?: Maybe<DeleteCompanyCertificatePayload>,
  deleteEmployee?: Maybe<DeleteEmployeePayload>,
  deleteFarmVariety?: Maybe<DeleteFarmVarietyPayload>,
  deleteFarmVarietyImage?: Maybe<DeleteFarmVarietyImagePayload>,
  deletePalletGrouping?: Maybe<DeletePalletGroupingPayload>,
  deleteShipmentDocument?: Maybe<DeleteShipmentDocumentPayload>,
  generateDemoData?: Maybe<GenerateDemoDataPayload>,
  getPricingHelp?: Maybe<GetPricingHelpPayload>,
  hideOrderAlert?: Maybe<HideOrderAlertPayload>,
  inviteUser?: Maybe<InviteUserPayload>,
  progressPalletGrouping?: Maybe<ProgressPalletGroupingPayload>,
  rejectPalletGrouping?: Maybe<RejectPalletGroupingPayload>,
  resendInvite?: Maybe<ResendInvitePayload>,
  revertLineItemsToCreated?: Maybe<RevertLineItemsToCreatedPayload>,
  revertOrderChange?: Maybe<RevertOrderChangePayload>,
  revokeInvite?: Maybe<RevokeInvitePayload>,
  revokeRejectedPalletGrouping?: Maybe<RevokeRejectedPalletGroupingPayload>,
  togglePackInstructionsRowPriority?: Maybe<TogglePackInstructionsRowPriorityPayload>,
  unhideOrderAlert?: Maybe<UnhideOrderAlertPayload>,
  updateAdditionalField?: Maybe<UpdateAdditionalFieldPayload>,
  updateCommandResult?: Maybe<UpdateCommandResultPayload>,
  updateCompanyDetails?: Maybe<UpdateCompanyDetailsPayload>,
  updateCompanyTradeRegions?: Maybe<UpdateCompanyTradeRegionsPayload>,
  updateFarmVariety?: Maybe<UpdateFarmVarietyPayload>,
  updateOrder?: Maybe<UpdateOrderPayload>,
  updateShipmentDocument?: Maybe<UpdateShipmentDocumentPayload>,
  updateUser?: Maybe<UpdateUserPayload>,
  updateUserFeedItem?: Maybe<UpdateUserFeedItemPayload>,
  updateUserFeedItems?: Maybe<UpdateUserFeedItemsPayload>,
  uploadCompanyCertificate?: Maybe<UploadCompanyCertificatePayload>,
  uploadCompanyLogo?: Maybe<UploadCompanyLogoPayload>,
  uploadFarmVarietyImage?: Maybe<UploadFarmVarietyImagePayload>,
  uploadOrderShipmentDocument?: Maybe<UploadOrderShipmentDocumentPayload>,
  uploadUserAvatar?: Maybe<UploadUserAvatarPayload>,
  wontPackLineItems?: Maybe<WontPackLineItemsPayload>,
};


export type MutationAcceptLineItemsArgs = {
  ids: Array<Scalars['Int']>
};


export type MutationCreateAdditionalFieldArgs = {
  attributes: CreateAdditionalFieldAttributes
};


export type MutationCreateCommentArgs = {
  attributes: CreateCommentAttributes
};


export type MutationCreateCommercialTermChangeRequestArgs = {
  attributes: CreateCommercialTermChangeRequestAttributes
};


export type MutationCreateFarmVarietyArgs = {
  attributes: CreateFarmVarietyAttributes
};


export type MutationCreateOrderArgs = {
  attributes: CreateOrderAttributes
};


export type MutationCreateTicketArgs = {
  attributes: CreateTicketAttributes
};


export type MutationDeleteAdditionalFieldArgs = {
  id: Scalars['Int']
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['Int']
};


export type MutationDeleteCompanyCertificateArgs = {
  id: Scalars['Int']
};


export type MutationDeleteEmployeeArgs = {
  employeeId: Scalars['Int']
};


export type MutationDeleteFarmVarietyArgs = {
  varietyId: Scalars['Int'],
  farmId: Scalars['Int']
};


export type MutationDeleteFarmVarietyImageArgs = {
  varietyId: Scalars['Int'],
  imageId: Scalars['Int']
};


export type MutationDeletePalletGroupingArgs = {
  id: Scalars['Int']
};


export type MutationDeleteShipmentDocumentArgs = {
  id: Scalars['Int']
};


export type MutationGenerateDemoDataArgs = {
  id: Scalars['Int']
};


export type MutationGetPricingHelpArgs = {
  attributes: PricingHelperAttributes
};


export type MutationHideOrderAlertArgs = {
  id: Scalars['Int']
};


export type MutationInviteUserArgs = {
  email: Scalars['String']
};


export type MutationProgressPalletGroupingArgs = {
  id: Scalars['Int']
};


export type MutationRejectPalletGroupingArgs = {
  id: Scalars['Int']
};


export type MutationResendInviteArgs = {
  id: Scalars['Int']
};


export type MutationRevertLineItemsToCreatedArgs = {
  ids: Array<Scalars['Int']>
};


export type MutationRevertOrderChangeArgs = {
  startId: Scalars['Int'],
  endId: Scalars['Int']
};


export type MutationRevokeInviteArgs = {
  id: Scalars['Int']
};


export type MutationRevokeRejectedPalletGroupingArgs = {
  id: Scalars['Int']
};


export type MutationTogglePackInstructionsRowPriorityArgs = {
  lineitemids: Array<Scalars['Int']>
};


export type MutationUnhideOrderAlertArgs = {
  id: Scalars['Int']
};


export type MutationUpdateAdditionalFieldArgs = {
  id: Scalars['Int'],
  attributes: UpdateAdditionalFieldAttributes
};


export type MutationUpdateCommandResultArgs = {
  attributes: CommandResultAttributes
};


export type MutationUpdateCompanyDetailsArgs = {
  attributes: UpdateCompanyAttributes
};


export type MutationUpdateCompanyTradeRegionsArgs = {
  addTradeRegions: Array<TradeRegionAttributes>,
  removeTradeRegions: Array<TradeRegionAttributes>
};


export type MutationUpdateFarmVarietyArgs = {
  attributes: UpdateFarmVarietyAttributes
};


export type MutationUpdateOrderArgs = {
  attributes: UpdateOrderAttributes
};


export type MutationUpdateShipmentDocumentArgs = {
  id: Scalars['Int'],
  type: Scalars['String']
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  phoneNumber: Scalars['String'],
  notifyEmail: Scalars['Boolean'],
  notifyBrowserPush: Scalars['Boolean'],
  useV2: Scalars['Boolean']
};


export type MutationUpdateUserFeedItemArgs = {
  attributes: UpdateUserFeedItemAttributes
};


export type MutationUpdateUserFeedItemsArgs = {
  attributes: Array<UpdateUserFeedItemAttributes>
};


export type MutationUploadCompanyCertificateArgs = {
  companyId: Scalars['Int'],
  file: Scalars['Upload'],
  expiryDate: Scalars['String'],
  certificateTypeId: Scalars['Int']
};


export type MutationUploadCompanyLogoArgs = {
  companyId: Scalars['Int'],
  file: Scalars['Upload']
};


export type MutationUploadFarmVarietyImageArgs = {
  id: Scalars['Int'],
  image: Scalars['Upload']
};


export type MutationUploadOrderShipmentDocumentArgs = {
  orderid: Scalars['Int'],
  type: Scalars['String'],
  file: Scalars['Upload']
};


export type MutationUploadUserAvatarArgs = {
  file: Scalars['Upload']
};


export type MutationWontPackLineItemsArgs = {
  ids: Array<Scalars['Int']>
};

export type NotifiableUnion = Comment;

export type Order = {
   __typename?: 'Order',
  audits?: Maybe<Array<Audit>>,
  buyer?: Maybe<Company>,
  comments?: Maybe<Array<Comment>>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  isPackhouseRun?: Maybe<Scalars['Boolean']>,
  lineItemGroupings?: Maybe<Array<LineItemGrouping>>,
  lineItems?: Maybe<Array<LineItem>>,
  seller?: Maybe<Company>,
  shipmentDocuments?: Maybe<Array<ShipmentDocument>>,
  state?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
  user?: Maybe<User>,
};

export type OrderAcceptance = {
   __typename?: 'OrderAcceptance',
  lineItemDates?: Maybe<Array<Scalars['ISO8601DateTime']>>,
  lineItemIds?: Maybe<Array<Scalars['Int']>>,
  lineItemPackingWeeks?: Maybe<Array<Scalars['String']>>,
};

export type OrderAlert = {
   __typename?: 'OrderAlert',
  additionalInformation?: Maybe<AlertInformationUnion>,
  affectedCompany?: Maybe<Company>,
  affectedCompanyId?: Maybe<Scalars['Int']>,
  alertText?: Maybe<Scalars['String']>,
  company?: Maybe<Company>,
  companyId?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  eventText?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  importance?: Maybe<Scalars['Int']>,
  order?: Maybe<Order>,
  orderId?: Maybe<Scalars['Int']>,
  severity?: Maybe<AlertSeverityEnum>,
  state?: Maybe<AlertStateEnum>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type OrderBy = {
  column?: Maybe<OrderByColumn>,
  direction?: Maybe<OrderByDirection>,
};

export enum OrderByColumn {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  ReferenceNumber = 'reference_number',
  Company = 'company',
  Id = 'id',
  State = 'state'
}

export enum OrderByDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type OrderFilter = {
  commoditycontains?: Maybe<Scalars['String']>,
  companycontains?: Maybe<Scalars['String']>,
  companyids?: Maybe<Array<Scalars['Int']>>,
  states?: Maybe<Array<StateFilter>>,
  referencestartswith?: Maybe<Scalars['String']>,
  packingweekcontains?: Maybe<Scalars['String']>,
  commodityseasonids?: Maybe<Array<Scalars['Int']>>,
  ordering?: Maybe<Array<OrderBy>>,
};

export type OrderFulfillment = {
   __typename?: 'OrderFulfillment',
  lineItemIds?: Maybe<Array<Scalars['Int']>>,
  lineItemPackedQuantity?: Maybe<Array<Scalars['Int']>>,
  lineItemPackingWeeks?: Maybe<Array<Scalars['String']>>,
  lineItemQuantities?: Maybe<Array<Scalars['Int']>>,
  lineItemQuantityTypes?: Maybe<Array<Scalars['String']>>,
};

export type OrderSearch = {
   __typename?: 'OrderSearch',
  orders?: Maybe<Array<Order>>,
  totalFiltered?: Maybe<Scalars['Int']>,
  totalOrders?: Maybe<Scalars['Int']>,
};


export type OrderSearchOrdersArgs = {
  limit?: Maybe<Scalars['Int']>,
  skip?: Maybe<Scalars['Int']>
};

export type PackInstructionsLineItemRow = {
   __typename?: 'PackInstructionsLineItemRow',
  additionalFields?: Maybe<Scalars['Json']>,
  batchNumber?: Maybe<Scalars['String']>,
  buyerName?: Maybe<Scalars['String']>,
  grade?: Maybe<Scalars['String']>,
  inventoryCode?: Maybe<Scalars['String']>,
  lineItemId?: Maybe<Scalars['Int']>,
  mark?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['Int']>,
  pack?: Maybe<Scalars['String']>,
  packingWeek?: Maybe<Scalars['String']>,
  palletStack?: Maybe<Scalars['Int']>,
  priority?: Maybe<Scalars['Boolean']>,
  quantitiesPacked?: Maybe<Array<Scalars['Int']>>,
  quantityRequested?: Maybe<Scalars['Int']>,
  quantityType?: Maybe<Scalars['String']>,
  sizeCounts?: Maybe<Array<Scalars['String']>>,
  state?: Maybe<Scalars['String']>,
  targetMarket?: Maybe<Scalars['String']>,
  targetRegion?: Maybe<Scalars['String']>,
  varietyName?: Maybe<Scalars['String']>,
};

export type PackInstructionsRow = {
   __typename?: 'PackInstructionsRow',
  lineItems?: Maybe<Array<PackInstructionsLineItemRow>>,
  quantitiesPacked?: Maybe<Scalars['Int']>,
  quantityRequested?: Maybe<Scalars['Int']>,
  quantityType?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
};

export type PageInfo = {
   __typename?: 'PageInfo',
  endCursor?: Maybe<Scalars['String']>,
  hasNextPage: Scalars['Boolean'],
  hasPreviousPage: Scalars['Boolean'],
  startCursor?: Maybe<Scalars['String']>,
};

export type PricingHelperAttributes = {
  companytype: Scalars['String'],
  buyerid: Scalars['Int'],
  sellerid: Scalars['Int'],
  quantity: Scalars['Int'],
  quantityunit: Scalars['String'],
  palletstack: Scalars['Int'],
  varietyid: Scalars['Int'],
  priceminorunit: Scalars['Int'],
  priceunit: Scalars['String'],
  currency: Scalars['String'],
  targetregion: Scalars['String'],
  targetcountry: Scalars['String'],
  incoterm: Scalars['String'],
};

export type ProgressPalletGroupingPayload = {
   __typename?: 'ProgressPalletGroupingPayload',
  id?: Maybe<Scalars['Int']>,
  state?: Maybe<Scalars['String']>,
};

export enum Quantity {
  Carton = 'CARTON',
  Pallet = 'PALLET',
  Container = 'CONTAINER'
}

export type Query = {
   __typename?: 'Query',
  additionalFields?: Maybe<Array<AdditionalField>>,
  cartonCounts?: Maybe<Array<CartonCount>>,
  cartonGroupings?: Maybe<Array<CartonGrouping>>,
  cartonGroupingsForLineItem?: Maybe<Array<CartonGrouping>>,
  cartonGroupingsForWeek?: Maybe<Array<CartonGrouping>>,
  cartonsPerBuyerChartUrl?: Maybe<Scalars['String']>,
  cartonsPerSellerChartUrl?: Maybe<Scalars['String']>,
  certificateTypes?: Maybe<Array<CertificateType>>,
  commercialTerms?: Maybe<Array<CommercialTerm>>,
  commodities?: Maybe<Array<Commodity>>,
  commoditySplitChartUrl?: Maybe<Scalars['String']>,
  company?: Maybe<Company>,
  currentMapPoints?: Maybe<Array<MapPoint>>,
  currentMapPointsForOrder?: Maybe<Array<MapPoint>>,
  metabaseReports?: Maybe<Array<MetabaseReport>>,
  myCompany?: Maybe<Company>,
  myUser?: Maybe<User>,
  order?: Maybe<Order>,
  orderAlerts?: Maybe<Array<OrderAlert>>,
  orders?: Maybe<Array<Order>>,
  ordersSearch?: Maybe<OrderSearch>,
  packinstructions?: Maybe<Array<PackInstructionsRow>>,
  quantityType?: Maybe<Quantity>,
  targetMarketSplitChartUrl?: Maybe<Scalars['String']>,
  tickets?: Maybe<Array<Ticket>>,
  tradePartners?: Maybe<Array<Company>>,
  tradeRegions?: Maybe<Array<TradeRegion>>,
  userFeedConnection: UserFeedItemConnection,
  userFeedUnseenCount: Scalars['Int'],
};


export type QueryAdditionalFieldsArgs = {
  companyIds: Array<Scalars['Int']>
};


export type QueryCartonGroupingsArgs = {
  orderid?: Maybe<Scalars['Int']>,
  seasonids?: Maybe<Array<Scalars['Int']>>,
  packingweek?: Maybe<Scalars['String']>,
  startweek?: Maybe<Scalars['String']>,
  endweek?: Maybe<Scalars['String']>,
  startduedate?: Maybe<Scalars['String']>,
  endduedate?: Maybe<Scalars['String']>,
  companyid?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderByStuffDate?: Maybe<Scalars['Boolean']>,
  currency?: Maybe<Scalars['String']>
};


export type QueryCartonGroupingsForLineItemArgs = {
  id: Scalars['Int']
};


export type QueryCartonGroupingsForWeekArgs = {
  week: Scalars['String']
};


export type QueryCommercialTermsArgs = {
  startweek?: Maybe<Scalars['String']>,
  endweek?: Maybe<Scalars['String']>,
  companyid?: Maybe<Scalars['Int']>,
  targetregion?: Maybe<Scalars['String']>,
  advancetype?: Maybe<Scalars['String']>,
  advanceunit?: Maybe<Scalars['String']>,
  commodity?: Maybe<Scalars['String']>,
  advanceevent?: Maybe<Scalars['String']>
};


export type QueryCompanyArgs = {
  id: Scalars['ID']
};


export type QueryCurrentMapPointsForOrderArgs = {
  id: Scalars['Int']
};


export type QueryMetabaseReportsArgs = {
  chartType: Scalars['String']
};


export type QueryOrderArgs = {
  id: Scalars['ID']
};


export type QueryOrdersSearchArgs = {
  filter?: Maybe<OrderFilter>
};


export type QueryTicketsArgs = {
  email: Scalars['String']
};


export type QueryTradePartnersArgs = {
  id: Scalars['ID']
};


export type QueryUserFeedConnectionArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type RejectPalletGroupingPayload = {
   __typename?: 'RejectPalletGroupingPayload',
  id?: Maybe<Scalars['Int']>,
  state?: Maybe<Scalars['String']>,
};

export type ResendInvitePayload = {
   __typename?: 'ResendInvitePayload',
  id?: Maybe<Scalars['Int']>,
};

export type RevertLineItemsToCreatedPayload = {
   __typename?: 'RevertLineItemsToCreatedPayload',
  orders?: Maybe<Array<Order>>,
};

export type RevertOrderChangePayload = {
   __typename?: 'RevertOrderChangePayload',
  order?: Maybe<Order>,
};

export type RevokeInvitePayload = {
   __typename?: 'RevokeInvitePayload',
  id?: Maybe<Scalars['Int']>,
};

export type RevokeRejectedPalletGroupingPayload = {
   __typename?: 'RevokeRejectedPalletGroupingPayload',
  id?: Maybe<Scalars['Int']>,
  state?: Maybe<Scalars['String']>,
};

export type Role = {
   __typename?: 'Role',
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  resourceId?: Maybe<Scalars['Int']>,
  resourceType?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type SeaPort = {
   __typename?: 'SeaPort',
  country?: Maybe<Scalars['String']>,
  createdAt: Scalars['ISO8601DateTime'],
  id?: Maybe<Scalars['Int']>,
  latitude?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  unLocode?: Maybe<Scalars['String']>,
  updatedAt: Scalars['ISO8601DateTime'],
};

export type ShipmentDocument = {
   __typename?: 'ShipmentDocument',
  documentType?: Maybe<Scalars['String']>,
  file?: Maybe<Document>,
  id?: Maybe<Scalars['Int']>,
  state?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export enum StateFilter {
  Expired = 'expired',
  WontPack = 'wont_pack',
  Created = 'created',
  Accepted = 'accepted',
  PartiallyPacked = 'partially_packed',
  Packed = 'packed',
  AtColdStore = 'at_cold_store',
  AtRailDepot = 'at_rail_depot',
  AtOriginPort = 'at_origin_port',
  InTransit = 'in_transit',
  AtDestinationPort = 'at_destination_port',
  Delivered = 'delivered',
  PartiallyDelivered = 'partially_delivered',
  PartiallyAccepted = 'partially_accepted'
}

export type SubCommodity = {
   __typename?: 'SubCommodity',
  commodity?: Maybe<Commodity>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type Subscription = {
   __typename?: 'Subscription',
  newOrder: Order,
  newUserFeedItem: UserFeedItem,
  onAcceptLineItems: Order,
  onPremCommands: Array<Command>,
  onRevertLineItemsToCreated: Order,
  onWontPackLineItems: Order,
};

export type Ticket = {
   __typename?: 'Ticket',
  createdAt?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
  subject?: Maybe<Scalars['String']>,
};

export type TogglePackInstructionsRowPriorityPayload = {
   __typename?: 'TogglePackInstructionsRowPriorityPayload',
  packInstructionsRows?: Maybe<Array<PackInstructionsLineItemRow>>,
};

export type TradeCountry = {
   __typename?: 'TradeCountry',
  code?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
};

export type TradeRegion = {
   __typename?: 'TradeRegion',
  code?: Maybe<Scalars['String']>,
  countries?: Maybe<Array<TradeCountry>>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
};

export type TradeRegionAttributes = {
  id: Scalars['Int'],
  name: Scalars['String'],
  code: Scalars['String'],
};

export type UnhideOrderAlertPayload = {
   __typename?: 'UnhideOrderAlertPayload',
  orderAlert?: Maybe<OrderAlert>,
};

export type UpdateAdditionalFieldAttributes = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  fieldtype: Scalars['String'],
  parameters?: Maybe<Scalars['String']>,
  ispackinginstruction?: Maybe<Scalars['Boolean']>,
};

export type UpdateAdditionalFieldPayload = {
   __typename?: 'UpdateAdditionalFieldPayload',
  additionalField?: Maybe<AdditionalField>,
};

export type UpdateCommandResultPayload = {
   __typename?: 'UpdateCommandResultPayload',
  result?: Maybe<Scalars['String']>,
};

export type UpdateCompanyAttributes = {
  id: Scalars['Int'],
  name: Scalars['String'],
  about?: Maybe<Scalars['String']>,
  registrationnumber: Scalars['String'],
  vatnumber: Scalars['String'],
  address?: Maybe<AddressAttributes>,
};

export type UpdateCompanyDetailsPayload = {
   __typename?: 'UpdateCompanyDetailsPayload',
  company?: Maybe<Company>,
};

export type UpdateCompanyTradeRegionsPayload = {
   __typename?: 'UpdateCompanyTradeRegionsPayload',
  company?: Maybe<Company>,
};

export type UpdateFarmVarietyAttributes = {
  id: Scalars['Int'],
  farmid: Scalars['Int'],
  peakcount?: Maybe<Scalars['String']>,
  hectares?: Maybe<Scalars['Int']>,
  packingstart: Scalars['String'],
  packingend: Scalars['String'],
};

export type UpdateFarmVarietyPayload = {
   __typename?: 'UpdateFarmVarietyPayload',
  farmVariety?: Maybe<FarmVariety>,
};

export type UpdateLineItemAttributes = {
  id?: Maybe<Scalars['Int']>,
  varietyid: Scalars['Int'],
  commodityid: Scalars['Int'],
  quantity?: Maybe<Scalars['Int']>,
  quantityunit?: Maybe<Scalars['String']>,
  priceminorunit?: Maybe<Scalars['Int']>,
  priceunit?: Maybe<Scalars['String']>,
  currency: Scalars['String'],
  sizecounts: Array<Scalars['String']>,
  pack: Scalars['String'],
  priceterm: Scalars['String'],
  additionalfields: Scalars['String'],
  palletstack: Scalars['Int'],
  unlimited: Scalars['Boolean'],
  targetmarket?: Maybe<Scalars['String']>,
  targetregion: Scalars['String'],
  targetcountry?: Maybe<Scalars['String']>,
  packingweek?: Maybe<Scalars['String']>,
  startweek?: Maybe<Scalars['String']>,
  endweek?: Maybe<Scalars['String']>,
  incoterm: Scalars['String'],
  grade?: Maybe<Scalars['String']>,
  deleted?: Maybe<Scalars['Boolean']>,
  mark?: Maybe<Scalars['String']>,
  referencenumber?: Maybe<Scalars['String']>,
  rank?: Maybe<Scalars['Int']>,
  batchnumber?: Maybe<Scalars['String']>,
  inventorycode?: Maybe<Scalars['String']>,
};

export type UpdateLineItemGroupingAttributes = {
  id?: Maybe<Scalars['Int']>,
  cumulativequantity?: Maybe<Scalars['Int']>,
  quantitytype?: Maybe<Scalars['String']>,
  commonfields: Array<Scalars['String']>,
  lineitems: Array<UpdateLineItemAttributes>,
  deleted?: Maybe<Scalars['Boolean']>,
  rank?: Maybe<Scalars['Int']>,
};

export type UpdateOrderAttributes = {
  id: Scalars['Int'],
  ispackhouserun: Scalars['Boolean'],
  commonfields?: Maybe<Array<Scalars['String']>>,
  lineitemgroupings?: Maybe<Array<UpdateLineItemGroupingAttributes>>,
};

export type UpdateOrderPayload = {
   __typename?: 'UpdateOrderPayload',
  errors?: Maybe<Scalars['String']>,
  order?: Maybe<Order>,
};

export type UpdateShipmentDocumentPayload = {
   __typename?: 'UpdateShipmentDocumentPayload',
  shipmentDocument?: Maybe<ShipmentDocument>,
};

export type UpdateUserFeedItemAttributes = {
  id: Scalars['Int'],
  read?: Maybe<Scalars['Boolean']>,
  seen?: Maybe<Scalars['Boolean']>,
};

export type UpdateUserFeedItemPayload = {
   __typename?: 'UpdateUserFeedItemPayload',
  userFeedItem?: Maybe<UserFeedItem>,
};

export type UpdateUserFeedItemsPayload = {
   __typename?: 'UpdateUserFeedItemsPayload',
  userFeedItems?: Maybe<Array<UserFeedItem>>,
};

export type UpdateUserPayload = {
   __typename?: 'UpdateUserPayload',
  user?: Maybe<User>,
};


export type UploadCompanyCertificatePayload = {
   __typename?: 'UploadCompanyCertificatePayload',
  certificate?: Maybe<CompanyCertificate>,
};

export type UploadCompanyLogoPayload = {
   __typename?: 'UploadCompanyLogoPayload',
  company?: Maybe<Company>,
};

export type UploadFarmVarietyImagePayload = {
   __typename?: 'UploadFarmVarietyImagePayload',
  farmVariety?: Maybe<FarmVariety>,
};

export type UploadOrderShipmentDocumentPayload = {
   __typename?: 'UploadOrderShipmentDocumentPayload',
  order?: Maybe<Order>,
};

export type UploadUserAvatarPayload = {
   __typename?: 'UploadUserAvatarPayload',
  user?: Maybe<User>,
};

export type User = {
   __typename?: 'User',
  avatar?: Maybe<Scalars['String']>,
  avatarFile?: Maybe<Document>,
  company?: Maybe<Company>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  email?: Maybe<Scalars['String']>,
  employee?: Maybe<Employee>,
  employeeId?: Maybe<Scalars['Int']>,
  firstName?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  lastName?: Maybe<Scalars['String']>,
  notifyBrowserPush?: Maybe<Scalars['Boolean']>,
  notifyEmail?: Maybe<Scalars['Boolean']>,
  phoneNumber?: Maybe<Scalars['String']>,
  roles?: Maybe<Array<Role>>,
  state?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
  useV2?: Maybe<Scalars['Boolean']>,
};

export type UserFeedItem = {
   __typename?: 'UserFeedItem',
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  message?: Maybe<Scalars['String']>,
  notifiable?: Maybe<NotifiableUnion>,
  read?: Maybe<Scalars['Boolean']>,
  seen?: Maybe<Scalars['Boolean']>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type UserFeedItemConnection = {
   __typename?: 'UserFeedItemConnection',
  edges?: Maybe<Array<Maybe<UserFeedItemEdge>>>,
  nodes?: Maybe<Array<Maybe<UserFeedItem>>>,
  pageInfo: PageInfo,
};

export type UserFeedItemEdge = {
   __typename?: 'UserFeedItemEdge',
  cursor: Scalars['String'],
  node?: Maybe<UserFeedItem>,
};

export type Variety = {
   __typename?: 'Variety',
  commodity?: Maybe<Commodity>,
  createdAt?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  subCommodity?: Maybe<SubCommodity>,
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>,
};

export type Vessel = {
   __typename?: 'Vessel',
  destination?: Maybe<Scalars['String']>,
  eta?: Maybe<Scalars['String']>,
  finalDestination?: Maybe<Scalars['String']>,
  finalEta?: Maybe<Scalars['String']>,
  lat?: Maybe<Scalars['Float']>,
  locationType?: Maybe<Scalars['String']>,
  long?: Maybe<Scalars['Float']>,
  origin?: Maybe<Scalars['String']>,
  vesselName?: Maybe<Scalars['String']>,
  voyageNumber?: Maybe<Scalars['String']>,
};

export type Voyage = {
   __typename?: 'Voyage',
  arrivalTime?: Maybe<Scalars['ISO8601DateTime']>,
  createdAt: Scalars['ISO8601DateTime'],
  departureTime?: Maybe<Scalars['ISO8601DateTime']>,
  estimatedTimeOfArrival?: Maybe<Scalars['ISO8601DateTime']>,
  estimatedTimeOfDeparture?: Maybe<Scalars['ISO8601DateTime']>,
  id?: Maybe<Scalars['ID']>,
  locations?: Maybe<Array<Location>>,
  portOfDestination?: Maybe<SeaPort>,
  portOfOrigin?: Maybe<SeaPort>,
  updatedAt: Scalars['ISO8601DateTime'],
  vesselCallSign?: Maybe<Scalars['String']>,
  vesselImo?: Maybe<Scalars['String']>,
  vesselMmsi?: Maybe<Scalars['String']>,
  vesselName?: Maybe<Scalars['String']>,
  voyageNumber?: Maybe<Scalars['String']>,
};

export type WontPackLineItemsPayload = {
   __typename?: 'WontPackLineItemsPayload',
  orders?: Maybe<Array<Order>>,
};

export type CreateAdditionalFieldMutationVariables = {
  attributes: CreateAdditionalFieldAttributes
};


export type CreateAdditionalFieldMutation = (
  { __typename?: 'Mutation' }
  & { createAdditionalField: Maybe<(
    { __typename?: 'CreateAdditionalFieldPayload' }
    & { additionalField: Maybe<(
      { __typename?: 'AdditionalField' }
      & Pick<AdditionalField, 'id' | 'companyId' | 'name' | 'description' | 'fieldType' | 'parameters' | 'isPackingInstruction'>
    )> }
  )> }
);

export type DeleteAdditionalFieldMutationVariables = {
  id: Scalars['Int']
};


export type DeleteAdditionalFieldMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdditionalField: Maybe<(
    { __typename?: 'DeleteAdditionalFieldPayload' }
    & Pick<DeleteAdditionalFieldPayload, 'id'>
  )> }
);

export type UpdateAdditionalFieldMutationVariables = {
  id: Scalars['Int'],
  attributes: UpdateAdditionalFieldAttributes
};


export type UpdateAdditionalFieldMutation = (
  { __typename?: 'Mutation' }
  & { updateAdditionalField: Maybe<(
    { __typename?: 'UpdateAdditionalFieldPayload' }
    & { additionalField: Maybe<(
      { __typename?: 'AdditionalField' }
      & Pick<AdditionalField, 'id' | 'name' | 'description' | 'fieldType' | 'parameters' | 'isPackingInstruction'>
    )> }
  )> }
);

export type AdditionalFieldsQueryVariables = {
  companyids: Array<Scalars['Int']>
};


export type AdditionalFieldsQuery = (
  { __typename?: 'Query' }
  & { additionalFields: Maybe<Array<(
    { __typename?: 'AdditionalField' }
    & Pick<AdditionalField, 'id' | 'name' | 'description' | 'fieldType' | 'parameters' | 'isPackingInstruction'>
  )>> }
);

export type CartonCountsQueryVariables = {};


export type CartonCountsQuery = (
  { __typename?: 'Query' }
  & { cartonCounts: Maybe<Array<(
    { __typename?: 'CartonCount' }
    & Pick<CartonCount, 'id' | 'name' | 'commodityId' | 'counts' | 'priceMetric' | 'stackSizes'>
  )>> }
);

export type BaseCartonGroupingFieldsFragment = (
  { __typename?: 'CartonGrouping' }
  & Pick<CartonGrouping, 'id' | 'containerNumber' | 'state' | 'cartons' | 'palletNumber' | 'grade' | 'batchNumber' | 'sizeCount' | 'advancePrice' | 'finalPrice' | 'advanceDueDate' | 'finalDueDate'>
  & { lineItem: Maybe<(
    { __typename?: 'LineItem' }
    & Pick<LineItem, 'currency' | 'incoterm' | 'mark' | 'orderId' | 'priceMinorUnit' | 'priceUnit' | 'referenceNumber'>
  )>, buyer: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, seller: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )> }
);

export type CartonGroupingFragment = (
  { __typename?: 'CartonGrouping' }
  & Pick<CartonGrouping, 'targetRegion' | 'targetMarket' | 'targetCountry' | 'pack' | 'palletStack' | 'packhouseCode' | 'orchard' | 'containerNumber' | 'exporterCode' | 'farmCode' | 'packingWeek' | 'lineItemId' | 'consignmentNumber' | 'inventoryCode' | 'sequenceNumber' | 'coldStore' | 'productCharacteristic'>
  & { variety: Maybe<(
    { __typename?: 'Variety' }
    & Pick<Variety, 'id' | 'name'>
    & { commodity: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )> }
  )> }
  & BaseCartonGroupingFieldsFragment
);

export type CashFlowCartonGroupingFragment = (
  { __typename?: 'CartonGrouping' }
  & Pick<CartonGrouping, 'palletGrossMass'>
  & BaseCartonGroupingFieldsFragment
);

export type CartonGroupingsQueryVariables = {
  orderid?: Maybe<Scalars['Int']>,
  packingweek?: Maybe<Scalars['String']>,
  companyid?: Maybe<Scalars['Int']>,
  seasonids?: Maybe<Array<Scalars['Int']>>,
  limit?: Maybe<Scalars['Int']>
};


export type CartonGroupingsQuery = (
  { __typename?: 'Query' }
  & { cartonGroupings: Maybe<Array<(
    { __typename?: 'CartonGrouping' }
    & CartonGroupingFragment
  )>> }
);

export type CashFlowCartonGroupingsQueryVariables = {
  orderid?: Maybe<Scalars['Int']>,
  startduedate?: Maybe<Scalars['String']>,
  endduedate?: Maybe<Scalars['String']>,
  companyid?: Maybe<Scalars['Int']>,
  currency?: Maybe<Scalars['String']>
};


export type CashFlowCartonGroupingsQuery = (
  { __typename?: 'Query' }
  & { cartonGroupings: Maybe<Array<(
    { __typename?: 'CartonGrouping' }
    & CashFlowCartonGroupingFragment
  )>> }
);

export type CommentFieldsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'commentText' | 'isDeleted' | 'createdAt' | 'updatedAt'>
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { avatarFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )> }
  )> }
);

export type CreateCommentMutationVariables = {
  attributes: CreateCommentAttributes
};


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: Maybe<(
    { __typename?: 'CreateCommentPayload' }
    & { comment: Maybe<(
      { __typename?: 'Comment' }
      & CommentFieldsFragment
    )> }
  )> }
);

export type DeleteCommentMutationVariables = {
  commentid: Scalars['Int']
};


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment: Maybe<(
    { __typename?: 'DeleteCommentPayload' }
    & { comment: Maybe<(
      { __typename?: 'Comment' }
      & CommentFieldsFragment
    )> }
  )> }
);

export type CommercialTermChangeRequestFieldsFragment = (
  { __typename?: 'CommercialTermChangeRequest' }
  & Pick<CommercialTermChangeRequest, 'id' | 'message' | 'flag'>
  & { commercialTerm: Maybe<(
    { __typename?: 'CommercialTerm' }
    & Pick<CommercialTerm, 'id'>
  )> }
);

export type CreateCommercialTermChangeRequestMutationVariables = {
  attributes: CreateCommercialTermChangeRequestAttributes
};


export type CreateCommercialTermChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & { createCommercialTermChangeRequest: Maybe<(
    { __typename?: 'CreateCommercialTermChangeRequestPayload' }
    & { changeRequest: Maybe<(
      { __typename?: 'CommercialTermChangeRequest' }
      & Pick<CommercialTermChangeRequest, 'id'>
    )> }
  )> }
);

export type CommercialTermFragment = (
  { __typename?: 'CommercialTerm' }
  & Pick<CommercialTerm, 'id' | 'startDate' | 'endDate' | 'targetRegion' | 'advanceType' | 'advanceUnit' | 'buyerId' | 'deleted' | 'pack' | 'grade' | 'paymentTerm' | 'advanceCreditTerm' | 'finalCreditTerm' | 'advanceEvent' | 'finalEvent' | 'advanceAmount' | 'rankOneGroupEvent'>
  & { commodity: Maybe<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
  )>, buyer: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )> }
);

export type CommercialTermsQueryVariables = {
  startweek?: Maybe<Scalars['String']>,
  endweek?: Maybe<Scalars['String']>,
  companyid?: Maybe<Scalars['Int']>,
  targetregion?: Maybe<Scalars['String']>,
  advancetype?: Maybe<Scalars['String']>,
  advanceunit?: Maybe<Scalars['String']>,
  commodity?: Maybe<Scalars['String']>,
  advanceevent?: Maybe<Scalars['String']>
};


export type CommercialTermsQuery = (
  { __typename?: 'Query' }
  & { commercialTerms: Maybe<Array<(
    { __typename?: 'CommercialTerm' }
    & CommercialTermFragment
  )>> }
);

export type CommoditiesQueryVariables = {};


export type CommoditiesQuery = (
  { __typename?: 'Query' }
  & { commodities: Maybe<Array<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
    & { varieties: Maybe<Array<(
      { __typename?: 'Variety' }
      & Pick<Variety, 'id' | 'name'>
    )>> }
  )>> }
);

export type UploadCompanyCertificateMutationVariables = {
  file: Scalars['Upload'],
  expirydate: Scalars['String'],
  companyid: Scalars['Int'],
  certificatetypeid: Scalars['Int']
};


export type UploadCompanyCertificateMutation = (
  { __typename?: 'Mutation' }
  & { uploadCompanyCertificate: Maybe<(
    { __typename?: 'UploadCompanyCertificatePayload' }
    & { certificate: Maybe<(
      { __typename?: 'CompanyCertificate' }
      & Pick<CompanyCertificate, 'id' | 'expiryDate' | 'createdAt'>
      & { certificate: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url' | 'v2Url'>
      )>, certificateType: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'name' | 'id'>
      )> }
    )> }
  )> }
);

export type DeleteCompanyCertificateMutationVariables = {
  id: Scalars['Int']
};


export type DeleteCompanyCertificateMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanyCertificate: Maybe<(
    { __typename?: 'DeleteCompanyCertificatePayload' }
    & Pick<DeleteCompanyCertificatePayload, 'id'>
  )> }
);

export type CertificateTypeQueryVariables = {};


export type CertificateTypeQuery = (
  { __typename?: 'Query' }
  & { certificateTypes: Maybe<Array<(
    { __typename?: 'CertificateType' }
    & Pick<CertificateType, 'id' | 'name'>
  )>> }
);

export type CommoditySeasonFragment = (
  { __typename?: 'CommoditySeason' }
  & Pick<CommoditySeason, 'id' | 'startDate' | 'endDate' | 'createdAt' | 'updatedAt'>
  & { commodity: Maybe<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
  )> }
);

export type MyCompanyQueryVariables = {};


export type MyCompanyQuery = (
  { __typename?: 'Query' }
  & { myCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'about' | 'registrationNumber' | 'vatNumber' | 'businessType' | 'businessId' | 'createdAt'>
    & { logoFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )>, address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'postalCode' | 'country'>
    )>, tradeRegions: Maybe<Array<(
      { __typename?: 'TradeRegion' }
      & Pick<TradeRegion, 'id' | 'name' | 'code'>
      & { countries: Maybe<Array<(
        { __typename?: 'TradeCountry' }
        & Pick<TradeCountry, 'id' | 'name' | 'code'>
      )>> }
    )>>, certificates: Maybe<Array<(
      { __typename?: 'CompanyCertificate' }
      & Pick<CompanyCertificate, 'id' | 'expiryDate' | 'createdAt' | 'updatedAt'>
      & { certificate: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url' | 'v2Url'>
      )>, certificateType: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'name' | 'id'>
      )> }
    )>>, varieties: Maybe<Array<(
      { __typename?: 'FarmVariety' }
      & Pick<FarmVariety, 'id' | 'varietyId' | 'name' | 'peakCount' | 'hectares' | 'packingStart' | 'packingEnd'>
      & { commodity: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )>, images: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url' | 'v2Url' | 'filename' | 'thumbnailUrl' | 'v2ThumbnailUrl'>
      )>> }
    )>>, additionalFields: Maybe<Array<(
      { __typename?: 'AdditionalField' }
      & Pick<AdditionalField, 'id' | 'companyId' | 'name' | 'description' | 'fieldType' | 'parameters' | 'createdAt' | 'updatedAt' | 'isPackingInstruction'>
    )>>, activeUsers: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'state'>
      & { roles: Maybe<Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name' | 'resourceType' | 'resourceId' | 'createdAt' | 'updatedAt'>
      )>>, employee: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
      )> }
    )>>, invites: Maybe<Array<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'id' | 'email' | 'state'>
    )>>, commoditySeasons: Maybe<Array<(
      { __typename?: 'CommoditySeason' }
      & CommoditySeasonFragment
    )>> }
  )> }
);

export type UpdateCompanyDetailsMutationVariables = {
  attributes: UpdateCompanyAttributes
};


export type UpdateCompanyDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyDetails: Maybe<(
    { __typename?: 'UpdateCompanyDetailsPayload' }
    & { company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'about' | 'registrationNumber' | 'vatNumber'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'postalCode' | 'country'>
      )> }
    )> }
  )> }
);

export type UploadCompanyLogoMutationVariables = {
  file: Scalars['Upload'],
  companyid: Scalars['Int']
};


export type UploadCompanyLogoMutation = (
  { __typename?: 'Mutation' }
  & { uploadCompanyLogo: Maybe<(
    { __typename?: 'UploadCompanyLogoPayload' }
    & { company: Maybe<(
      { __typename?: 'Company' }
      & { logoFile: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url'>
      )> }
    )> }
  )> }
);

export type UpdateCompanyTradeRegionsMutationVariables = {
  addtraderegions: Array<TradeRegionAttributes>,
  removetraderegions: Array<TradeRegionAttributes>
};


export type UpdateCompanyTradeRegionsMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyTradeRegions: Maybe<(
    { __typename?: 'UpdateCompanyTradeRegionsPayload' }
    & { company: Maybe<(
      { __typename?: 'Company' }
      & { tradeRegions: Maybe<Array<(
        { __typename?: 'TradeRegion' }
        & Pick<TradeRegion, 'id' | 'name' | 'code'>
      )>> }
    )> }
  )> }
);

export type FarmVarietyFragment = (
  { __typename?: 'FarmVariety' }
  & Pick<FarmVariety, 'id' | 'varietyId' | 'name' | 'peakCount' | 'hectares' | 'packingStart' | 'packingEnd'>
  & { commodity: Maybe<(
    { __typename?: 'Commodity' }
    & Pick<Commodity, 'id' | 'name'>
  )>, images: Maybe<Array<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'url' | 'v2Url' | 'filename' | 'thumbnailUrl' | 'v2ThumbnailUrl'>
  )>> }
);

export type CreateFarmVarietyMutationVariables = {
  attributes: CreateFarmVarietyAttributes
};


export type CreateFarmVarietyMutation = (
  { __typename?: 'Mutation' }
  & { createFarmVariety: Maybe<(
    { __typename?: 'CreateFarmVarietyPayload' }
    & { farmVariety: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type UpdateFarmVarietyMutationVariables = {
  attributes: UpdateFarmVarietyAttributes
};


export type UpdateFarmVarietyMutation = (
  { __typename?: 'Mutation' }
  & { updateFarmVariety: Maybe<(
    { __typename?: 'UpdateFarmVarietyPayload' }
    & { farmVariety: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type DeleteFarmVarietyMutationVariables = {
  varietyid: Scalars['Int'],
  farmid: Scalars['Int']
};


export type DeleteFarmVarietyMutation = (
  { __typename?: 'Mutation' }
  & { deleteFarmVariety: Maybe<(
    { __typename?: 'DeleteFarmVarietyPayload' }
    & { farmVariety: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type UploadFarmVarietyImageMutationVariables = {
  id: Scalars['Int'],
  image: Scalars['Upload']
};


export type UploadFarmVarietyImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadFarmVarietyImage: Maybe<(
    { __typename?: 'UploadFarmVarietyImagePayload' }
    & { farmVariety: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type DeleteFarmVarietyImageMutationVariables = {
  varietyid: Scalars['Int'],
  imageid: Scalars['Int']
};


export type DeleteFarmVarietyImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteFarmVarietyImage: Maybe<(
    { __typename?: 'DeleteFarmVarietyImagePayload' }
    & { farmVariety: Maybe<(
      { __typename?: 'FarmVariety' }
      & FarmVarietyFragment
    )> }
  )> }
);

export type InviteUserMutationVariables = {
  email: Scalars['String']
};


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { inviteUser: Maybe<(
    { __typename?: 'InviteUserPayload' }
    & { invite: Maybe<(
      { __typename?: 'Invite' }
      & Pick<Invite, 'id' | 'email' | 'state' | 'message' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);

export type RevokeInviteMutationVariables = {
  id: Scalars['Int']
};


export type RevokeInviteMutation = (
  { __typename?: 'Mutation' }
  & { revokeInvite: Maybe<(
    { __typename?: 'RevokeInvitePayload' }
    & Pick<RevokeInvitePayload, 'id'>
  )> }
);

export type ResendInviteMutationVariables = {
  id: Scalars['Int']
};


export type ResendInviteMutation = (
  { __typename?: 'Mutation' }
  & { resendInvite: Maybe<(
    { __typename?: 'ResendInvitePayload' }
    & Pick<ResendInvitePayload, 'id'>
  )> }
);

export type VesselMapFragment = (
  { __typename?: 'Vessel' }
  & Pick<Vessel, 'vesselName' | 'voyageNumber' | 'lat' | 'long'>
);

export type SeaPortMapFragment = (
  { __typename?: 'SeaPort' }
  & Pick<SeaPort, 'id' | 'name' | 'country' | 'unLocode' | 'latitude' | 'longitude' | 'createdAt' | 'updatedAt'>
);

export type FboMapFragment = (
  { __typename?: 'FoodBusinessOperator' }
  & Pick<FoodBusinessOperator, 'id' | 'name' | 'fboCode' | 'typeOfBusiness' | 'latitude' | 'longitude' | 'createdAt' | 'updatedAt'>
);

export type CurrentMapPointsQueryVariables = {};


export type CurrentMapPointsQuery = (
  { __typename?: 'Query' }
  & { currentMapPoints: Maybe<Array<(
    { __typename?: 'MapPoint' }
    & Pick<MapPoint, 'totalCartons'>
    & { containers: Maybe<Array<(
      { __typename?: 'MapPointContainer' }
      & Pick<MapPointContainer, 'id' | 'containerNumber' | 'orderId' | 'otherPartyName' | 'vesselName' | 'portOfDestination' | 'eta'>
    )>>, orders: Maybe<Array<(
      { __typename?: 'MapPointOrder' }
      & Pick<MapPointOrder, 'orderId' | 'otherPartyName' | 'totalCartons'>
    )>>, location: Maybe<(
      { __typename: 'FoodBusinessOperator' }
      & FboMapFragment
    ) | (
      { __typename: 'SeaPort' }
      & SeaPortMapFragment
    ) | (
      { __typename: 'Vessel' }
      & VesselMapFragment
    )> }
  )>> }
);

export type CurrentMapPointsForOrderQueryVariables = {
  id: Scalars['Int']
};


export type CurrentMapPointsForOrderQuery = (
  { __typename?: 'Query' }
  & { currentMapPointsForOrder: Maybe<Array<(
    { __typename?: 'MapPoint' }
    & Pick<MapPoint, 'totalCartons'>
    & { containers: Maybe<Array<(
      { __typename?: 'MapPointContainer' }
      & Pick<MapPointContainer, 'id' | 'containerNumber' | 'orderId' | 'otherPartyName' | 'vesselName' | 'portOfDestination' | 'eta'>
    )>>, orders: Maybe<Array<(
      { __typename?: 'MapPointOrder' }
      & Pick<MapPointOrder, 'orderId' | 'otherPartyName' | 'totalCartons'>
    )>>, location: Maybe<(
      { __typename: 'FoodBusinessOperator' }
      & FboMapFragment
    ) | (
      { __typename: 'SeaPort' }
      & SeaPortMapFragment
    ) | (
      { __typename: 'Vessel' }
      & VesselMapFragment
    )> }
  )>> }
);

export type MetabaseReportsQueryVariables = {
  chart_type: Scalars['String']
};


export type MetabaseReportsQuery = (
  { __typename?: 'Query' }
  & { metabaseReports: Maybe<Array<(
    { __typename?: 'MetabaseReport' }
    & Pick<MetabaseReport, 'chartType' | 'id' | 'name' | 'url'>
  )>> }
);

export type OrderAlertFieldsFragment = (
  { __typename?: 'OrderAlert' }
  & Pick<OrderAlert, 'id' | 'orderId' | 'alertText' | 'importance' | 'eventText' | 'severity' | 'state'>
  & { additionalInformation: Maybe<(
    { __typename?: 'OrderAcceptance' }
    & Pick<OrderAcceptance, 'lineItemIds' | 'lineItemDates' | 'lineItemPackingWeeks'>
  ) | (
    { __typename?: 'OrderFulfillment' }
    & Pick<OrderFulfillment, 'lineItemIds' | 'lineItemPackedQuantity' | 'lineItemQuantities' | 'lineItemQuantityTypes' | 'lineItemPackingWeeks'>
  )>, affectedCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )> }
);

export type OrderAlertsQueryVariables = {};


export type OrderAlertsQuery = (
  { __typename?: 'Query' }
  & { orderAlerts: Maybe<Array<(
    { __typename?: 'OrderAlert' }
    & OrderAlertFieldsFragment
  )>> }
);

export type HideOrderAlertMutationVariables = {
  id: Scalars['Int']
};


export type HideOrderAlertMutation = (
  { __typename?: 'Mutation' }
  & { hideOrderAlert: Maybe<(
    { __typename?: 'HideOrderAlertPayload' }
    & { orderAlert: Maybe<(
      { __typename?: 'OrderAlert' }
      & Pick<OrderAlert, 'state'>
    )> }
  )> }
);

export type UnhideOrderAlertMutationVariables = {
  id: Scalars['Int']
};


export type UnhideOrderAlertMutation = (
  { __typename?: 'Mutation' }
  & { unhideOrderAlert: Maybe<(
    { __typename?: 'UnhideOrderAlertPayload' }
    & { orderAlert: Maybe<(
      { __typename?: 'OrderAlert' }
      & Pick<OrderAlert, 'state'>
    )> }
  )> }
);

export type ShipmentDocumentFieldsFragment = (
  { __typename?: 'ShipmentDocument' }
  & Pick<ShipmentDocument, 'id' | 'documentType'>
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    )> }
  )>, file: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'filename' | 'v2Url'>
  )> }
);

export type SingleLineItemFieldsFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'id' | 'additionalFields' | 'packingWeek' | 'startWeek' | 'endWeek' | 'targetRegion' | 'targetCountry' | 'targetMarket' | 'quantity' | 'incoterm' | 'pack' | 'palletStack' | 'quantityUnit' | 'priceMinorUnit' | 'sizeCounts' | 'priceTerm' | 'currency' | 'state' | 'priceUnit' | 'grade' | 'mark' | 'commodityId' | 'varietyId' | 'packedCartons' | 'packedPallets' | 'referenceNumber' | 'rank' | 'batchNumber' | 'inventoryCode'>
  & { variety: Maybe<(
    { __typename?: 'Variety' }
    & Pick<Variety, 'id'>
    & { commodity: Maybe<(
      { __typename?: 'Commodity' }
      & Pick<Commodity, 'id' | 'name'>
    )> }
  )> }
);

export type StatesDistributionFieldsFragment = (
  { __typename?: 'LineItem' }
  & { statesDistribution: Maybe<Array<(
    { __typename?: 'LineItemState' }
    & Pick<LineItemState, 'quantity' | 'quantityTotal' | 'palletQuantity' | 'palletQuantityTotal' | 'state' | 'supplyChainOrder'>
  )>> }
);

export type QuantityTypeQueryVariables = {};


export type QuantityTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'quantityType'>
);

export type SingleOrderFieldsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'state' | 'isPackhouseRun' | 'createdAt' | 'updatedAt'>
  & { comments: Maybe<Array<(
    { __typename?: 'Comment' }
    & CommentFieldsFragment
  )>>, buyer: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'v2Url'>
    )> }
  )>, seller: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'v2Url'>
    )>, varieties: Maybe<Array<(
      { __typename?: 'FarmVariety' }
      & Pick<FarmVariety, 'id' | 'varietyId' | 'name'>
      & { commodity: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )> }
    )>> }
  )>, lineItems: Maybe<Array<(
    { __typename?: 'LineItem' }
    & SingleLineItemFieldsFragment
  )>>, lineItemGroupings: Maybe<Array<(
    { __typename?: 'LineItemGrouping' }
    & Pick<LineItemGrouping, 'id' | 'cumulativeQuantity' | 'quantityType' | 'commonFields' | 'deleted' | 'rank'>
    & { lineItems: Maybe<Array<(
      { __typename?: 'LineItem' }
      & SingleLineItemFieldsFragment
      & StatesDistributionFieldsFragment
    )>> }
  )>>, audits: Maybe<Array<(
    { __typename?: 'Audit' }
    & Pick<Audit, 'id' | 'action' | 'auditableType' | 'auditableId' | 'associatedType' | 'associatedId' | 'createdAt' | 'auditedChanges' | 'version'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName'>
      & { company: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      )> }
    )> }
  )>>, shipmentDocuments: Maybe<Array<(
    { __typename?: 'ShipmentDocument' }
    & ShipmentDocumentFieldsFragment
  )>> }
);

export type MultipleOrderFieldsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'state' | 'isPackhouseRun' | 'updatedAt' | 'createdAt'>
  & { lineItemGroupings: Maybe<Array<(
    { __typename?: 'LineItemGrouping' }
    & Pick<LineItemGrouping, 'id' | 'cumulativeQuantity' | 'quantityType' | 'commonFields' | 'deleted' | 'rank'>
    & { lineItems: Maybe<Array<(
      { __typename?: 'LineItem' }
      & SingleLineItemFieldsFragment
      & StatesDistributionFieldsFragment
    )>> }
  )>>, buyer: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'v2Url'>
    )> }
  )>, seller: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'v2Url'>
    )>, varieties: Maybe<Array<(
      { __typename?: 'FarmVariety' }
      & Pick<FarmVariety, 'id' | 'varietyId' | 'name'>
      & { commodity: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type DuplicateOrderFieldsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'isPackhouseRun'>
  & { lineItemGroupings: Maybe<Array<(
    { __typename?: 'LineItemGrouping' }
    & Pick<LineItemGrouping, 'id' | 'cumulativeQuantity' | 'quantityType' | 'commonFields' | 'deleted' | 'rank'>
    & { lineItems: Maybe<Array<(
      { __typename?: 'LineItem' }
      & SingleLineItemFieldsFragment
    )>> }
  )>>, buyer: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'v2Url'>
    )> }
  )>, seller: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
    & { logoFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'v2Url'>
    )> }
  )> }
);

export type CreateOrderMutationVariables = {
  attributes: CreateOrderAttributes,
  lineitemstates?: Maybe<Array<Scalars['String']>>,
  packingweekstart?: Maybe<Scalars['String']>,
  packingweekend?: Maybe<Scalars['String']>
};


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: Maybe<(
    { __typename?: 'CreateOrderPayload' }
    & Pick<CreateOrderPayload, 'errors'>
    & { order: Maybe<(
      { __typename?: 'Order' }
      & MultipleOrderFieldsFragment
    )> }
  )> }
);

export type OrdersSearchQueryVariables = {
  limit: Scalars['Int'],
  skip: Scalars['Int'],
  filter: OrderFilter,
  lineitemstates?: Maybe<Array<Scalars['String']>>,
  packingweekstart?: Maybe<Scalars['String']>,
  packingweekend?: Maybe<Scalars['String']>
};


export type OrdersSearchQuery = (
  { __typename?: 'Query' }
  & { ordersSearch: Maybe<(
    { __typename?: 'OrderSearch' }
    & Pick<OrderSearch, 'totalOrders' | 'totalFiltered'>
    & { orders: Maybe<Array<(
      { __typename?: 'Order' }
      & MultipleOrderFieldsFragment
    )>> }
  )> }
);

export type OrderQueryVariables = {
  id: Scalars['ID']
};


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order: Maybe<(
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  )> }
);

export type DuplicateOrderQueryVariables = {
  id: Scalars['ID']
};


export type DuplicateOrderQuery = (
  { __typename?: 'Query' }
  & { order: Maybe<(
    { __typename?: 'Order' }
    & DuplicateOrderFieldsFragment
  )> }
);

export type UpdateOrderMutationVariables = {
  attributes: UpdateOrderAttributes
};


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: Maybe<(
    { __typename?: 'UpdateOrderPayload' }
    & { order: Maybe<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )> }
  )> }
);

export type AcceptLineItemsMutationVariables = {
  ids: Array<Scalars['Int']>
};


export type AcceptLineItemsMutation = (
  { __typename?: 'Mutation' }
  & { acceptLineItems: Maybe<(
    { __typename?: 'AcceptLineItemsPayload' }
    & { orders: Maybe<Array<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )>> }
  )> }
);

export type WontPackLineItemsMutationVariables = {
  ids: Array<Scalars['Int']>
};


export type WontPackLineItemsMutation = (
  { __typename?: 'Mutation' }
  & { wontPackLineItems: Maybe<(
    { __typename?: 'WontPackLineItemsPayload' }
    & { orders: Maybe<Array<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )>> }
  )> }
);

export type RevertLineItemsToCreatedMutationVariables = {
  ids: Array<Scalars['Int']>
};


export type RevertLineItemsToCreatedMutation = (
  { __typename?: 'Mutation' }
  & { revertLineItemsToCreated: Maybe<(
    { __typename?: 'RevertLineItemsToCreatedPayload' }
    & { orders: Maybe<Array<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )>> }
  )> }
);

export type RevertOrderChangeMutationVariables = {
  startid: Scalars['Int'],
  endid: Scalars['Int']
};


export type RevertOrderChangeMutation = (
  { __typename?: 'Mutation' }
  & { revertOrderChange: Maybe<(
    { __typename?: 'RevertOrderChangePayload' }
    & { order: Maybe<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )> }
  )> }
);

export type UploadOrderShipmentDocumentMutationVariables = {
  orderid: Scalars['Int'],
  type: Scalars['String'],
  file: Scalars['Upload']
};


export type UploadOrderShipmentDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadOrderShipmentDocument: Maybe<(
    { __typename?: 'UploadOrderShipmentDocumentPayload' }
    & { order: Maybe<(
      { __typename?: 'Order' }
      & { shipmentDocuments: Maybe<Array<(
        { __typename?: 'ShipmentDocument' }
        & ShipmentDocumentFieldsFragment
      )>> }
    )> }
  )> }
);

export type GenerateDemoDataMutationVariables = {
  id: Scalars['Int']
};


export type GenerateDemoDataMutation = (
  { __typename?: 'Mutation' }
  & { generateDemoData: Maybe<(
    { __typename?: 'GenerateDemoDataPayload' }
    & { order: Maybe<(
      { __typename?: 'Order' }
      & SingleOrderFieldsFragment
    )> }
  )> }
);

export type NewOrderSubscriptionVariables = {};


export type NewOrderSubscription = (
  { __typename?: 'Subscription' }
  & { newOrder: (
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  ) }
);

export type OnAcceptLineItemsSubscriptionVariables = {};


export type OnAcceptLineItemsSubscription = (
  { __typename?: 'Subscription' }
  & { onAcceptLineItems: (
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  ) }
);

export type OnWontPackLineItemsSubscriptionVariables = {};


export type OnWontPackLineItemsSubscription = (
  { __typename?: 'Subscription' }
  & { onWontPackLineItems: (
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  ) }
);

export type OnRevertLineItemsToCreatedSubscriptionVariables = {};


export type OnRevertLineItemsToCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { onRevertLineItemsToCreated: (
    { __typename?: 'Order' }
    & SingleOrderFieldsFragment
  ) }
);

export type PackInstructionsFieldsFragment = (
  { __typename?: 'PackInstructionsRow' }
  & Pick<PackInstructionsRow, 'state' | 'quantityRequested' | 'quantitiesPacked' | 'quantityType'>
  & { lineItems: Maybe<Array<(
    { __typename?: 'PackInstructionsLineItemRow' }
    & PackInstructionsLineItemFieldsFragment
  )>> }
);

export type PackInstructionsLineItemFieldsFragment = (
  { __typename?: 'PackInstructionsLineItemRow' }
  & Pick<PackInstructionsLineItemRow, 'priority' | 'state' | 'packingWeek' | 'buyerName' | 'targetRegion' | 'targetMarket' | 'varietyName' | 'sizeCounts' | 'grade' | 'mark' | 'pack' | 'palletStack' | 'batchNumber' | 'inventoryCode' | 'additionalFields' | 'quantityRequested' | 'quantitiesPacked' | 'quantityType' | 'orderId' | 'lineItemId'>
);

export type PackInstructionsQueryVariables = {};


export type PackInstructionsQuery = (
  { __typename?: 'Query' }
  & { packinstructions: Maybe<Array<(
    { __typename?: 'PackInstructionsRow' }
    & PackInstructionsFieldsFragment
  )>> }
);

export type TogglePackInstructionsRowPriorityMutationVariables = {
  lineitemids: Array<Scalars['Int']>
};


export type TogglePackInstructionsRowPriorityMutation = (
  { __typename?: 'Mutation' }
  & { togglePackInstructionsRowPriority: Maybe<(
    { __typename?: 'TogglePackInstructionsRowPriorityPayload' }
    & { packInstructionsRows: Maybe<Array<(
      { __typename?: 'PackInstructionsLineItemRow' }
      & PackInstructionsLineItemFieldsFragment
    )>> }
  )> }
);

export type GetPricingHelpMutationVariables = {
  attributes: PricingHelperAttributes
};


export type GetPricingHelpMutation = (
  { __typename?: 'Mutation' }
  & { getPricingHelp: Maybe<(
    { __typename?: 'GetPricingHelpPayload' }
    & Pick<GetPricingHelpPayload, 'fotPriceMinorUnit' | 'cifPriceMinorUnit' | 'fobPriceMinorUnit' | 'dipPriceMinorUnit' | 'exwPriceMinorUnit' | 'currency'>
  )> }
);

export type SettingsQueryVariables = {};


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { myUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'state' | 'notifyEmail' | 'notifyBrowserPush' | 'createdAt' | 'updatedAt' | 'useV2' | 'avatar'>
    & { avatarFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )>, company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, roles: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'name'>
    )>> }
  )> }
);

export type UpdateUserMutationVariables = {
  id: Scalars['Int'],
  firstname: Scalars['String'],
  lastname: Scalars['String'],
  phonenumber: Scalars['String'],
  notifyemail: Scalars['Boolean'],
  notifybrowserpush: Scalars['Boolean'],
  usev2: Scalars['Boolean']
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'state' | 'notifyEmail' | 'notifyBrowserPush' | 'useV2'>
    )> }
  )> }
);

export type UploadUserAvatarMutationVariables = {
  file: Scalars['Upload']
};


export type UploadUserAvatarMutation = (
  { __typename?: 'Mutation' }
  & { uploadUserAvatar: Maybe<(
    { __typename?: 'UploadUserAvatarPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & { avatarFile: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url'>
      )> }
    )> }
  )> }
);

export type UpdateShipmentDocumentMutationVariables = {
  id: Scalars['Int'],
  type: Scalars['String']
};


export type UpdateShipmentDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateShipmentDocument: Maybe<(
    { __typename?: 'UpdateShipmentDocumentPayload' }
    & { shipmentDocument: Maybe<(
      { __typename?: 'ShipmentDocument' }
      & Pick<ShipmentDocument, 'id' | 'documentType' | 'state'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
        & { company: Maybe<(
          { __typename?: 'Company' }
          & Pick<Company, 'id'>
        )> }
      )>, file: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'v2Url'>
      )> }
    )> }
  )> }
);

export type DeleteShipmentDocumentMutationVariables = {
  id: Scalars['Int']
};


export type DeleteShipmentDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteShipmentDocument: Maybe<(
    { __typename?: 'DeleteShipmentDocumentPayload' }
    & Pick<DeleteShipmentDocumentPayload, 'id'>
  )> }
);

export type TicketFieldsFragment = (
  { __typename?: 'Ticket' }
  & Pick<Ticket, 'id' | 'subject' | 'description' | 'status' | 'createdAt'>
);

export type TicketsQueryVariables = {
  email: Scalars['String']
};


export type TicketsQuery = (
  { __typename?: 'Query' }
  & { tickets: Maybe<Array<(
    { __typename?: 'Ticket' }
    & TicketFieldsFragment
  )>> }
);

export type CreateTicketMutationVariables = {
  attributes: CreateTicketAttributes
};


export type CreateTicketMutation = (
  { __typename?: 'Mutation' }
  & { createTicket: Maybe<(
    { __typename?: 'CreateTicketPayload' }
    & { ticket: Maybe<(
      { __typename?: 'Ticket' }
      & TicketFieldsFragment
    )> }
  )> }
);

export type TradePartnerQueryVariables = {
  id: Scalars['ID']
};


export type TradePartnerQuery = (
  { __typename?: 'Query' }
  & { company: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'about' | 'businessType' | 'registrationNumber' | 'vatNumber' | 'createdAt'>
    & { logoFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )>, address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'postalCode' | 'country'>
    )>, varieties: Maybe<Array<(
      { __typename?: 'FarmVariety' }
      & Pick<FarmVariety, 'id' | 'varietyId' | 'name' | 'packingStart' | 'packingEnd' | 'peakCount' | 'hectares'>
      & { images: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'url' | 'thumbnailUrl'>
      )>>, commodity: Maybe<(
        { __typename?: 'Commodity' }
        & Pick<Commodity, 'id' | 'name'>
      )> }
    )>>, certificates: Maybe<Array<(
      { __typename?: 'CompanyCertificate' }
      & Pick<CompanyCertificate, 'id' | 'companyId' | 'expiryDate'>
      & { certificate: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'url' | 'filename'>
      )>, certificateType: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'name' | 'id'>
      )> }
    )>>, tradeRegions: Maybe<Array<(
      { __typename?: 'TradeRegion' }
      & Pick<TradeRegion, 'name' | 'code'>
    )>> }
  )> }
);

export type TradePartnersQueryVariables = {};


export type TradePartnersQuery = (
  { __typename?: 'Query' }
  & { myCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { companies: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
      & { logoFile: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url'>
      )>, varieties: Maybe<Array<(
        { __typename?: 'FarmVariety' }
        & Pick<FarmVariety, 'id' | 'varietyId' | 'name'>
        & { commodity: Maybe<(
          { __typename?: 'Commodity' }
          & Pick<Commodity, 'id' | 'name'>
        )> }
      )>>, certificates: Maybe<Array<(
        { __typename?: 'CompanyCertificate' }
        & { certificateType: Maybe<(
          { __typename?: 'CertificateType' }
          & Pick<CertificateType, 'name'>
        )> }
      )>>, tradeRegions: Maybe<Array<(
        { __typename?: 'TradeRegion' }
        & Pick<TradeRegion, 'name' | 'code'>
      )>> }
    )>> }
  )> }
);

export type AllTradeRegionsQueryVariables = {};


export type AllTradeRegionsQuery = (
  { __typename?: 'Query' }
  & { tradeRegions: Maybe<Array<(
    { __typename?: 'TradeRegion' }
    & Pick<TradeRegion, 'id' | 'name' | 'code'>
    & { countries: Maybe<Array<(
      { __typename?: 'TradeCountry' }
      & Pick<TradeCountry, 'id' | 'code' | 'name'>
    )>> }
  )>> }
);

export type UserFeedItemFragment = (
  { __typename?: 'UserFeedItem' }
  & Pick<UserFeedItem, 'id' | 'message' | 'read' | 'seen' | 'createdAt'>
  & { notifiable: Maybe<(
    { __typename: 'Comment' }
    & Pick<Comment, 'id'>
    & { commentable: Maybe<(
      { __typename: 'Order' }
      & Pick<Order, 'id'>
    )> }
  )> }
);

export type UserFeedItemsConnectionQueryVariables = {
  last?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>
};


export type UserFeedItemsConnectionQuery = (
  { __typename?: 'Query' }
  & { userFeedConnection: (
    { __typename?: 'UserFeedItemConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'UserFeedItemEdge' }
      & Pick<UserFeedItemEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'UserFeedItem' }
        & UserFeedItemFragment
      )> }
    )>>> }
  ) }
);

export type UserFeedUnseenCountQueryVariables = {};


export type UserFeedUnseenCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userFeedUnseenCount'>
);

export type UpdateUserFeedItemMutationVariables = {
  attributes: UpdateUserFeedItemAttributes
};


export type UpdateUserFeedItemMutation = (
  { __typename?: 'Mutation' }
  & { updateUserFeedItem: Maybe<(
    { __typename?: 'UpdateUserFeedItemPayload' }
    & { userFeedItem: Maybe<(
      { __typename?: 'UserFeedItem' }
      & Pick<UserFeedItem, 'id' | 'seen' | 'read'>
    )> }
  )> }
);

export type UpdateUserFeedItemsMutationVariables = {
  attributes: Array<UpdateUserFeedItemAttributes>
};


export type UpdateUserFeedItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserFeedItems: Maybe<(
    { __typename?: 'UpdateUserFeedItemsPayload' }
    & { userFeedItems: Maybe<Array<(
      { __typename?: 'UserFeedItem' }
      & Pick<UserFeedItem, 'id' | 'seen' | 'read'>
    )>> }
  )> }
);

export type NewUserFeedItemSubscriptionVariables = {};


export type NewUserFeedItemSubscription = (
  { __typename?: 'Subscription' }
  & { newUserFeedItem: (
    { __typename?: 'UserFeedItem' }
    & Pick<UserFeedItem, 'id'>
  ) }
);

export type UserQueryVariables = {};


export type UserQuery = (
  { __typename?: 'Query' }
  & { myUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'state' | 'notifyEmail' | 'notifyBrowserPush' | 'createdAt' | 'updatedAt' | 'useV2'>
    & { avatarFile: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'filename' | 'url'>
    )>, company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, roles: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'name'>
    )>> }
  )>, myCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'businessType'>
    & { certificates: Maybe<Array<(
      { __typename?: 'CompanyCertificate' }
      & Pick<CompanyCertificate, 'id' | 'expiryDate' | 'createdAt' | 'updatedAt'>
      & { certificate: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'filename' | 'url'>
      )>, certificateType: Maybe<(
        { __typename?: 'CertificateType' }
        & Pick<CertificateType, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type DeleteEmployeeMutationVariables = {
  employeeid: Scalars['Int']
};


export type DeleteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { deleteEmployee: Maybe<(
    { __typename?: 'DeleteEmployeePayload' }
    & Pick<DeleteEmployeePayload, 'employeeId'>
  )> }
);

export const BaseCartonGroupingFieldsFragmentDoc = gql`
    fragment baseCartonGroupingFields on CartonGrouping {
  id
  containerNumber
  state
  cartons
  palletNumber
  grade
  batchNumber
  sizeCount
  advancePrice
  finalPrice
  advanceDueDate
  finalDueDate
  lineItem {
    currency
    incoterm
    mark
    orderId
    priceMinorUnit
    priceUnit
    referenceNumber
  }
  buyer {
    id
    name
  }
  seller {
    id
    name
  }
}
    `;
export const CartonGroupingFragmentDoc = gql`
    fragment cartonGrouping on CartonGrouping {
  ...baseCartonGroupingFields
  targetRegion
  targetMarket
  targetCountry
  pack
  palletStack
  packhouseCode
  orchard
  containerNumber
  exporterCode
  farmCode
  packingWeek
  lineItemId
  consignmentNumber
  inventoryCode
  sequenceNumber
  coldStore
  productCharacteristic
  variety {
    id
    name
    commodity {
      id
      name
    }
  }
}
    ${BaseCartonGroupingFieldsFragmentDoc}`;
export const CashFlowCartonGroupingFragmentDoc = gql`
    fragment cashFlowCartonGrouping on CartonGrouping {
  ...baseCartonGroupingFields
  palletGrossMass
}
    ${BaseCartonGroupingFieldsFragmentDoc}`;
export const CommercialTermChangeRequestFieldsFragmentDoc = gql`
    fragment CommercialTermChangeRequestFields on CommercialTermChangeRequest {
  id
  message
  flag
  commercialTerm {
    id
  }
}
    `;
export const CommercialTermFragmentDoc = gql`
    fragment commercialTerm on CommercialTerm {
  id
  startDate
  endDate
  targetRegion
  advanceType
  advanceUnit
  buyerId
  deleted
  pack
  grade
  paymentTerm
  advanceCreditTerm
  finalCreditTerm
  advanceEvent
  finalEvent
  advanceType
  advanceAmount
  advanceUnit
  rankOneGroupEvent
  commodity {
    id
    name
  }
  buyer {
    id
    name
  }
}
    `;
export const CommoditySeasonFragmentDoc = gql`
    fragment commoditySeason on CommoditySeason {
  id
  startDate
  endDate
  commodity {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const FarmVarietyFragmentDoc = gql`
    fragment farmVariety on FarmVariety {
  id
  varietyId
  name
  peakCount
  hectares
  packingStart
  packingEnd
  commodity {
    id
    name
  }
  images {
    id
    url
    v2Url
    filename
    thumbnailUrl
    v2ThumbnailUrl
  }
}
    `;
export const VesselMapFragmentDoc = gql`
    fragment vesselMap on Vessel {
  vesselName
  voyageNumber
  lat
  long
}
    `;
export const SeaPortMapFragmentDoc = gql`
    fragment seaPortMap on SeaPort {
  id
  name
  country
  unLocode
  latitude
  longitude
  createdAt
  updatedAt
}
    `;
export const FboMapFragmentDoc = gql`
    fragment fboMap on FoodBusinessOperator {
  id
  name
  fboCode
  typeOfBusiness
  latitude
  longitude
  createdAt
  updatedAt
}
    `;
export const OrderAlertFieldsFragmentDoc = gql`
    fragment orderAlertFields on OrderAlert {
  additionalInformation {
    ... on OrderAcceptance {
      lineItemIds
      lineItemDates
      lineItemPackingWeeks
    }
    ... on OrderFulfillment {
      lineItemIds
      lineItemPackedQuantity
      lineItemQuantities
      lineItemQuantityTypes
      lineItemPackingWeeks
    }
  }
  affectedCompany {
    name
  }
  id
  orderId
  alertText
  importance
  eventText
  severity
  state
}
    `;
export const CommentFieldsFragmentDoc = gql`
    fragment commentFields on Comment {
  id
  commentText
  isDeleted
  createdAt
  updatedAt
  user {
    id
    firstName
    lastName
    avatarFile {
      id
      filename
      url
    }
  }
}
    `;
export const SingleLineItemFieldsFragmentDoc = gql`
    fragment singleLineItemFields on LineItem {
  id
  additionalFields
  packingWeek
  startWeek
  endWeek
  targetRegion
  targetCountry
  targetMarket
  quantity
  incoterm
  pack
  palletStack
  quantityUnit
  priceMinorUnit
  sizeCounts
  priceTerm
  currency
  state
  priceUnit
  grade
  mark
  commodityId
  varietyId
  packedCartons
  packedPallets
  variety {
    id
    commodity {
      id
      name
    }
  }
  referenceNumber
  rank
  batchNumber
  inventoryCode
}
    `;
export const StatesDistributionFieldsFragmentDoc = gql`
    fragment statesDistributionFields on LineItem {
  statesDistribution {
    quantity
    quantityTotal
    palletQuantity
    palletQuantityTotal
    state
    supplyChainOrder
  }
}
    `;
export const ShipmentDocumentFieldsFragmentDoc = gql`
    fragment shipmentDocumentFields on ShipmentDocument {
  id
  documentType
  user {
    id
    firstName
    lastName
    company {
      id
    }
  }
  file {
    id
    filename
    v2Url
  }
}
    `;
export const SingleOrderFieldsFragmentDoc = gql`
    fragment singleOrderFields on Order {
  id
  state
  isPackhouseRun
  createdAt
  updatedAt
  comments {
    ...commentFields
  }
  buyer {
    id
    name
    logoFile {
      id
      v2Url
    }
  }
  seller {
    id
    name
    logoFile {
      id
      v2Url
    }
    varieties {
      id
      varietyId
      name
      commodity {
        id
        name
      }
    }
  }
  lineItems {
    ...singleLineItemFields
  }
  lineItemGroupings {
    id
    cumulativeQuantity
    quantityType
    commonFields
    deleted
    rank
    lineItems {
      ...singleLineItemFields
      ...statesDistributionFields
    }
  }
  audits {
    id
    action
    auditableType
    auditableId
    associatedType
    associatedId
    createdAt
    auditedChanges
    version
    user {
      id
      firstName
      company {
        id
        name
      }
    }
  }
  shipmentDocuments {
    ...shipmentDocumentFields
  }
}
    ${CommentFieldsFragmentDoc}
${SingleLineItemFieldsFragmentDoc}
${StatesDistributionFieldsFragmentDoc}
${ShipmentDocumentFieldsFragmentDoc}`;
export const MultipleOrderFieldsFragmentDoc = gql`
    fragment multipleOrderFields on Order {
  id
  state
  isPackhouseRun
  updatedAt
  createdAt
  lineItemGroupings {
    id
    cumulativeQuantity
    quantityType
    commonFields
    deleted
    rank
    lineItems(lineItemStates: $lineitemstates, packingWeekStart: $packingweekstart, packingWeekEnd: $packingweekend) {
      ...singleLineItemFields
      ...statesDistributionFields
    }
  }
  buyer {
    id
    name
    logoFile {
      id
      filename
      v2Url
    }
  }
  seller {
    id
    name
    logoFile {
      id
      filename
      v2Url
    }
    varieties {
      id
      varietyId
      name
      commodity {
        id
        name
      }
    }
  }
}
    ${SingleLineItemFieldsFragmentDoc}
${StatesDistributionFieldsFragmentDoc}`;
export const DuplicateOrderFieldsFragmentDoc = gql`
    fragment duplicateOrderFields on Order {
  id
  isPackhouseRun
  lineItemGroupings {
    id
    cumulativeQuantity
    quantityType
    commonFields
    deleted
    rank
    lineItems {
      ...singleLineItemFields
    }
  }
  buyer {
    id
    name
    logoFile {
      id
      filename
      v2Url
    }
  }
  seller {
    id
    name
    logoFile {
      id
      filename
      v2Url
    }
  }
}
    ${SingleLineItemFieldsFragmentDoc}`;
export const PackInstructionsLineItemFieldsFragmentDoc = gql`
    fragment packInstructionsLineItemFields on PackInstructionsLineItemRow {
  priority
  state
  packingWeek
  buyerName
  targetRegion
  targetMarket
  varietyName
  sizeCounts
  grade
  mark
  pack
  palletStack
  batchNumber
  inventoryCode
  additionalFields
  quantityRequested
  quantitiesPacked
  quantityType
  orderId
  lineItemId
}
    `;
export const PackInstructionsFieldsFragmentDoc = gql`
    fragment packInstructionsFields on PackInstructionsRow {
  state
  quantityRequested
  quantitiesPacked
  quantityType
  lineItems {
    ...packInstructionsLineItemFields
  }
}
    ${PackInstructionsLineItemFieldsFragmentDoc}`;
export const TicketFieldsFragmentDoc = gql`
    fragment ticketFields on Ticket {
  id
  subject
  description
  status
  subject
  createdAt
}
    `;
export const UserFeedItemFragmentDoc = gql`
    fragment userFeedItem on UserFeedItem {
  id
  message
  read
  seen
  createdAt
  notifiable {
    ... on Comment {
      id
      __typename
      commentable {
        ... on Order {
          id
          __typename
        }
      }
    }
  }
}
    `;
export const CreateAdditionalFieldDocument = gql`
    mutation CreateAdditionalField($attributes: CreateAdditionalFieldAttributes!) {
  createAdditionalField(attributes: $attributes) {
    additionalField {
      id
      companyId
      name
      description
      fieldType
      parameters
      isPackingInstruction
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAdditionalFieldGQL extends Apollo.Mutation<CreateAdditionalFieldMutation, CreateAdditionalFieldMutationVariables> {
    document = CreateAdditionalFieldDocument;

  }
export const DeleteAdditionalFieldDocument = gql`
    mutation DeleteAdditionalField($id: Int!) {
  deleteAdditionalField(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdditionalFieldGQL extends Apollo.Mutation<DeleteAdditionalFieldMutation, DeleteAdditionalFieldMutationVariables> {
    document = DeleteAdditionalFieldDocument;

  }
export const UpdateAdditionalFieldDocument = gql`
    mutation UpdateAdditionalField($id: Int!, $attributes: UpdateAdditionalFieldAttributes!) {
  updateAdditionalField(id: $id, attributes: $attributes) {
    additionalField {
      id
      name
      description
      fieldType
      parameters
      isPackingInstruction
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAdditionalFieldGQL extends Apollo.Mutation<UpdateAdditionalFieldMutation, UpdateAdditionalFieldMutationVariables> {
    document = UpdateAdditionalFieldDocument;

  }
export const AdditionalFieldsDocument = gql`
    query AdditionalFields($companyids: [Int!]!) {
  additionalFields(companyIds: $companyids) {
    id
    name
    description
    fieldType
    parameters
    isPackingInstruction
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdditionalFieldsGQL extends Apollo.Query<AdditionalFieldsQuery, AdditionalFieldsQueryVariables> {
    document = AdditionalFieldsDocument;

  }
export const CartonCountsDocument = gql`
    query CartonCounts {
  cartonCounts {
    id
    name
    commodityId
    counts
    priceMetric
    stackSizes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartonCountsGQL extends Apollo.Query<CartonCountsQuery, CartonCountsQueryVariables> {
    document = CartonCountsDocument;

  }
export const CartonGroupingsDocument = gql`
    query CartonGroupings($orderid: Int, $packingweek: String, $companyid: Int, $seasonids: [Int!], $limit: Int) {
  cartonGroupings(orderid: $orderid, packingweek: $packingweek, companyid: $companyid, seasonids: $seasonids, limit: $limit) {
    ...cartonGrouping
  }
}
    ${CartonGroupingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartonGroupingsGQL extends Apollo.Query<CartonGroupingsQuery, CartonGroupingsQueryVariables> {
    document = CartonGroupingsDocument;

  }
export const CashFlowCartonGroupingsDocument = gql`
    query CashFlowCartonGroupings($orderid: Int, $startduedate: String, $endduedate: String, $companyid: Int, $currency: String) {
  cartonGroupings(orderid: $orderid, startduedate: $startduedate, endduedate: $endduedate, companyid: $companyid, currency: $currency) {
    ...cashFlowCartonGrouping
  }
}
    ${CashFlowCartonGroupingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CashFlowCartonGroupingsGQL extends Apollo.Query<CashFlowCartonGroupingsQuery, CashFlowCartonGroupingsQueryVariables> {
    document = CashFlowCartonGroupingsDocument;

  }
export const CreateCommentDocument = gql`
    mutation CreateComment($attributes: CreateCommentAttributes!) {
  createComment(attributes: $attributes) {
    comment {
      ...commentFields
    }
  }
}
    ${CommentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCommentGQL extends Apollo.Mutation<CreateCommentMutation, CreateCommentMutationVariables> {
    document = CreateCommentDocument;

  }
export const DeleteCommentDocument = gql`
    mutation DeleteComment($commentid: Int!) {
  deleteComment(commentId: $commentid) {
    comment {
      ...commentFields
    }
  }
}
    ${CommentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCommentGQL extends Apollo.Mutation<DeleteCommentMutation, DeleteCommentMutationVariables> {
    document = DeleteCommentDocument;

  }
export const CreateCommercialTermChangeRequestDocument = gql`
    mutation CreateCommercialTermChangeRequest($attributes: CreateCommercialTermChangeRequestAttributes!) {
  createCommercialTermChangeRequest(attributes: $attributes) {
    changeRequest {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCommercialTermChangeRequestGQL extends Apollo.Mutation<CreateCommercialTermChangeRequestMutation, CreateCommercialTermChangeRequestMutationVariables> {
    document = CreateCommercialTermChangeRequestDocument;

  }
export const CommercialTermsDocument = gql`
    query CommercialTerms($startweek: String, $endweek: String, $companyid: Int, $targetregion: String, $advancetype: String, $advanceunit: String, $commodity: String, $advanceevent: String) {
  commercialTerms(startweek: $startweek, endweek: $endweek, companyid: $companyid, targetregion: $targetregion, advancetype: $advancetype, advanceunit: $advanceunit, commodity: $commodity, advanceevent: $advanceevent) {
    ...commercialTerm
  }
}
    ${CommercialTermFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CommercialTermsGQL extends Apollo.Query<CommercialTermsQuery, CommercialTermsQueryVariables> {
    document = CommercialTermsDocument;

  }
export const CommoditiesDocument = gql`
    query Commodities {
  commodities {
    id
    name
    varieties {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CommoditiesGQL extends Apollo.Query<CommoditiesQuery, CommoditiesQueryVariables> {
    document = CommoditiesDocument;

  }
export const UploadCompanyCertificateDocument = gql`
    mutation UploadCompanyCertificate($file: Upload!, $expirydate: String!, $companyid: Int!, $certificatetypeid: Int!) {
  uploadCompanyCertificate(file: $file, expiryDate: $expirydate, companyId: $companyid, certificateTypeId: $certificatetypeid) {
    certificate {
      id
      expiryDate
      createdAt
      certificate {
        id
        filename
        url
        v2Url
      }
      certificateType {
        name
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadCompanyCertificateGQL extends Apollo.Mutation<UploadCompanyCertificateMutation, UploadCompanyCertificateMutationVariables> {
    document = UploadCompanyCertificateDocument;

  }
export const DeleteCompanyCertificateDocument = gql`
    mutation DeleteCompanyCertificate($id: Int!) {
  deleteCompanyCertificate(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCompanyCertificateGQL extends Apollo.Mutation<DeleteCompanyCertificateMutation, DeleteCompanyCertificateMutationVariables> {
    document = DeleteCompanyCertificateDocument;

  }
export const CertificateTypeDocument = gql`
    query CertificateType {
  certificateTypes {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CertificateTypeGQL extends Apollo.Query<CertificateTypeQuery, CertificateTypeQueryVariables> {
    document = CertificateTypeDocument;

  }
export const MyCompanyDocument = gql`
    query MyCompany {
  myCompany {
    id
    name
    about
    registrationNumber
    vatNumber
    businessType
    businessId
    createdAt
    logoFile {
      id
      filename
      url
    }
    address {
      addressLine1
      addressLine2
      city
      postalCode
      country
    }
    tradeRegions {
      id
      name
      code
      countries {
        id
        name
        code
      }
    }
    certificates {
      id
      expiryDate
      createdAt
      updatedAt
      certificate {
        id
        filename
        url
        v2Url
      }
      certificateType {
        name
        id
      }
    }
    varieties {
      id
      varietyId
      name
      peakCount
      hectares
      packingStart
      packingEnd
      commodity {
        id
        name
      }
      images {
        id
        url
        v2Url
        filename
        thumbnailUrl
        v2ThumbnailUrl
      }
    }
    additionalFields {
      id
      companyId
      name
      description
      fieldType
      parameters
      createdAt
      updatedAt
      isPackingInstruction
    }
    activeUsers {
      id
      firstName
      lastName
      phoneNumber
      email
      state
      roles {
        id
        name
        resourceType
        resourceId
        createdAt
        updatedAt
      }
      employee {
        id
      }
    }
    invites {
      id
      email
      state
    }
    commoditySeasons {
      ...commoditySeason
    }
  }
}
    ${CommoditySeasonFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MyCompanyGQL extends Apollo.Query<MyCompanyQuery, MyCompanyQueryVariables> {
    document = MyCompanyDocument;

  }
export const UpdateCompanyDetailsDocument = gql`
    mutation UpdateCompanyDetails($attributes: UpdateCompanyAttributes!) {
  updateCompanyDetails(attributes: $attributes) {
    company {
      id
      name
      about
      registrationNumber
      vatNumber
      address {
        addressLine1
        addressLine2
        city
        postalCode
        country
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyDetailsGQL extends Apollo.Mutation<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables> {
    document = UpdateCompanyDetailsDocument;

  }
export const UploadCompanyLogoDocument = gql`
    mutation UploadCompanyLogo($file: Upload!, $companyid: Int!) {
  uploadCompanyLogo(file: $file, companyId: $companyid) {
    company {
      logoFile {
        id
        filename
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadCompanyLogoGQL extends Apollo.Mutation<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables> {
    document = UploadCompanyLogoDocument;

  }
export const UpdateCompanyTradeRegionsDocument = gql`
    mutation UpdateCompanyTradeRegions($addtraderegions: [TradeRegionAttributes!]!, $removetraderegions: [TradeRegionAttributes!]!) {
  updateCompanyTradeRegions(addTradeRegions: $addtraderegions, removeTradeRegions: $removetraderegions) {
    company {
      tradeRegions {
        id
        name
        code
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyTradeRegionsGQL extends Apollo.Mutation<UpdateCompanyTradeRegionsMutation, UpdateCompanyTradeRegionsMutationVariables> {
    document = UpdateCompanyTradeRegionsDocument;

  }
export const CreateFarmVarietyDocument = gql`
    mutation CreateFarmVariety($attributes: CreateFarmVarietyAttributes!) {
  createFarmVariety(attributes: $attributes) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFarmVarietyGQL extends Apollo.Mutation<CreateFarmVarietyMutation, CreateFarmVarietyMutationVariables> {
    document = CreateFarmVarietyDocument;

  }
export const UpdateFarmVarietyDocument = gql`
    mutation UpdateFarmVariety($attributes: UpdateFarmVarietyAttributes!) {
  updateFarmVariety(attributes: $attributes) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFarmVarietyGQL extends Apollo.Mutation<UpdateFarmVarietyMutation, UpdateFarmVarietyMutationVariables> {
    document = UpdateFarmVarietyDocument;

  }
export const DeleteFarmVarietyDocument = gql`
    mutation DeleteFarmVariety($varietyid: Int!, $farmid: Int!) {
  deleteFarmVariety(varietyId: $varietyid, farmId: $farmid) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFarmVarietyGQL extends Apollo.Mutation<DeleteFarmVarietyMutation, DeleteFarmVarietyMutationVariables> {
    document = DeleteFarmVarietyDocument;

  }
export const UploadFarmVarietyImageDocument = gql`
    mutation UploadFarmVarietyImage($id: Int!, $image: Upload!) {
  uploadFarmVarietyImage(id: $id, image: $image) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadFarmVarietyImageGQL extends Apollo.Mutation<UploadFarmVarietyImageMutation, UploadFarmVarietyImageMutationVariables> {
    document = UploadFarmVarietyImageDocument;

  }
export const DeleteFarmVarietyImageDocument = gql`
    mutation DeleteFarmVarietyImage($varietyid: Int!, $imageid: Int!) {
  deleteFarmVarietyImage(varietyId: $varietyid, imageId: $imageid) {
    farmVariety {
      ...farmVariety
    }
  }
}
    ${FarmVarietyFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFarmVarietyImageGQL extends Apollo.Mutation<DeleteFarmVarietyImageMutation, DeleteFarmVarietyImageMutationVariables> {
    document = DeleteFarmVarietyImageDocument;

  }
export const InviteUserDocument = gql`
    mutation InviteUser($email: String!) {
  inviteUser(email: $email) {
    invite {
      id
      email
      state
      message
      createdAt
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteUserGQL extends Apollo.Mutation<InviteUserMutation, InviteUserMutationVariables> {
    document = InviteUserDocument;

  }
export const RevokeInviteDocument = gql`
    mutation RevokeInvite($id: Int!) {
  revokeInvite(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RevokeInviteGQL extends Apollo.Mutation<RevokeInviteMutation, RevokeInviteMutationVariables> {
    document = RevokeInviteDocument;

  }
export const ResendInviteDocument = gql`
    mutation ResendInvite($id: Int!) {
  resendInvite(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendInviteGQL extends Apollo.Mutation<ResendInviteMutation, ResendInviteMutationVariables> {
    document = ResendInviteDocument;

  }
export const CurrentMapPointsDocument = gql`
    query CurrentMapPoints {
  currentMapPoints {
    totalCartons
    containers {
      id
      containerNumber
      orderId
      otherPartyName
      vesselName
      portOfDestination
      eta
    }
    orders {
      orderId
      otherPartyName
      totalCartons
    }
    location {
      ...vesselMap
      ...seaPortMap
      ...fboMap
      __typename
    }
  }
}
    ${VesselMapFragmentDoc}
${SeaPortMapFragmentDoc}
${FboMapFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentMapPointsGQL extends Apollo.Query<CurrentMapPointsQuery, CurrentMapPointsQueryVariables> {
    document = CurrentMapPointsDocument;

  }
export const CurrentMapPointsForOrderDocument = gql`
    query CurrentMapPointsForOrder($id: Int!) {
  currentMapPointsForOrder(id: $id) {
    totalCartons
    containers {
      id
      containerNumber
      orderId
      otherPartyName
      vesselName
      portOfDestination
      eta
    }
    orders {
      orderId
      otherPartyName
      totalCartons
    }
    location {
      ...vesselMap
      ...seaPortMap
      ...fboMap
      __typename
    }
  }
}
    ${VesselMapFragmentDoc}
${SeaPortMapFragmentDoc}
${FboMapFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentMapPointsForOrderGQL extends Apollo.Query<CurrentMapPointsForOrderQuery, CurrentMapPointsForOrderQueryVariables> {
    document = CurrentMapPointsForOrderDocument;

  }
export const MetabaseReportsDocument = gql`
    query MetabaseReports($chart_type: String!) {
  metabaseReports(chartType: $chart_type) {
    chartType
    id
    name
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MetabaseReportsGQL extends Apollo.Query<MetabaseReportsQuery, MetabaseReportsQueryVariables> {
    document = MetabaseReportsDocument;

  }
export const OrderAlertsDocument = gql`
    query OrderAlerts {
  orderAlerts {
    ...orderAlertFields
  }
}
    ${OrderAlertFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderAlertsGQL extends Apollo.Query<OrderAlertsQuery, OrderAlertsQueryVariables> {
    document = OrderAlertsDocument;

  }
export const HideOrderAlertDocument = gql`
    mutation HideOrderAlert($id: Int!) {
  hideOrderAlert(id: $id) {
    orderAlert {
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HideOrderAlertGQL extends Apollo.Mutation<HideOrderAlertMutation, HideOrderAlertMutationVariables> {
    document = HideOrderAlertDocument;

  }
export const UnhideOrderAlertDocument = gql`
    mutation UnhideOrderAlert($id: Int!) {
  unhideOrderAlert(id: $id) {
    orderAlert {
      state
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnhideOrderAlertGQL extends Apollo.Mutation<UnhideOrderAlertMutation, UnhideOrderAlertMutationVariables> {
    document = UnhideOrderAlertDocument;

  }
export const QuantityTypeDocument = gql`
    query quantityType {
  quantityType
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QuantityTypeGQL extends Apollo.Query<QuantityTypeQuery, QuantityTypeQueryVariables> {
    document = QuantityTypeDocument;

  }
export const CreateOrderDocument = gql`
    mutation CreateOrder($attributes: CreateOrderAttributes!, $lineitemstates: [String!], $packingweekstart: String, $packingweekend: String) {
  createOrder(attributes: $attributes) {
    order {
      ...multipleOrderFields
    }
    errors
  }
}
    ${MultipleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrderGQL extends Apollo.Mutation<CreateOrderMutation, CreateOrderMutationVariables> {
    document = CreateOrderDocument;

  }
export const OrdersSearchDocument = gql`
    query OrdersSearch($limit: Int!, $skip: Int!, $filter: OrderFilter!, $lineitemstates: [String!], $packingweekstart: String, $packingweekend: String) {
  ordersSearch(filter: $filter) {
    orders(limit: $limit, skip: $skip) {
      ...multipleOrderFields
    }
    totalOrders
    totalFiltered
  }
}
    ${MultipleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrdersSearchGQL extends Apollo.Query<OrdersSearchQuery, OrdersSearchQueryVariables> {
    document = OrdersSearchDocument;

  }
export const OrderDocument = gql`
    query Order($id: ID!) {
  order(id: $id) {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderGQL extends Apollo.Query<OrderQuery, OrderQueryVariables> {
    document = OrderDocument;

  }
export const DuplicateOrderDocument = gql`
    query DuplicateOrder($id: ID!) {
  order(id: $id) {
    ...duplicateOrderFields
  }
}
    ${DuplicateOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DuplicateOrderGQL extends Apollo.Query<DuplicateOrderQuery, DuplicateOrderQueryVariables> {
    document = DuplicateOrderDocument;

  }
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($attributes: UpdateOrderAttributes!) {
  updateOrder(attributes: $attributes) {
    order {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrderGQL extends Apollo.Mutation<UpdateOrderMutation, UpdateOrderMutationVariables> {
    document = UpdateOrderDocument;

  }
export const AcceptLineItemsDocument = gql`
    mutation AcceptLineItems($ids: [Int!]!) {
  acceptLineItems(ids: $ids) {
    orders {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptLineItemsGQL extends Apollo.Mutation<AcceptLineItemsMutation, AcceptLineItemsMutationVariables> {
    document = AcceptLineItemsDocument;

  }
export const WontPackLineItemsDocument = gql`
    mutation WontPackLineItems($ids: [Int!]!) {
  wontPackLineItems(ids: $ids) {
    orders {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WontPackLineItemsGQL extends Apollo.Mutation<WontPackLineItemsMutation, WontPackLineItemsMutationVariables> {
    document = WontPackLineItemsDocument;

  }
export const RevertLineItemsToCreatedDocument = gql`
    mutation RevertLineItemsToCreated($ids: [Int!]!) {
  revertLineItemsToCreated(ids: $ids) {
    orders {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RevertLineItemsToCreatedGQL extends Apollo.Mutation<RevertLineItemsToCreatedMutation, RevertLineItemsToCreatedMutationVariables> {
    document = RevertLineItemsToCreatedDocument;

  }
export const RevertOrderChangeDocument = gql`
    mutation RevertOrderChange($startid: Int!, $endid: Int!) {
  revertOrderChange(startId: $startid, endId: $endid) {
    order {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RevertOrderChangeGQL extends Apollo.Mutation<RevertOrderChangeMutation, RevertOrderChangeMutationVariables> {
    document = RevertOrderChangeDocument;

  }
export const UploadOrderShipmentDocumentDocument = gql`
    mutation UploadOrderShipmentDocument($orderid: Int!, $type: String!, $file: Upload!) {
  uploadOrderShipmentDocument(orderid: $orderid, type: $type, file: $file) {
    order {
      shipmentDocuments {
        ...shipmentDocumentFields
      }
    }
  }
}
    ${ShipmentDocumentFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadOrderShipmentDocumentGQL extends Apollo.Mutation<UploadOrderShipmentDocumentMutation, UploadOrderShipmentDocumentMutationVariables> {
    document = UploadOrderShipmentDocumentDocument;

  }
export const GenerateDemoDataDocument = gql`
    mutation GenerateDemoData($id: Int!) {
  generateDemoData(id: $id) {
    order {
      ...singleOrderFields
    }
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateDemoDataGQL extends Apollo.Mutation<GenerateDemoDataMutation, GenerateDemoDataMutationVariables> {
    document = GenerateDemoDataDocument;

  }
export const NewOrderDocument = gql`
    subscription NewOrder {
  newOrder {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewOrderGQL extends Apollo.Subscription<NewOrderSubscription, NewOrderSubscriptionVariables> {
    document = NewOrderDocument;

  }
export const OnAcceptLineItemsDocument = gql`
    subscription OnAcceptLineItems {
  onAcceptLineItems {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnAcceptLineItemsGQL extends Apollo.Subscription<OnAcceptLineItemsSubscription, OnAcceptLineItemsSubscriptionVariables> {
    document = OnAcceptLineItemsDocument;

  }
export const OnWontPackLineItemsDocument = gql`
    subscription OnWontPackLineItems {
  onWontPackLineItems {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnWontPackLineItemsGQL extends Apollo.Subscription<OnWontPackLineItemsSubscription, OnWontPackLineItemsSubscriptionVariables> {
    document = OnWontPackLineItemsDocument;

  }
export const OnRevertLineItemsToCreatedDocument = gql`
    subscription OnRevertLineItemsToCreated {
  onRevertLineItemsToCreated {
    ...singleOrderFields
  }
}
    ${SingleOrderFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OnRevertLineItemsToCreatedGQL extends Apollo.Subscription<OnRevertLineItemsToCreatedSubscription, OnRevertLineItemsToCreatedSubscriptionVariables> {
    document = OnRevertLineItemsToCreatedDocument;

  }
export const PackInstructionsDocument = gql`
    query PackInstructions {
  packinstructions {
    ...packInstructionsFields
  }
}
    ${PackInstructionsFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PackInstructionsGQL extends Apollo.Query<PackInstructionsQuery, PackInstructionsQueryVariables> {
    document = PackInstructionsDocument;

  }
export const TogglePackInstructionsRowPriorityDocument = gql`
    mutation TogglePackInstructionsRowPriority($lineitemids: [Int!]!) {
  togglePackInstructionsRowPriority(lineitemids: $lineitemids) {
    packInstructionsRows {
      ...packInstructionsLineItemFields
    }
  }
}
    ${PackInstructionsLineItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TogglePackInstructionsRowPriorityGQL extends Apollo.Mutation<TogglePackInstructionsRowPriorityMutation, TogglePackInstructionsRowPriorityMutationVariables> {
    document = TogglePackInstructionsRowPriorityDocument;

  }
export const GetPricingHelpDocument = gql`
    mutation GetPricingHelp($attributes: PricingHelperAttributes!) {
  getPricingHelp(attributes: $attributes) {
    fotPriceMinorUnit
    cifPriceMinorUnit
    fobPriceMinorUnit
    dipPriceMinorUnit
    exwPriceMinorUnit
    currency
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPricingHelpGQL extends Apollo.Mutation<GetPricingHelpMutation, GetPricingHelpMutationVariables> {
    document = GetPricingHelpDocument;

  }
export const SettingsDocument = gql`
    query Settings {
  myUser {
    id
    firstName
    lastName
    phoneNumber
    email
    state
    notifyEmail
    notifyBrowserPush
    createdAt
    updatedAt
    useV2
    avatar
    avatarFile {
      id
      filename
      url
    }
    company {
      id
      name
    }
    roles {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SettingsGQL extends Apollo.Query<SettingsQuery, SettingsQueryVariables> {
    document = SettingsDocument;

  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $firstname: String!, $lastname: String!, $phonenumber: String!, $notifyemail: Boolean!, $notifybrowserpush: Boolean!, $usev2: Boolean!) {
  updateUser(id: $id, firstName: $firstname, lastName: $lastname, phoneNumber: $phonenumber, notifyEmail: $notifyemail, notifyBrowserPush: $notifybrowserpush, useV2: $usev2) {
    user {
      id
      firstName
      lastName
      phoneNumber
      state
      notifyEmail
      notifyBrowserPush
      useV2
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;

  }
export const UploadUserAvatarDocument = gql`
    mutation UploadUserAvatar($file: Upload!) {
  uploadUserAvatar(file: $file) {
    user {
      avatarFile {
        id
        filename
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadUserAvatarGQL extends Apollo.Mutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables> {
    document = UploadUserAvatarDocument;

  }
export const UpdateShipmentDocumentDocument = gql`
    mutation UpdateShipmentDocument($id: Int!, $type: String!) {
  updateShipmentDocument(id: $id, type: $type) {
    shipmentDocument {
      id
      documentType
      state
      user {
        id
        firstName
        lastName
        company {
          id
        }
      }
      file {
        id
        filename
        v2Url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShipmentDocumentGQL extends Apollo.Mutation<UpdateShipmentDocumentMutation, UpdateShipmentDocumentMutationVariables> {
    document = UpdateShipmentDocumentDocument;

  }
export const DeleteShipmentDocumentDocument = gql`
    mutation DeleteShipmentDocument($id: Int!) {
  deleteShipmentDocument(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteShipmentDocumentGQL extends Apollo.Mutation<DeleteShipmentDocumentMutation, DeleteShipmentDocumentMutationVariables> {
    document = DeleteShipmentDocumentDocument;

  }
export const TicketsDocument = gql`
    query Tickets($email: String!) {
  tickets(email: $email) {
    ...ticketFields
  }
}
    ${TicketFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TicketsGQL extends Apollo.Query<TicketsQuery, TicketsQueryVariables> {
    document = TicketsDocument;

  }
export const CreateTicketDocument = gql`
    mutation CreateTicket($attributes: CreateTicketAttributes!) {
  createTicket(attributes: $attributes) {
    ticket {
      ...ticketFields
    }
  }
}
    ${TicketFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTicketGQL extends Apollo.Mutation<CreateTicketMutation, CreateTicketMutationVariables> {
    document = CreateTicketDocument;

  }
export const TradePartnerDocument = gql`
    query TradePartner($id: ID!) {
  company(id: $id) {
    id
    name
    about
    logoFile {
      id
      filename
      url
    }
    businessType
    registrationNumber
    vatNumber
    createdAt
    address {
      addressLine1
      addressLine2
      city
      postalCode
      country
    }
    varieties {
      id
      varietyId
      name
      packingStart
      packingEnd
      peakCount
      hectares
      images {
        id
        url
        thumbnailUrl
      }
      commodity {
        id
        name
      }
    }
    certificates {
      id
      companyId
      expiryDate
      certificate {
        url
        filename
      }
      certificateType {
        name
        id
      }
    }
    tradeRegions {
      name
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradePartnerGQL extends Apollo.Query<TradePartnerQuery, TradePartnerQueryVariables> {
    document = TradePartnerDocument;

  }
export const TradePartnersDocument = gql`
    query TradePartners {
  myCompany {
    id
    companies {
      id
      name
      logoFile {
        id
        url
      }
      varieties {
        id
        varietyId
        name
        commodity {
          id
          name
        }
      }
      certificates {
        certificateType {
          name
        }
      }
      tradeRegions {
        name
        code
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradePartnersGQL extends Apollo.Query<TradePartnersQuery, TradePartnersQueryVariables> {
    document = TradePartnersDocument;

  }
export const AllTradeRegionsDocument = gql`
    query AllTradeRegions {
  tradeRegions {
    id
    name
    code
    countries {
      id
      code
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllTradeRegionsGQL extends Apollo.Query<AllTradeRegionsQuery, AllTradeRegionsQueryVariables> {
    document = AllTradeRegionsDocument;

  }
export const UserFeedItemsConnectionDocument = gql`
    query UserFeedItemsConnection($last: Int, $after: String, $first: Int, $before: String) {
  userFeedConnection(last: $last, after: $after, first: $first, before: $before) {
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...userFeedItem
      }
    }
  }
}
    ${UserFeedItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFeedItemsConnectionGQL extends Apollo.Query<UserFeedItemsConnectionQuery, UserFeedItemsConnectionQueryVariables> {
    document = UserFeedItemsConnectionDocument;

  }
export const UserFeedUnseenCountDocument = gql`
    query UserFeedUnseenCount {
  userFeedUnseenCount
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFeedUnseenCountGQL extends Apollo.Query<UserFeedUnseenCountQuery, UserFeedUnseenCountQueryVariables> {
    document = UserFeedUnseenCountDocument;

  }
export const UpdateUserFeedItemDocument = gql`
    mutation UpdateUserFeedItem($attributes: UpdateUserFeedItemAttributes!) {
  updateUserFeedItem(attributes: $attributes) {
    userFeedItem {
      id
      seen
      read
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserFeedItemGQL extends Apollo.Mutation<UpdateUserFeedItemMutation, UpdateUserFeedItemMutationVariables> {
    document = UpdateUserFeedItemDocument;

  }
export const UpdateUserFeedItemsDocument = gql`
    mutation UpdateUserFeedItems($attributes: [UpdateUserFeedItemAttributes!]!) {
  updateUserFeedItems(attributes: $attributes) {
    userFeedItems {
      id
      seen
      read
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserFeedItemsGQL extends Apollo.Mutation<UpdateUserFeedItemsMutation, UpdateUserFeedItemsMutationVariables> {
    document = UpdateUserFeedItemsDocument;

  }
export const NewUserFeedItemDocument = gql`
    subscription newUserFeedItem {
  newUserFeedItem {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewUserFeedItemGQL extends Apollo.Subscription<NewUserFeedItemSubscription, NewUserFeedItemSubscriptionVariables> {
    document = NewUserFeedItemDocument;

  }
export const UserDocument = gql`
    query User {
  myUser {
    id
    firstName
    lastName
    phoneNumber
    email
    state
    notifyEmail
    notifyBrowserPush
    createdAt
    updatedAt
    useV2
    avatarFile {
      id
      filename
      url
    }
    company {
      id
      name
    }
    roles {
      name
    }
  }
  myCompany {
    id
    name
    businessType
    certificates {
      id
      expiryDate
      createdAt
      updatedAt
      certificate {
        id
        filename
        url
      }
      certificateType {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    document = UserDocument;

  }
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($employeeid: Int!) {
  deleteEmployee(employeeId: $employeeid) {
    employeeId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEmployeeGQL extends Apollo.Mutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables> {
    document = DeleteEmployeeDocument;

  }
