<div *ngIf="initialized"
     class="account">
  <ag-nav [isExistingUser]="isExistingUser"
          [myCompany]="myCompany"></ag-nav>

  <ag-notification [(visible)]="showCertificateExpiryBanner"
                   [type]="'is-warning'"
                   [isMessage]="true">
    <div notification-header>Attention</div>
    <div notification-body>
        You have company certificates that have expired, or are in danger of expiring:<br /><br />
        <ul>
          <ng-container *ngFor="let cert of myCompany?.certificates">
            <li *ngIf="expired(cert)">
              {{ cert.certificate.filename }}
            </li>
          </ng-container>
        </ul>
    </div>
  </ag-notification>

  <section class="section">
    <router-outlet></router-outlet>
  </section>

</div>

<ng-template agModalHost></ng-template>