<div class="modal"
     [ngClass]="{ 'is-active': isVisible }">
  <div class="modal-background"></div>
  <form *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="onConfirmClick()">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{title}}</p>
      </header>
      <section class="modal-card-body">
        <div formArrayName="formFields">
          <div *ngFor="let field of fields; index as i"
               class="field">
            <label class="label">{{field.inputLabel}}</label>
            <select *ngIf="field.inputType === 'dropdown'"
                    class="input"
                    #modalInput
                    [formControlName]="i"
                    (change)="customErrors[field.inputLabel] = []"
                    [ngClass]="{'is-danger': formSubmitted && (formControls[i].errors || customErrors[field.inputLabel].length > 0) }"
                    [attr.tabindex]="i+1">
              <option *ngFor="let option of field.dropDownOptions"
                      [ngValue]="option">
                {{option}}
              </option>
            </select>
            <ng-container *ngIf="field.inputType === 'boolean'">
              <input id="bulmaSwitch"
                     #modalInput
                     type="checkbox"
                     class="switch is-primary"
                     [formControlName]="i"
                     (change)="customErrors[field.inputLabel] = []"
                     [ngClass]="{'is-danger': formSubmitted && (formControls[i].errors || customErrors[field.inputLabel].length> 0) }"
                     [attr.tabindex]="i+1" />
              <label class="label"
                     for="bulmaSwitch"></label>
            </ng-container>
            <input *ngIf="field.inputType === 'text' || field.inputType === 'email'"
                   placeholder="..."
                   #modalInput
                   class="input"
                   [type]="field.inputType"
                   [formControlName]="i"
                   [pattern]="field.pattern"
                   (keydown)="customErrors[field.inputLabel] = []"
                   [ngClass]="{'is-danger': formSubmitted && (formControls[i].errors || customErrors[field.inputLabel].length> 0) }"
                   [attr.tabindex]="i+1" />
            <div *ngIf="formSubmitted && formControls[i].errors"
                 class="has-text-danger help">
              <small
                     *ngIf="formControls[i].errors.required">{{field.inputLabel}}
                is required</small>
              <small *ngIf="formControls[i].errors.email">Please enter a
                valid email address</small>
              <small *ngIf="formControls[i].errors.minlength">
                {{field.inputLabel}} cannot be shorter than
                {{field.minLength}}
                characters
              </small>
              <small *ngIf="formControls[i].errors.maxlength">
                {{field.inputLabel}} cannot be longer than
                {{field.maxLength}}
                characters
              </small>
              <small *ngIf="formControls[i].errors.min">{{field.inputLabel}}
                cannot be smaller than {{field.min}}</small>
              <small *ngIf="formControls[i].errors.max">{{field.inputLabel}}
                cannot be larger than {{field.max}}</small>
              <small
                     *ngIf="formControls[i].errors.pattern">{{patternValidationMessage}}</small>
              <!-- Add new validation here -->
            </div>
            <!-- Custom errors-->
            <div *ngIf="customErrors[field.inputLabel].length > 0"
                 class="has-text-danger help">
              <small *ngFor="let error of customErrors[field.inputLabel]">
                {{ error }}
              </small>
            </div>
            <!--End custom errors-->
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-link"
                type="submit"
                [ngClass]="[confirmBtnClass, isLoading ? 'is-loading' : '']"
                [disabled]="!form.dirty"
                [attr.tabindex]="fields.length+1">
          {{confirmBtnText}}
        </button>
        <button class="button"
                (click)="onCancelClick()"
                type="button"
                [ngClass]="{'is-disabled': isLoading}"
                [attr.tabindex]="fields.length+2">
          Cancel
        </button>
      </footer>
    </div>
  </form>
</div>
