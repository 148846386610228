import { User } from '@app/generated/graphql';

export abstract class UserHelper {

  static getFullName(user: User) {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return null;
  }

  static isNewUser(user: User) {
    return user.state === 'invited';
  }

  static isExistingUser(user: User) {
    return user.state === 'active';
  }

  static formattedRoles(user: User): Array<String> {
    const arr = [];
    user.roles.map(role => {
      let roleName = role.name.replace('_', ' ');
      roleName = roleName.charAt(0).toUpperCase() + roleName.slice(1);
      arr.push(roleName);
    });
    return arr;
  }

  static userRoles(user: User): Array<String> {
    const arr = [];
    user.roles.map(role => arr.push(role.name));
    return arr;
  }

  static isPackhouseManager(user: User) {
    return this.userRoles(user).includes('packhouse_manager');
  }

  static isAdmin(user: User) {
    return this.userRoles(user).includes('admin');
  }

  static isEmployee(user: User) {
    return this.userRoles(user).includes('employee');
  }

}
