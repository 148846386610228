import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountComponent } from './account.component';
import { AuthenticationGuard } from '@app/core/guards/authentication/authentication.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PermissionGuard } from '@app/core/guards/permission/permission.guard';
import { Version2Guard } from '@app/core/guards/version-2/version2-guard.guard';
import { ExistingUserGuard } from '@app/core/guards/existing-user/existing-user.guard';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { ServerErrorComponent } from './server-error/server-error.component';


export const routes: Routes = [
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        pathMatch: 'full',
        canActivate: [PermissionGuard, Version2Guard, ExistingUserGuard],
        data: { roles: ['admin', 'employee'] }
      },
      {
        path: 'settings',
        pathMatch: 'full',
        canActivate: [PermissionGuard],
        data: { roles: ['admin', 'employee', 'packhouse_manager'] },
        loadChildren: './settings/settings.module#SettingsModule'
      },
      {
        path: 'company',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee'] },
        loadChildren: './company/company.module#CompanyModule'
      },
      {
        path: 'orders',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee'] },
        loadChildren: './orders-router/orders-router.module#OrdersRouterModule'
      },
      {
        path: 'order/:id',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee'] },
        loadChildren: './view-order/view-order.module#ViewOrderModule'
      },
      {
        path: 'new-order',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee'] },
        loadChildren: './new-order-router/new-order-router.module#NewOrderRouterModule'
      },
      {
        path: 'partners',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee'] },
        loadChildren: './trade-partners/trade-partners.module#TradePartnersModule'
      },
      {
        path: 'support',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee'] },
        loadChildren: './support/support.module#SupportModule'
      },
      {
        path: 'planning',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee', 'packhouse_manager'] },
        loadChildren: './planning/planning.module#PlanningModule'
      },
      {
        path: 'pack-instructions',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee', 'packhouse_manager'] },
        loadChildren: './pack-instructions/pack-instructions.module#PackInstructionsModule'
      },
      {
        path: 'pallets',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          roles: ['admin', 'employee', 'packhouse_manager'],
          isLineItemBased: false
        },
        loadChildren: './pallets/pallets.module#PalletsModule'
      },
      {
        path: 'line-item/:id/pallets',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          roles: ['admin', 'employee', 'packhouse_manager'],
          isLineItemBased: true
        },
        loadChildren: './pallets/pallets.module#PalletsModule'
      },
      {
        path: 'cash-flow',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          roles: ['admin', 'employee', 'packhouse_manager'],
          isLineItemBased: true
        },
        loadChildren: './cash-flow/cash-flow.module#CashFlowModule'
      },
      {
        path: 'commercial-terms',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee', 'packhouse_manager'] },
        loadChildren: './commercial-terms/commercial-terms.module#CommercialTermsModule'
      },
      {
        path: 'lineage-card',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: { roles: ['admin', 'employee', 'packhouse_manager'] },
        loadChildren: './lineage-card/lineage-card.module#LineageCardModule'
      },
      {
        path: 'data-insights',
        pathMatch: 'full',
        canActivate: [PermissionGuard, ExistingUserGuard],
        data: {
          roles: ['admin', 'employee', 'packhouse_manager'],
        },
        loadChildren: './reports/reports.module#ReportsModule'
      },
      {
        path: '404',
        component: FourOhFourComponent,
        data: { roles: ['admin', 'employee', 'packhouse_manager'] }
      },
      {
        path: '500',
        component: ServerErrorComponent,
        data: { roles: ['admin', 'employee', 'packhouse_manager'] }
      },
      {
        path: '**',
        redirectTo: '/account/404',
        data: { roles: ['admin', 'employee', 'packhouse_manager'] }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
