<div class="content has-text-centered">
  <h1>404</h1>
  <ng-container *ngIf="previousUrl">
      <p>Oops, looks like the page <b>{{previousUrl}}</b> does not exist...</p>
  </ng-container>
  <ng-container *ngIf="!previousUrl">
    <p>Oops, looks like that page does not exist...</p>
  </ng-container>
  <button (click)="goBack()"
          class="button">Go Back</button>
</div>