<ng-container *ngIf="!isMessage">
  <div class="notification"
       [ngClass]="[type, isColumn ? 'is-column' : '' ]"
       *ngIf="visible"
       [@notificationScaleInAnimation]>
    <button class="delete"
            (click)="dismiss()"></button>
    <ng-content></ng-content>
  </div>
</ng-container>

<ng-container *ngIf="isMessage">
  <div class="message"
       [ngClass]="[type, isColumn ? 'is-column' : '' ]"
       *ngIf="visible"
       [@notificationScaleInAnimation]>
    <div class="message-header">
      <ng-content select="[notification-header]"></ng-content>
      <button class="delete"
              (click)="dismiss()"></button>
    </div>
    <div class="message-body">
      <ng-content select="[notification-body]"></ng-content>
    </div>
  </div>
</ng-container>