import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VersionService } from '@app/core/services/version/version.service';

@Injectable({
  providedIn: 'root'
})
export class Version2Guard implements CanActivate {

  constructor(private versionService: VersionService, private router: Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.versionService.uiVersion().pipe(
      map(_res => {
        return true;
        // allow by default as we only have one version currently
        // if (res !== 2) {
        //   this.router.navigate(['/account/settings']);
        //   return false;
        // }
      })
    );
  }
}
