import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'ag-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit, AfterViewInit {

  innerWidth: number;
  innerHeight: number;

  @ViewChild('orangeImage', { static: true }) img: ElementRef;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    const xPercent = (event.clientX / this.innerWidth) * 100;
    const yPercent = (event.clientY / this.innerHeight) * 100;
    const xPercentReg = 20 * (xPercent / 100);
    const yPercentReg = 20 * (yPercent / 100);
    const xPx = -10 + xPercentReg;
    const yPx = 10 + yPercentReg;
    (this.img.nativeElement as HTMLElement).style.filter = `drop-shadow(${xPx}px ${yPx}px 10px #b4b4b4)`;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  constructor(private location: Location) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  ngAfterViewInit(): void {
    (this.img.nativeElement as HTMLElement).style.filter = 'drop-shadow(0 20px 10px #b4b4b4)';
  }

  goBack() {
    this.location.back();
  }

}
