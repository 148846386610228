import { Component, OnInit } from '@angular/core';
import { UrlTrackerService } from '@app/core/services/url-tracker/url-tracker.service';
import { Location } from '@angular/common';

@Component({
  selector: 'ag-four-oh-four',
  templateUrl: './four-oh-four.component.html',
  styleUrls: ['./four-oh-four.component.scss']
})
export class FourOhFourComponent implements OnInit {

  previousUrl: String;

  constructor(urlTrackerService: UrlTrackerService,
    private location: Location) {
    this.previousUrl = urlTrackerService.previousUrl;
  }

  ngOnInit() { }

  goBack() {
    this.location.back();
  }

}
