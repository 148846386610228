import { Component, OnInit, ViewChild, ElementRef, QueryList } from '@angular/core';
import { FormBuilder, Validators, ValidatorFn, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Modal } from '../../interfaces/modal.interface';
import { InputModalField } from '@app/core/interfaces/input-modal-field.interface';

export interface InputModalConfig {
  title: string;
  fields?: Array<InputModalField>;
  field?: InputModalField;

  confirmBtnText?: string;
  confirmBtnClass?: string;
  onConfirm: (result: Array<string> | Array<number> | Array<boolean> | string | number | boolean) => void;
  onCancel?: () => void;
}

@Component({
  selector: 'ag-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements OnInit, Modal {

  form: FormGroup;
  get formFields(): FormArray { return this.form.get('formFields') as FormArray; }

  title: string;
  fields: Array<InputModalField>;
  singleField = false;

  confirmBtnText: string;
  confirmBtnClass: string;
  customErrors: Map<string, string[]> = new Map();
  onConfirm: (result: Array<string> | Array<number> | Array<boolean> | string | number | boolean) => void;
  onCancel: () => void;

  isLoading = false;
  isVisible = false;
  formSubmitted = false;
  onAction: Promise<boolean>;

  private onActionResolve: (action: boolean) => void;

  @ViewChild('modalInput') inputs: QueryList<ElementRef>;

  constructor(private formBuilder: FormBuilder) {
    this.onAction = new Promise((resolve) => this.onActionResolve = resolve);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      formFields: new FormArray(this.fieldsWithValidators())
    });

    this.fields.forEach((field) => {
      this.customErrors[field.inputLabel] = [];
    });
  }

  fieldsWithValidators(): Array<FormControl> {
    return this.fields.map((field, index) => {
      const validators: Array<ValidatorFn> = [];

      if (field.required) {
        validators.push(Validators.required);
      }

      switch (field.inputType) {
        case 'email':
          this.addEmailValidation(validators);
          break;
        case 'number':
          this.addNumberValidation(field, this.inputs[index], validators);
          break;
        case 'text':
          this.addTextValidation(field, validators);
          break;
        case 'boolean':
          break;
        case 'dropdown':
          break;
        default:
          throw new Error(`Unrecognized input type ${field.inputType}`);
      }
      return new FormControl(field.defaultValue, validators);
    });
  }

  addNumberValidation(field: InputModalField, input: ElementRef, validators: Array<ValidatorFn>) {
    if (field.min !== undefined) {
      validators.push(Validators.min(field.min));
      input.nativeElement.setAttribute('min', field.min);
    }

    if (field.max !== undefined) {
      validators.push(Validators.max(field.max));
      input.nativeElement.setAttribute('max', field.max);
    }
  }

  addTextValidation(field: InputModalField, validators: Array<ValidatorFn>) {
    if (field.minLength !== undefined) {
      validators.push(Validators.minLength(field.minLength));
    }

    if (field.maxLength !== undefined) {
      validators.push(Validators.maxLength(field.maxLength));
    }
  }

  addEmailValidation(validators: Array<ValidatorFn>) {
    validators.push(Validators.email);
  }

  configure(config: InputModalConfig) {
    this.title = config.title;
    this.fields = config.fields;

    if (config.field) {
      this.singleField = true;
      this.fields = [config.field];
    }

    this.confirmBtnClass = config.confirmBtnClass || 'is-link';
    this.confirmBtnText = config.confirmBtnText || 'Ok';
    this.onConfirm = config.onConfirm;
    this.onCancel = config.onCancel || this.hide;
  }

  show() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  onConfirmClick() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    this.onActionResolve(true);

    let result = this.formFields.value;

    // if a single field was passed instead of fields,
    // unpack the result for ease of use
    if (this.singleField) {
      result = result[0];
    }

    this.onConfirm(result);
  }

  onCancelClick() {
    this.onActionResolve(false);
    this.onCancel();
  }

  addCustomError(inputLabel: string, message: string) {
    if (this.customErrors[inputLabel].indexOf(message) === -1) {
      this.customErrors[inputLabel].push(message);
    }
  }

  get formControls() {
    return this.formFields.controls;
  }
}
