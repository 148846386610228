import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { AccountModule } from './account/account.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { HttpInterceptors } from './core/interceptors';
import { RavenErrorHandler } from './core/interceptors/raven.interceptor';
import { LoginCallbackComponent } from './login/callback/login-callback.component';
import { LoginComponent } from './login/login/login.component';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './landing/landing.component';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';

const appRoutes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'login/callback', component: LoginCallbackComponent },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  declarations: [AppComponent, LoginCallbackComponent, LoginComponent],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
    FormsModule,
    AccountModule,
    HttpClientModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [
    HttpInterceptors,
    { provide: ErrorHandler, useClass: RavenErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
