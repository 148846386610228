<ag-modal-base>
  <ul>
    <li>
      <div class="m-b-md">
        <a (click)="routeToOrder()">
          Order #{{orderAlert?.orderId}}
        </a>
      </div>
    </li>
    <li>
      <div class="horizontal-text">
        <label class="label">Company:&nbsp;</label>
        <span>{{orderAlert?.affectedCompany?.name}}</span>
      </div>
    </li>
    <li>
      <div class="horizontal-text">
        <label class="label">Event:&nbsp;</label>
        <span>{{orderAlert?.eventText}}</span>
      </div>
    </li>
    <li>
      <div class="horizontal-text">
        <label class="label">Alert:&nbsp;</label>
        <span>{{orderAlert?.alertText}}</span>
      </div>
    </li>
    <li *ngIf="orderAlert?.additionalInformation.__typename === 'OrderAcceptance'">
      <ng-container *ngTemplateOutlet="orderAcceptance"></ng-container>
    </li>
    <li *ngIf="orderAlert?.additionalInformation.__typename === 'OrderFulfillment'">
      <ng-container *ngTemplateOutlet="orderFulfillment"></ng-container>
    </li>
  </ul>
</ag-modal-base>

<ng-template #orderAcceptance>
  <ul>
    <li>
      <div class="horizontal-text">
        <label class="label">Packing Week:&nbsp;</label>
        <span>{{unique(orderAlert?.additionalInformation?.lineItemPackingWeeks)}}</span>
      </div>
    </li>
  
    <li>
      <div class="horizontal-text">
        <label class="label">Unaccepted Line Items:&nbsp;</label>
        <span>{{orderAlert?.additionalInformation?.lineItemIds.length}}</span>
      </div>
    </li>
  </ul>

  <div class="content">
    <ul>
      <li *ngFor="let lineItemId of orderAlert?.additionalInformation.lineItemIds; let i = index">
        Line Item #{{lineItemId}}
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #orderFulfillment>
  <ul>
    <li>
      <div class="horizontal-text">
        <label class="label">Packing Week:&nbsp;</label>
        <span>{{unique(orderAlert?.additionalInformation?.lineItemPackingWeeks)}}</span>
      </div>
    </li>
  
    <li>
      <div class="horizontal-text">
        <label class="label">Incomplete Line Items
        {{orderAlert?.additionalInformation?.lineItemIds.length}}:</label>
      </div>
    </li>
  </ul>

  <div class="content">
    <ul>
      <li *ngFor="let lineItemId of orderAlert?.additionalInformation.lineItemIds; let i = index">
        Line Item #{{lineItemId}}:
        packed {{orderAlert?.additionalInformation?.lineItemPackedQuantity[i]}} of
        {{orderAlert?.additionalInformation?.lineItemQuantities[i]}}
        {{orderAlert?.additionalInformation?.lineItemQuantityTypes[i]}}</li>
    </ul>
  </div>
  <ng-container *ngIf="myCompany?.businessType === 'Farm'">
      <a (click)="routeToPackInstructions()">
        View Pack Instructions
      </a>
  </ng-container>
</ng-template>
