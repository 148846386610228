import { Component } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ag-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(private authService: AuthenticationService, private router: Router) {
    if (this.authService.isAuthenticated()) {
      this.authService.logout();
      return;
    }
    this.authService.login();
  }
}
