import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { notificationAnimations } from './notification-animations';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

type NotificationType = 'is-primary' | 'is-link' | 'is-info' | 'is-success' | 'is-warning' | 'is-danger';

@Component({
  selector: 'ag-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [...notificationAnimations]
})
export class NotificationComponent implements OnInit {

  @Input()
  isColumn: boolean;

  @Input()
  type: NotificationType;

  @Input()
  isMessage = false;

  isVisible = true;

  @Output()
  visibleChange = new EventEmitter();

  @Output()
  disimissed = new EventEmitter();

  @Input()
  get visible(): boolean {
    return this.isVisible;
  }

  set visible(isVisible: boolean) {
    this.isVisible = isVisible;
    this.visibleChange.emit(this.isVisible);
  }

  constructor() { }

  ngOnInit() {
  }

  dismiss() {
    this.visible = false;
    this.disimissed.emit();
  }

}
