
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }

      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "MapPointLocatableUnion",
        "possibleTypes": [
          {
            "name": "FoodBusinessOperator"
          },
          {
            "name": "SeaPort"
          },
          {
            "name": "Vessel"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CommentableUnion",
        "possibleTypes": [
          {
            "name": "Order"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "AlertInformationUnion",
        "possibleTypes": [
          {
            "name": "OrderAcceptance"
          },
          {
            "name": "OrderFulfillment"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "NotifiableUnion",
        "possibleTypes": [
          {
            "name": "Comment"
          }
        ]
      }
    ]
  }
};

      export default result;
    