import { Component, Input } from '@angular/core';

@Component({
  selector: 'ag-page-loading-placeholder',
  templateUrl: './page-loading-placeholder.component.html'
})
export class PageLoadingPlaceholderComponent {

  @Input()
  title: string;

}
