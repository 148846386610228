import { Component, Input, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'ag-agrigateone-icon',
  templateUrl: './agrigateone-icon.component.html',
  styleUrls: ['./agrigateone-icon.component.scss']
})
export class AgrigateoneIconComponent implements OnInit {

  @Input() height: number;
  @Input() backgroundType: string;
  @Input() isFullWidth = false;
  @HostBinding('style.width') widthBinding: string;
  @HostBinding('style.height') heightBinding: string;

  white = '#ffffff';
  blue = '#16406f';
  green = '#8db043';
  yellow = '#f2d235';

  primary: string;

  ngOnInit() {
    this.primary = this.backgroundType === 'light' ? this.blue : this.white;
    this.widthBinding = this.isFullWidth ? '100%' : null;
    this.heightBinding = this.isFullWidth ? null : `${this.height}px`;
  }
}
