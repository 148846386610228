import {
  trigger,
  animate,
  transition,
  style
} from '@angular/animations';

const hiddenProps = {
  maxHeight: 0
};

const visibleProps = {
  maxHeight: '100vh'
};


export const matchesAnimation = trigger('matchesAnimation', [
  transition(':enter', [
    style(hiddenProps),
    animate('0.1s ease', style(visibleProps))
  ]),

  transition(':leave', [
    style(visibleProps),
    animate('0.1s ease', style(hiddenProps))
  ])
]);

export const animations = [
  matchesAnimation
];
