import {
  trigger,
  animate,
  transition,
  style,
  group
} from '@angular/animations';

export const modalBackdropFadeAnimation = trigger('modalBackdropFadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.25s ease', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)' }),
    animate('0.25s ease', style({ opacity: 0 }))
  ])
]);

export const modalWindowSlideInAnimation = trigger('modalWindowSlideInAnimation', [
  transition(':enter', [
    style({ transform: 'translateY(400%)' }),
    animate('0.3s cubic-bezier(0.645, 0.045, 0.355, 1)', style({ transform: 'translateX(0)' }))
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0)' }),
    animate('0.3s cubic-bezier(0.645, 0.045, 0.355, 1)', style({ transform: 'translateY(400%)' }))
  ])
]);

export const modalScaleInInAnimation = trigger('modalScaleInInAnimation', [
  transition(':enter', [
    group([
      style({ transform: 'scale(0)', opacity: 0 }),
      animate('0.23s cubic-bezier(0.645, 0.045, 0.355, 1)', style({ transform: 'scale(1)', opacity: 1 }))
    ])
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1 }),
    animate('0.23s cubic-bezier(0.645, 0.045, 0.355, 1)', style({ transform: 'scale(0.2)', opacity: 0 }))
  ])
]);

export const modalAnimations = [
  modalBackdropFadeAnimation,
  modalWindowSlideInAnimation,
  modalScaleInInAnimation
];
