<nav class="navbar"
     role="navigation"
     aria-label="main navigation"
     *ngIf="isExistingUser"
     heading="Agrigate">
  <div class="navbar-brand">
    <a class="navbar-item"
       [routerLink]="['/account/dashboard']">
      <ag-agrigateone-icon height="25"
                           backgroundType="light"
                           class='p-l-md'></ag-agrigateone-icon>
    </a>

    <a role="button"
       class="navbar-burger burger"
       [ngClass]="{ 'is-active': mobileNavVisible }"
       aria-label="menu"
       aria-expanded="false"
       (click)="toggleMobileNav()">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div *ngIf="currentUser"
       class="navbar-menu"
       [ngClass]="{ 'is-active': mobileNavVisible }">
    <div class="navbar-start">

      <a class="navbar-item"
         [routerLink]="['/account/dashboard']"
         [routerLinkActive]="'is-active'"
         *ngIf="showNavItem('Dashboard')">
        Dashboard
      </a>

      <a class="navbar-item has-unsaved"
         [ngClass]="{ 'has-unsaved': hasUnsavedOrder }"
         [routerLink]="['/account/new-order']"
         [routerLinkActive]="'is-active'"
         *ngIf="showNavItem('New Order')">
        New Order
      </a>

      <a class="navbar-item"
         [routerLink]="['/account/orders']"
         [routerLinkActive]="'is-active'"
         *ngIf="showNavItem('Orders')">
        Orders
      </a>

      <a class="navbar-item"
         [routerLink]="['/account/pallets']"
         [routerLinkActive]="'is-active'"
         *ngIf="showNavItem('Pallets')">
        Pallets
      </a>

      <a class="navbar-item"
         [routerLink]="['/account/planning']"
         [routerLinkActive]="'is-active'"
         *ngIf="myCompany?.businessType === 'Farm' && showNavItem('Planning')">
        Planning
      </a>

      <a class="navbar-item"
         [routerLink]="['/account/pack-instructions']"
         [routerLinkActive]="'is-active'"
         *ngIf="myCompany?.businessType === 'Farm' && showNavItem('Pack Instructions')">
        Pack Instructions
      </a>

      <a class="navbar-item"
         [routerLink]="['/account/data-insights']"
         [routerLinkActive]="'is-active'"
         *ngIf="showNavItem('Data Insights')">
        Data Insights
      </a>

      <a class="navbar-item"
         [routerLink]="['/account/lineage-card']"
         [routerLinkActive]="'is-active'"
         *ngIf="showNavItem('Lineage Card')">
        Lineage Card
      </a>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link"
           [routerLinkActive]="'is-active'">
           Finance
        </a>

        <div class="navbar-dropdown">
          <a class="navbar-item"
             [routerLink]="['/account/cash-flow']"
             [routerLinkActive]="'is-active'"
             *ngIf="showNavItem('Cash Flow')">
            Cash Flow
          </a>

          <a class="navbar-item"
             [routerLink]="['/account/commercial-terms']"
             [routerLinkActive]="'is-active'"
             *ngIf="showNavItem('Commercial Terms')">
            Commercial Terms
          </a>

        </div>
      </div>

      <!-- uncomment once freshdesk integration complete -->
      <!-- <a class="navbar-item" [routerLink]="['/account/support']" [routerLinkActive]="'is-active'">
        SupportuserFeedItem
      </a> -->

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link"
           [routerLinkActive]="'is-active'">
          Account
        </a>

        <div class="navbar-dropdown">
          <a class="navbar-item"
             [routerLink]="['/account/settings']"
             [routerLinkActive]="'is-active'"
             *ngIf="showNavItem('Account')">
            Profile
          </a>

          <a class="navbar-item"
             [routerLink]="['/account/company']"
             [routerLinkActive]="'is-active'"
             *ngIf="showNavItem('Company')">
            Company
          </a>

          <a class="navbar-item"
          [routerLink]="['/account/partners']"
          [routerLinkActive]="'is-active'"
          *ngIf="showNavItem('Trade Partners')">
          Trade Partners
        </a>

        <hr class="navbar-divider">

        <a class="navbar-item"
            (click)="logout()">
          <fa-icon [icon]="icons.faSignOutAlt"></fa-icon>
          <span class="mt-0">Sign Out</span>
        </a>

        </div>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item has-dropdown"
           [ngClass]="{ 'is-active': showUserFeed }">
        <a class="navbar-link user-feed-navbar-link"
           [ngClass]="{'pulse': isPulsing }"
           (click)="toggleUserFeed()"
           #userFeedNavbarLink>
          <fa-icon [icon]="icons.faBell"></fa-icon>
          <span *ngIf="userFeedUnseenItemCount > 0"
                class="badge is-badge-primary"
                [attr.data-badge]="userFeedUnseenItemCount > 99 ? '99+' : userFeedUnseenItemCount">&nbsp;</span>
        </a>

        <div class="navbar-dropdown is-right user-feed-navbar-dropdown"
             #userFeedContainer
             (scroll)="onUserFeedScroll($event)">
          <ng-container *ngFor="let userFeedItem of userFeedEdges let isFirst = first">
            <div class="navbar-item user-feed-navbar-item"
                 [ngClass]="{'unseen': !userFeedItem.node.seen }"
                 (click)="onUserFeedItemClick(userFeedItem.node)">
              <div class="is-flex">
                <span class="user-feed-read-indicator"
                      [ngClass]="{'unread': !userFeedItem.node.read }">
                  <fa-icon [icon]="!userFeedItem.node.read ? icons.faEnvelope : icons.faEnvelopeOpen"></fa-icon>
                </span>
              </div>
              <div class="user-feed-text-container">
                <div class="is-flex">{{ userFeedItem.node.message }}</div>
                <div class="help is-flex">{{ userFeedItem.node.createdAt | timeAgo | async }}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="userFeedEdges.length === 0">
            <div class="user-feed-clean-slate">
              <div class="user-feed-clean-slate__image-container">
                <img src="assets/images/empty-box.svg"
                     width="100"
                     height="120" />
              </div>
              <div class="user-feed-clean-slate__text-container">
                You have no notifications
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="loadingUserFeed">
            <div class="user-feed-loading">
              <div class="user-feed-loading__spinner">
                <ag-spinner></ag-spinner>
              </div>
              <div class="user-feed-loading__text">
                Loading...
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>
