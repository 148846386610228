import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'ag-metabase-iframe',
  templateUrl: './metabase-iframe.component.html',
  styleUrls: ['./metabase-iframe.component.scss']
})
export class MetabaseIframeComponent implements OnInit {

  @Input() height = '450px';
  @Input() src: string;
  @ViewChild('iframe') iframe: ElementRef;
  loading = true;

  get actualHeight(): string {
    return this.loading ? '0px' : this.height;
  }

  constructor() { }

  ngOnInit(): void { }

  onLoad() {
    this.loading = false;
  }

}
