import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ModalHostDirective } from './directives/modal-host.directive';
import { InputModalComponent } from './components/input-modal/input-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TruncatePipe } from './pipes/truncate.pipe';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { WeekPickerComponent } from './components/week-picker/week-picker.component';
import { WeekPickerPopupComponent } from './components/week-picker/week-picker-popup/week-picker-popup.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionPaneComponent } from './components/accordion/accordion-pane/accordion-pane.component';
import { AccordionHeaderComponent } from './components/accordion/accordion-header/accordion-header.component';
import { AccordionBodyComponent } from './components/accordion/accordion-body/accordion-body.component';
import { AccordionBodyHeaderComponent } from './components/accordion/accordion-body-header/accordion-body-header.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { ModalGalleryComponent } from './components/modal-gallery/modal-gallery.component';
import { PackingWeekDatePipe } from './pipes/packing-week-date.pipe';
import { FullUrlPipe } from './pipes/full-url.pipe';
import { EmptyPipe } from './pipes/empty.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { ModalBaseComponent } from './components/modal-base/modal-base.component';
import { NotificationComponent } from './notification/notification.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { MajorUnitPipe } from './pipes/major-unit.pipe';
import { MetabaseIframeComponent } from './components/metabase-iframe/metabase-iframe.component';
import { ShimmerLoaderComponent } from '@app/shared/shimmer-loader/shimmer-loader.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PrettyPrintPipe } from './pipes/pretty-print.pipe';
import { YearPaginatorComponent } from './components/year-paginator/year-paginator.component';
import { CompanySeasonPaginatorComponent } from './components/company-season-paginator/company-season-paginator.component';
import { HumanizePipe } from '@app/core/pipes/humanize.pipe';
import { PlurizePipe } from './pipes/plurize.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CurrencyPipe } from './pipes/currency.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { PageLoadingPlaceholderComponent } from '@app/shared/page-loading-placeholder/page-loading-placeholder.component';
import { PageTitleComponent } from '@app/shared/page-title/page-title.component';
import { CartonGroupingPivotTableComponent } from '@app/account/pallets/carton-grouping-pivot-table/carton-grouping-pivot-table.component';
import { PivotTableColumnLayoutComponent } from '@app/shared/pivot-table-column-layout/pivot-table-column-layout.component';
import { OpenAgriComponentsModule } from '@openagri/components';

@NgModule ({
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    OpenAgriComponentsModule
  ],
  declarations: [
    ConfirmModalComponent,
    ModalHostDirective,
    InputModalComponent,
    TruncatePipe,
    PaginatorComponent,
    DropdownComponent,
    WeekPickerComponent,
    WeekPickerPopupComponent,
    AccordionComponent,
    AccordionPaneComponent,
    AccordionHeaderComponent,
    AccordionBodyComponent,
    AccordionBodyHeaderComponent,
    TimeAgoPipe,
    ImageSliderComponent,
    ModalGalleryComponent,
    PackingWeekDatePipe,
    FullUrlPipe,
    EmptyPipe,
    SortPipe,
    ModalBaseComponent,
    NotificationComponent,
    ReplacePipe,
    MajorUnitPipe,
    MetabaseIframeComponent,
    ShimmerLoaderComponent,
    SpinnerComponent,
    PrettyPrintPipe,
    YearPaginatorComponent,
    CompanySeasonPaginatorComponent,
    PageTitleComponent,
    PageLoadingPlaceholderComponent,
    CartonGroupingPivotTableComponent,
    PivotTableColumnLayoutComponent,
    HumanizePipe,
    PlurizePipe,
    CurrencyPipe,
    SafeUrlPipe
  ],
  exports: [
    ConfirmModalComponent,
    ModalHostDirective,
    InputModalComponent,
    TruncatePipe,
    PaginatorComponent,
    DropdownComponent,
    WeekPickerComponent,
    AccordionComponent,
    AccordionPaneComponent,
    AccordionHeaderComponent,
    AccordionBodyComponent,
    AccordionBodyHeaderComponent,
    TimeAgoPipe,
    ImageSliderComponent,
    ModalGalleryComponent,
    PackingWeekDatePipe,
    FullUrlPipe,
    EmptyPipe,
    SortPipe,
    ModalBaseComponent,
    NotificationComponent,
    ReplacePipe,
    MajorUnitPipe,
    MetabaseIframeComponent,
    ShimmerLoaderComponent,
    SpinnerComponent,
    PageTitleComponent,
    PageLoadingPlaceholderComponent,
    PrettyPrintPipe,
    YearPaginatorComponent,
    CompanySeasonPaginatorComponent,
    CartonGroupingPivotTableComponent,
    PivotTableColumnLayoutComponent,
    HumanizePipe,
    CurrencyPipe,
    SafeUrlPipe
  ],
  entryComponents: [
    ConfirmModalComponent,
    InputModalComponent,
    WeekPickerPopupComponent
  ]
})
export class CoreModule { }

