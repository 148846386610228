import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserGQL } from '@app/generated/graphql';
import { UserHelper } from '@app/core/helpers/user.helper';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExistingUserGuard implements CanActivate {

  constructor(private router: Router, private userGql: UserGQL) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userGql.fetch().pipe(
      map(res => res.data.myUser),
      map(myUser => UserHelper.isExistingUser(myUser)),
      tap(isExistingUser => {
        if (!isExistingUser) {
          this.router.navigate(['account', 'settings']);
        }
      })
    );
  }
}
