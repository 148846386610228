import { Company, CompanyCertificate } from '@app/generated/graphql';
import { CertificateHelper } from './certificate.helper';


export abstract class CompanyHelper {
  static searchKeys(): string[] {
    return [
      'name',
      'varieties.name',
      'varieties.commodities.name',
      'certificates.document.name',
      'certificates.document.value',
      'certificates.certificateType.filename'
    ];
  }

  static recognizedCertificates(company: Company): Array<CompanyCertificate> {
    if (!company.certificates) {
      return null;
    }

    return company.certificates.filter((cert) => !CertificateHelper.isUnknownType(cert));
  }

  static isSeller(company: Company): boolean {
    if (!company) {
      return false;
    }
    return company.businessType === 'Farm';
  }

  static isBuyer(company: Company): boolean {
    if (!company) {
      return false;
    }
    return company.businessType === 'Importer';
  }
}
