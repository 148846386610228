import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule } from './http-link.module';
import { HttpLink } from './http-link';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import IntrospectionResultData from '../generated/introspection-result';
import ActionCable from 'actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { environment } from '../../environments/environment';
import { inflate } from './inflate.js';
import { createPersistedQueryLink } from 'apollo-angular-link-persisted';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: IntrospectionResultData
});

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  );
};

const uri = environment.apiBaseUrl + '/graphql';
export function createApollo(httpLink: HttpLink) {

  const cable = ActionCable.createConsumer(environment.webSocketUrl + '?token=' + localStorage.getItem('access_token'));
  const subscriptionLink = new ActionCableLink({ cable });

  const queryOrMutationLink = httpLink.create({ uri });

  const inflateLink = new ApolloLink((operation, forward) => {
    return forward(operation)
      .map((response) => {
        return inflate(response);
      });
  });

  const requestLink = ApolloLink.split(
    hasSubscriptionOperation,
    subscriptionLink,
    queryOrMutationLink
  );

  return {
    link: ApolloLink.from([inflateLink, createPersistedQueryLink(), requestLink]),
    cache: new InMemoryCache({ fragmentMatcher }),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      }
    }
  };

}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
