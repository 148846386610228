import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, UserGQL } from '@app/generated/graphql';
import { UserHelper } from '@app/core/helpers/user.helper';

@Injectable()
export class PermissionGuard implements CanActivate {
  currentUser: User;

  constructor(
    private router: Router,
    private userGql: UserGQL) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userGql.fetch().pipe(
      map(res => {
        const roles = UserHelper.userRoles(res.data.myUser);
        if (roles.some(r => route.data.roles.includes(r))) {
          return true;
        } else {
          this.router.navigate(['/account/settings']);
          return false;
        }
      })
    );
  }
}
