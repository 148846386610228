import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { environment } from '@environments/environment';
import { ToastService } from '@app/core/services/toast/toast.service';
import { User, UserGQL } from '@app/generated/graphql';
import { UserHelper } from '@app/core/helpers/user.helper';

@Component({
  selector: 'ag-home',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {

  user: User;

  constructor(private userGql: UserGQL,
    private router: Router,
    private authService: AuthenticationService,
    private toastService: ToastService) {

    if (this.authService.isAuthenticated()) {
      this.userGql.fetch().subscribe(res => {
        this.user = res.data.myUser;

        if (UserHelper.isNewUser(this.user)) {
          this.router.navigate(['account/settings']);
        }
        if (UserHelper.isExistingUser(this.user)) {
          this.router.navigate(['account']);
        }
      }, err => {
        this.toastService.showErrorToast('Unable to retrieve user data');
        throw err;
      });
    } else {
      window.location.replace(environment.authentication.logoutUrl);
    }
  }
}
