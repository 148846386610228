import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { environment } from '@environments/environment';
import { of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  refreshSub: any;
  private auth0 = new auth0.WebAuth({
    domain: environment.authentication.auth0Domain,
    clientID: environment.authentication.clientID,
    redirectUri: environment.authentication.redirectURI,
    audience: environment.authentication.audience,
    responseType: 'token id_token',
    scope: 'openid email'
  });

  constructor(private apollo: Apollo) { }

  login() {
    this.auth0.authorize();
  }

  logout() {
    this.clearAuthStorage();
    this.unscheduleRenewal();
    this.apollo.getClient().clearStore().then(() => {
      this.auth0.logout({
        returnTo: environment.authentication.logoutUrl
      });
    });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(true);
        } else {
          reject(err);
        }
      });
    });
  }

  setSession(authResult) {
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    this.clearAuthStorage();
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('email', authResult.idTokenPayload.email);
    document.cookie = 'AUTHORIZATION=' + localStorage.getItem('access_token') + '; path=/';
    this.scheduleRenewal();
  }

  clearAuthStorage() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('email');
    Sentry.configureScope(scope => scope.setUser(null));
  }

  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  getEmail() {
    return localStorage.getItem('access_token');
  }

  scheduleRenewal() {
    if (!this.isAuthenticated()) { return; }
    this.unscheduleRenewal();
    const expiresAt = Number(localStorage.getItem('expires_at'));

    const expiresIn$ = of(expiresAt).pipe(
      mergeMap(
        datetime => {
          const now = Date.now();
          const tenMinutes = 1000 * 60 * 10;

          console.log(`now: ${now.toLocaleString()}`);
          console.log(`expires: ${new Date(datetime).toLocaleString()}`);

          // Use timer to track delay until expiration
          // to run the refresh at the proper time
          return timer(Math.max(60000, datetime - now - tenMinutes));
        }
      )
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSub = expiresIn$.subscribe(
      () => {
        this.renewTokens();
        this.scheduleRenewal();
      }
    );
  }

  unscheduleRenewal() {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

  renewTokens() {
    console.log('token renew fired');
    this.auth0.checkSession({}, (err, authResult) => {
      if (err) {
        console.log(err);
      } else {
        this.setSession(authResult);
      }
    });
  }


}
