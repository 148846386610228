import { Component, OnInit, Input, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { NewOrderStore } from '../../account/new-order/stores/new-order.store';
import { faSignOutAlt, faBell, faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Subscription, Observable } from 'rxjs';
import { noop } from '@app/core/util/noop';
import { VersionService } from '@app/core/services/version/version.service';
import { UserHelper } from '@app/core/helpers/user.helper';
import { environment } from '@environments/environment';

import {
  UserFeedItem,
  UpdateUserFeedItemsGQL,
  UpdateUserFeedItemGQL,
  NewUserFeedItemGQL,
  UserFeedItemsConnectionGQL,
  UserFeedItemEdge,
  PageInfo,
  UserFeedUnseenCountGQL,
  User,
  Company,
  UserGQL
} from '@app/generated/graphql';

@Component({
  selector: 'ag-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {

  mobileNavVisible = false;
  hasUnsavedOrder: boolean;
  icons = { faSignOutAlt, faBell, faEnvelope, faEnvelopeOpen };
  currentUser: User;
  isLoading = false;
  uiVersion$: Observable<number>;

  @Input()
  isExistingUser: any;

  @Input()
  myCompany: Company;

  @ViewChild('userFeedNavbarLink')
  userFeedNavbarLinkRef: ElementRef<HTMLElement>;

  @ViewChild('userFeedContainer')
  userFeedContainerRef: ElementRef<HTMLElement>;

  showUserFeed = false;
  loadingUserFeed = false;
  userFeedEdges: UserFeedItemEdge[] = [];
  userFeedPageInfo: PageInfo;
  userFeedItemSubscription: Subscription;
  isPulsing: boolean;
  userFeedUnseenItemCount: number;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private newOrderStore: NewOrderStore,
    private toastService: ToastService,
    private updateUserFeedItemsGQL: UpdateUserFeedItemsGQL,
    private updateUserFeedItemGQL: UpdateUserFeedItemGQL,
    private newUserFeedItemGQL: NewUserFeedItemGQL,
    private userFeedItemsConnectionGQL: UserFeedItemsConnectionGQL,
    private userFeedUnseenCountGql: UserFeedUnseenCountGQL,
    private versionService: VersionService,
    private userGql: UserGQL
  ) {
    this.router.events.subscribe(() => (this.mobileNavVisible = false));
  }

  ngOnInit() {
    this.subscribeUnsavedNewOrder();
    this.userGql.fetch().subscribe(res => {
      this.currentUser = res.data.myUser;
    }, err => {
      this.toastService.showErrorToast('Unable to retrieve user data');
      throw err;
    });

    this.uiVersion$ = this.versionService.uiVersion();

    this.userFeedItemsConnectionGQL.fetch({ first: 5 }).subscribe(res => {
      this.userFeedEdges = res.data.userFeedConnection.edges;
      this.userFeedPageInfo = res.data.userFeedConnection.pageInfo;
    }, err => {
      throw err;
    });

    this.userFeedUnseenCountGql.fetch().subscribe(res => {
      this.userFeedUnseenItemCount = res.data.userFeedUnseenCount;
    }, err => {
      throw err;
    });

    this.userFeedItemSubscription = this.newUserFeedItemGQL.subscribe().subscribe(() => {
      this.userFeedItemsConnectionGQL.fetch({ first: 1 }).subscribe(gqlResponse => {
        this.userFeedEdges = [...gqlResponse.data.userFeedConnection.edges, ...this.userFeedEdges];
        if (!this.showUserFeed) {
          this.userFeedUnseenItemCount++;
          this.isPulsing = true;
          setTimeout(() => {
            this.isPulsing = false;
          }, 10000);
        }
      });
    });
  }

  // Hide the user feed if we click outside it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    if (this.userFeedContainerRef && this.userFeedNavbarLinkRef) {
      if (!this.userFeedNavbarLinkRef.nativeElement.contains(event.target) && !this.userFeedContainerRef.nativeElement.contains(event.target)) {
        if (this.showUserFeed) {
          this.toggleUserFeed(false);
        }
      }
    }
  }

  onUserFeedScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.fetchMoreUserFeedItems();
    }
  }

  markUserFeedItemAsRead(userFeedItem: UserFeedItem): void {
    this.updateUserFeedItemGQL.mutate({
      attributes: {
        id: userFeedItem.id,
        read: true,
        seen: true
      }
    }).subscribe(_ => {
      if (!userFeedItem.seen) {
        this.userFeedUnseenItemCount--;
        userFeedItem.seen = true;
      }
      userFeedItem.read = true;
    }, err => {
      throw err;
    });
  }

  navigateUserFeedItemRoute(userFeedItem: UserFeedItem): void {
    if (userFeedItem.hasOwnProperty('notifiable')) {
      switch (userFeedItem.notifiable.__typename) {
        case 'Comment':
          switch (userFeedItem.notifiable.commentable.__typename) {
            case 'Order':
              this.router.navigate(['/account/order/', userFeedItem.notifiable.commentable.id]);
              break;
            default:
              throw new Error(`Unable to navigte from user feed item for commentable ${userFeedItem.notifiable.commentable.__typename}`);
          }
          break;

        default:
          throw new Error(`Unable to navigte from user feed item for notifiable ${userFeedItem.notifiable.__typename}`);
      }
    }
  }

  toggleUserFeed(showUserFeed?: boolean): void {
    if (showUserFeed === undefined) {
      this.showUserFeed = !this.showUserFeed;
    } else {
      this.showUserFeed = showUserFeed;
    }

    if (!this.showUserFeed) {
      this.userFeedContainerRef.nativeElement.scroll(0, 0);
      this.userFeedEdges.forEach(edge => {
        if (!edge.node.seen) {
          this.userFeedUnseenItemCount--;
          edge.node.seen = true;
        }
      });
    } else {
      this.markAllUserFeedItemsAsSeen();
    }
  }

  onUserFeedItemClick(userFeedItem: UserFeedItem): void {
    this.markUserFeedItemAsRead(userFeedItem);
    this.navigateUserFeedItemRoute(userFeedItem);
    this.toggleUserFeed(false);
  }

  fetchMoreUserFeedItems() {
    if (this.userFeedPageInfo.hasNextPage) {
      this.loadingUserFeed = true;
      this.userFeedItemsConnectionGQL.fetch({
        first: 5,
        after: this.userFeedEdges[this.userFeedEdges.length - 1].cursor
      }).subscribe(res => {
        this.userFeedEdges = [...this.userFeedEdges, ...res.data.userFeedConnection.edges];
        this.userFeedPageInfo = res.data.userFeedConnection.pageInfo;
        this.markAllUserFeedItemsAsSeen();
        this.loadingUserFeed = false;
      }, err => {
        throw err;
      });
    }
  }

  markAllUserFeedItemsAsSeen(): void {
    const unseenAttributes = this.userFeedEdges
      .filter(edge => !edge.node.seen)
      .map(edge => ({
        id: edge.node.id,
        seen: true
      }));

    if (unseenAttributes.length > 0) {
      this.updateUserFeedItemsGQL.mutate({
        attributes: unseenAttributes
      }).subscribe(noop, err => {
        throw err;
      });
    }
  }

  toggleMobileNav(): void {
    this.mobileNavVisible = !this.mobileNavVisible;
  }

  navigate(view): void {
    this.router.navigate(view);
  }

  logout(): void {
    this.authService.logout();
  }

  showNavItem(navItem): boolean {
    const isAdminOrEmployee = UserHelper.isAdmin(this.currentUser) ||
      UserHelper.isEmployee(this.currentUser);
    switch (navItem) {
      case 'Dashboard':
        return isAdminOrEmployee;
      case 'Account':
        return isAdminOrEmployee || UserHelper.isPackhouseManager(this.currentUser);
      case 'Company':
        return isAdminOrEmployee;
      case 'Trade Partners':
        return isAdminOrEmployee;
      case 'Orders':
        return isAdminOrEmployee;
      case 'New Order':
        return isAdminOrEmployee;
      case 'Pallets':
        return isAdminOrEmployee;
      case 'Planning':
        return isAdminOrEmployee;
      case 'Pack Instructions':
        if (this.myCompany.businessType === 'Importer') {
          return false;
        }
        return isAdminOrEmployee || UserHelper.isPackhouseManager(this.currentUser);
      case 'Cash Flow':
        return isAdminOrEmployee;
      case 'Live Tracking':
        return isAdminOrEmployee;
      case 'Billing':
        return isAdminOrEmployee;
      case 'Data Insights':
        return isAdminOrEmployee;
      case 'Commercial Terms':
        return isAdminOrEmployee
      case 'Lineage Card':
        return isAdminOrEmployee && !environment.production
      default:
        return false;
    }
  }

  private subscribeUnsavedNewOrder(): void {
    this.newOrderStore.isUnsaved.subscribe(isUnsaved => {
      this.hasUnsavedOrder = isUnsaved;
    });
  }

  ngOnDestroy(): void {
    this.userFeedItemSubscription.unsubscribe();
  }

}


