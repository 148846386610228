import { Injectable } from '@angular/core';
import * as Fuse from 'fuse.js';
import { Searchable } from '../../interfaces/searchable.interface';

export interface FuseOptions extends Fuse.FuseOptions {
  minSearchStringLength: number;
}

export class FuseMatch<T> {
  constructor(score: number,
    item: T,
    matches: Array<FuseMatchInfo>) {
    this.score = score;
    this.item = item;
    this.matches = matches;
  }

  score: number;
  item: T;
  matches: Array<FuseMatchInfo>;
}

export class FuseMatchInfo {
  // indices of the matched characters (start, end)
  indices: [number, number];
  value: string;
  key: string;
  arrayIndex: number;
}

@Injectable({
  providedIn: 'root'
})
export class FuseService {
  defaults: FuseOptions = {
    shouldSort: true,
    includeScore: true,
    threshold: 0.2,
    includeMatches: true,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    minSearchStringLength: 2
  };

  searchOptions: FuseOptions = this.defaults;

  constructor() { }

  search<T extends Searchable>(
    collection: Array<T>,
    searchString: string,
    options?: FuseOptions
  ): Array<FuseMatch<T>> {

    let keys = {};
    if (collection && collection.length > 0) {
      keys = { keys: collection[0].searchKeys() };
    }

    Object.assign(this.searchOptions, options, keys);

    let results = [];
    if (searchString && searchString.length >= this.searchOptions.minSearchStringLength) {
      const fuse = new Fuse(collection, this.searchOptions);
      results = fuse.search(searchString);
      return results;
    } else {
      return collection.map(function (e) {
        return new FuseMatch<T>(0, e, null);
      });
    }
  }

  customSearch<T>(
    collection: Array<T>,
    searchString: string,
    searchKeys: string[],
    options?: FuseOptions
  ): Array<FuseMatch<T>> {

    let keys = {};
    if (collection && collection.length > 0) {
      keys = { keys: searchKeys };
    }

    Object.assign(this.searchOptions, options, keys);

    let results = [];
    if (searchString && searchString.length >= this.searchOptions.minSearchStringLength) {
      const fuse = new Fuse(collection, this.searchOptions);
      results = fuse.search(searchString);
      return results;
    } else {
      return collection.map(function (e) {
        return new FuseMatch<T>(0, e, null);
      });
    }
  }

  matchString(fuseMatch: FuseMatch<unknown>): string {
    if (fuseMatch.matches && fuseMatch.matches.length > 0) {
      const match = fuseMatch.matches[0];
      const matchedKey = this.humanReadableKey(match.key);
      return `${matchedKey}: ${match.value}`;
    }
    return undefined;
  }

  matchStringMulti(fuseMatch: FuseMatch<unknown>) {
    if (fuseMatch.matches && fuseMatch.matches.length > 0) {
      const matches = fuseMatch.matches.map((match) => {
        const matchedKey = this.humanReadableKey(match.key);
        return `${matchedKey}: ${match.value}`;
      });

      return matches.filter((match, index, self) => {
        return self.indexOf(match) === index;
      });
    }
    return [];
  }

  humanReadableKey(key: string): string {
    if (key) {
      let keyEntries = key.split('.');
      if (keyEntries.length > 2) {
        keyEntries = keyEntries.slice(-2);
      }
      return keyEntries.join(' ');
    }
    return key;
  }

}
